import { createSlice, current } from "@reduxjs/toolkit";
import { getCustomerOrderQuickData } from "./api";
import { cloneDeep, set } from "lodash";

const initialState = {
    intraCity: {
        type: "IntraCity",
        isBookedWithoutToken: false,
        lead: {},
        locationDataFromLandingForm: null,
        assetSubType: {

        },
        city: null,
        pickDropLocation: [
            {
                id: 0,
                place: {
                    placeId: null,
                    coordinate: {
                        latitude: null,
                        longitude: null
                    },
                    placeValue: '',
                    city: '',
                    area: '',
                    name: '',
                    pincode: '',
                    formatted_address: ''
                },
                contactPerson: {
                    name: '',
                    contact: '',
                    floor: 0,
                    l1: "",
                    l2: "",
                    officeName: '',
                    nearby_landmark: '',
                    pincode: '',
                    city: '',
                    isSelfSelected: false
                },
                isSubmited: false,
            },
            {
                id: 1,
                place: {
                    placeId: null,
                    coordinate: {
                        latitude: null,
                        longitude: null
                    },
                    placeValue: '',
                    city: '',
                    area: '',
                    name: '',
                    pincode: '',
                    formatted_address: ''
                },
                contactPerson: {
                    name: '',
                    contact: '',
                    floor: 0,
                    l1: "",
                    l2: "",
                    officeName: '',
                    nearby_landmark: '',
                    pincode: '',
                    city: '',
                    isSelfSelected: false
                },
                isSubmited: false,
            },
        ],
        packages: [
            {
                id: 0,
                materialType: null,
                qty: 0,
                codAmount: 0,
                orderNumber: '',
                // packingType: null,
                // size: 0,
                // unit: 0,
                // dimension: {
                //   l: 0,
                //   b: 0,
                //   h: 0
                // },
                // weight: 0,
                // stack: false,
                // haz: false,
                // frag: false,
                fromPlaceRef: 0,
                toPlaceRef: null,
            }
        ],
        paymentMode: "topay",
        intraCityRateType: "EXPRESS",
        booked: false
    },
    partLoad: {
        type: "partLoad",
        bookingType: "",
        city: null,
        lead: {},
        isBookedWithoutToken: false,
        isMultipleEWayBill: false,
        pickDropLocation: [
            {
                id: 0,
                place: {
                    placeId: null,
                    coordinate: {
                        latitude: null,
                        longitude: null
                    },
                    placeValue: '',
                    city: '',
                    area: '',
                    name: '',
                    pincode: '',
                    formatted_address: ''
                },
                contactPerson: {
                    name: '',
                    gstin: '',
                    contact: '',
                    floor: 0,
                    l1: "",
                    l2: "",
                    officeName: '',
                    nearby_landmark: '',
                    pincode: '',
                    city: '',
                    isSelfSelected: false
                },
                isSubmited: false,
            },
            {
                id: 1,
                place: {
                    placeId: null,
                    coordinate: {
                        latitude: null,
                        longitude: null
                    },
                    placeValue: '',
                    city: '',
                    area: '',
                    name: '',
                    pincode: '',
                    formatted_address: ''
                },
                contactPerson: {
                    name: '',
                    gstin: '',
                    contact: '',
                    floor: 0,
                    l1: "",
                    l2: "",
                    officeName: '',
                    nearby_landmark: '',
                    pincode: '',
                    city: '',
                    isSelfSelected: false
                },
                isSubmited: false,
            },
        ],
        packages: [
            {
                id: 0,
                materialType: null,
                qty: 0,
                codAmount: 0,
                orderNumber: '',
                packingType: null,
                size: 0,
                unit: 0,
                dimension: {
                    l: 0,
                    b: 0,
                    h: 0
                },
                weight: 0,
                // stack: false,
                // haz: false,
                // frag: false,
                fromPlaceRef: 0,
                toPlaceRef: null,
            }
        ],
        eWayBillData: [
            {
                id: 0,
                eWbillNumber: "",
                partyBillNumber: "",
                partyGoodsVal: 0,
                isDataFromApiCall: false,
            }
        ],
        paymentMode: "topay",
        gross: 0,
        vehicleDetails: {
            assetType: '',
            assetSubType: '',
            vehicleModel: '',
            truckNos: ''
        }
    },
    quickOrder: {
        isOpen: false,
        data: ""
    },
    tracking: {
        route: [],
        estimation: []
    },
    ewayBillLoading: false,
    isOutsideFormFilled: { check: false, type: "" },
    intraCityAcceptedOrder: ""
};

const setDeep = (obj: any, path: any, value: any) => {
    const pList = path.split(".");
    const key = pList.pop();
    const pointer = pList.reduce((accumulator: any, currentValue: any) => {
        if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
        return accumulator[currentValue];
    }, obj);
    pointer[key] = value;
    return obj;
};

const customerBookingSlice = createSlice({
    name: "customerBooking",
    initialState,
    reducers: {
        ResetCustomerBookingTracking: (state) => {
            Object.assign(state, initialState);
        },
        setCustomerBookingTracking: (state, action) => {
            Object.assign(state,
                state.tracking = {
                    ...state.tracking,
                    ...action.payload
                });
        },
        setIntraCityPickupDropLocation: (state, action) => {
            const { name, value, index } = action.payload;

            // Ensure intraCity exists
            if (typeof state.intraCity !== "object") {
                state.intraCity = initialState.intraCity;
            }

            // Ensure pickDropLocation exists as an array
            if (!Array.isArray(current(state).intraCity.pickDropLocation)) {
                state.intraCity.pickDropLocation = [];
            }

            // Create a deep copy of pickDropLocation and update the specific index
            const updatedPickDropLocation = current(state).intraCity.pickDropLocation.map((item, i) => {
                // Create a new copy for immutability
                const data = cloneDeep(item); // Ensure immutability

                // Set the value using Lodash's set method
                set(data, name, value);
                return i === index ? data : item
            });
            state.intraCity.pickDropLocation = updatedPickDropLocation
        },
        setIntraCity: (state, action) => {
            const [what, val] = action.payload;

            // Ensure intraCity exists
            if (typeof state.intraCity !== "object") {
                state.intraCity = initialState.intraCity;
            }

            // Use setDeep to update the nested field
            Object.assign(state, {
                intraCity: setDeep({ ...state.intraCity }, what, val),
            });
        },
        setCustomerBookingType: (state, action) => {
            console.log(`setCustomerBookingType Entry`, action.payload, `action.payload`, action);

            Object.assign(state, {
                partLoad: {
                    ...state.partLoad,
                    type: action.payload
                }
            });
        },
        setPartLoad: (state, action) => {
            const { what, val } = action.payload;

            Object.assign(state, {
                partLoad: {
                    ...state.partLoad,
                    [what]: val
                }
            });
        },
        closequickOrdrDialog: (state) => {
            Object.assign(state, {
                quickOrder: { isOpen: false, data: {} }
            });
        },
        acceptIntracityBooking: (state,action) => {
            state.intraCityAcceptedOrder = action.payload?.shipmentNumber
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomerOrderQuickData.fulfilled, (state, action) => {
                Object.assign(state.quickOrder, action.payload);
            });
    },
});

export const { ResetCustomerBookingTracking, setCustomerBookingTracking,
    setIntraCityPickupDropLocation, setCustomerBookingType, setIntraCity,
    setPartLoad, closequickOrdrDialog,acceptIntracityBooking } = customerBookingSlice.actions;

export default customerBookingSlice.reducer;