// Keep this interface in sync with node server   code
export interface IDepartmentRights<T> {
  dashboard2: T;
  companyHome: {
    view: T;
    finance: T;
    operation: T;
    hr: T;
    feedback: T;
    subscription: T;
    entity: T;
  };
  companySettings: {
    charges: T;
    messages: T;
    addExpense: {
      create: T;
      update: T;
      view: T;
      delete: T;
    };
    addIncome: {
      create: T;
      update: T;
      view: T;
      delete: T;
    };
    manage: T;
    reward: T;
    vendors: {
      create: T;
      update: T;
      view: T;
    };
    assetCategory: {
      create: T;
      view: T;
    };
    assetType: {
      create: T;
      view: T;
    };
    userType: {
      create: T;
      update: T;
      view: T;
    };
    createCheckList: {
      create: T;
      view: T;
    };
  };
  // individualInfo: T;
  myCompany: {
    companyInfo: T;
    branch: {
      create: T;
      view: T;
      delete: T;
    };
    branchSecondaryInfo: T;
    user: {
      paidLeaveCount: T;
      verifyDoc: T;
      create: T;
      update: T;
      view: T;
      viewAll: T;
      delete: T;
    };
    fleets: {
      create: T;
      update: T;
      view: T;
    };
    // assetBalance: T;
    // assetCount:T;
    department: {
      create: T;
      update: T;
    };
    transportationCharge: T;
    // individualInfo: T;//copy
    // individualWithFleet: T;//copy
    // assetBalance: T
  };
  paymentType: {
    create: T;
    view: T;
  };
  route: {
    routes:T;
    individualRouteDetails:T;
    routeReport:T;
    services:T;
    manageOldRouteSystem:T;
    branchDistanceTime:T;
    branchRoute:T;
    routeBundle:{
      create:T;
      view:T;
    }
  };
  pricing:{
    serviceSubType:{
      create:T;
      view:T;
    },
    standardRates:{
      create:T;
      view:T;
    },
    volumeDiscount:{
      create:T;
      view:T;
    }
    };
  customerOrders: T;
  branchOperations: {
    booking: {
      create: T;
      edit: T;
      delete: T;
    };
    fetchDocket: {
      fetch: T;
      viewCustomerTrackingNumber: T;
      updateDocketSize: T;
    };
    backToHub: {
      view: T;
      viewAllBranches: T
    };
    loading: T;
    unloading: T;
    delivery: T;
    hubList: T;
    doorDelivery: {
      view: T;
      viewAllBranches: T;
      pendingDeliverywithConact: T
    };
    // pendingProofOfDelievery:T;
    verifyStocks: T;
    qrVerifyStocks: T;
    // verifyPackages: T;
    // viewLoadingAll: T;
    // viewUnloadingAll: T;
    // track: T;
    parcelTransferAllUser: T;
    // extraDocket: T;
    // branchDistanceTime: T;
    // branchRoute: T;
  };
  fleetOperations: {
    tripPlanning: {
      create: T;
      edit: T;
      view: T;
      favouriteFleetAdmin: T;
      fleetOrderAdmin: T;
    },
    // fleetActivityLogs: {
    //   view:T;
    //   viewPackagesDetails:T;
    // };
    fleetOrder: T;
    fleetTower: T;
    outForDelievery: T;
    fleetDriver: {
      edit: T;
      delete: T;
    };
    fleetStatus: {
      view: T;
      addGSTCustody: T;
      removeGSTCustody: T;
    };
    // tripList:T;
    // favouriteFleetAdmin: T;
    // parcelTransfer: T;
    // create: T;//copy
    // edit: T;//copy
    // view: T;//copy
    // fleetOrderAdmin: T;//copy
  };
  verification: {
    pod: {
      unverifiedPODlist: T;
      verifyPOD: T;
    };
    expense: {
      view: T;
      accept: T;
      reject: T;
    };
    verifiedPackages: T;
    pettyCashList: T;
    verifySettledAmount: T;
    // shortage: T;
    //  cancelBookings: T;
    //  processCancelBookings: T;
    controlTower: T;
  };
  // listbranch: T;
  attendance: {
    add: T;
    view: T;
    apply: {
      applyLeave: T;
      applyPaidLeave: T;
    };
    // report: T;
    leaveReport: T;
    // handBook: T;
    processRequest: T;
    viewAll: T;
    removeAttendance: T;
  };

  // holiday: {
  //   create: T;
  //   viewAll: T;
  // };
  // creditor: T;

  // trip: { create: T; edit: T; view: T };
  // zone: {
  //   create: T;
  //   edit: T;
  //   delete: T;
  // };
  // department: T;
  // fuel: {
  //   create: T;
  //   edit: T;
  //   report: T;
  // };
  customer: {
    view: T;
    edit: T;
    viewPastBooking: T;
    viewMonthWiseBooking: T;
    branchOnly: T;
    allCustomers: T;
  };
  creditCustomers: {
    creditCustomer: T;
    creditBillList: T;
    creditVerify: {
      view: T;
      process: T;
    };
    verifyCreditPermisson: T;
    assignCredit: T;
    creditReport: T;
  };
  fixedCustomer: {
    fixedCustomerRateList: {
      Update: T;
      view: T;
    };
    fixedCustomerList: T;
    fixedCustomerDiscountList: T;
  };
  crm: {
    newCustomer: T;
    lostCustomer: T;
    customerRequirementList: T;
    customerCommunication: T;
    customerNameUpdate: T;
    pendingDeliveryCallRecords: T;
    voiceCall: {
      allView: T;
      view: T;
    };
  };
  telecaller: {
    assignCustomer: T;
    handleCustomer: T;
  };
  telecallerUserAssignment: T;
  // creditCustomer: T;
  // creditSettle: T;
  // creditIncomeSettle: T;
  // route: {
  //   create: T;
  //   edit: T;
  //   delete: T;
  //   view: T;
  //   // branchDistanceTime: T;
  //   // branchRoute: T;
  // };
  // rate: {
  //   create: T;
  //   edit: T;
  //   delete: T;
  //   view: T;
  // };
  branchReport: {
    bookedReport: T;
    ewaybill: T;
    loadingReport: T;
    inTransitReport: T;
    pendingDeliveryReport: T;
    loadingReportList: T;
    fetchReportList: T;
    incidentalDocketsReport: T;
    customerTrackingNumber: T;
    eWayBillInvoiceView: T;
  };
  fleetReport: {
    fleetUtilization: T;
    fleetDistanceTracking: T;
    parcelTransferLogs: T;
    fuelReport: T;
    fleetActivityLogs: {
      view: T;
      viewPackagesDetails: T;
    };
    fleetSummary: T;
    dayWiseFleetSummary: T;
    allFleetDayWiseSummary: T;

  };
  prospectiveCustomer: T;
  demurrageReport: {
    demurrageMonthWise: T;
    demurrageBranchWise: T;
    demurrageDayWise: T;
    demurrageDocketWise: T;
  };
  discountReport: {
    discountMonthWise: T;
    discountBranchWise: T;
    discountDayWise: T;
    discountDocketWise: T;
    shortageAdmin: T;
  };
  deliveryChargeReport: {
    deliveryChargeMonthWise: T;
    deliveryChargeBranchWise: T;
    deliveryChargeDayWise: T;
  };
  revenueReport: {
    bookingDocketWise: T;
    revenueSummary: T;
    revenueMonthWise: T;
    branchToBranchDayWise: T;
    branchToBranchMonthWise: T;
    revenueDayWise: T;
    doorDeliveryBranchDayWise: T;
    doorDeliveryDayWise: T;
    doorDeliveryDocketWise: T;
    shortage: T;
    branchwise: T;
    deliveryDocketWise: T;
    deliveryDocketWiseDetails: T;
    //   shortage: T;
    //   branchwise: T;
    // booking: T
    // bookingSummary: T
    // delivery: T
    // shortage: T
    // docketwiseDiscount: T
    // branchwiseDemurrage: T
  };
  accountReport: {
    pendingMemos: T;
    userBalance: T;
    transferList: T;
    venderSettlement: T;
    tallySync: T;
    incomeReport: T;
    incentiveReport: T;
    bookingSummary: T;
    expenseReport: T;
    branchWisePettyCash: T;
    ccavenueTxnsDayWise:T;
    secondaryCompanySettlement: T;
    // bookingSummary: T
    // transfer: T
    // vendor: T
    // tallySync: T
    // delivery: T
    // expense: T
    // income: T
    // booking: T
  };
  expenseReport: {
    entityWiseExpenseReport: T;
    monthWiseExpense: T;
    dayWiseExpense: T;
    expenseBudget: T;
  };
  kpiReport: {
    branchRank: T;
    branchwiseOperationKPI: T;
    branchwisePeformanceKPI: T;
    fuelEfficiencyRank: T;
    userActivity: T;
    userActivityDayWise: T;
    userActivityEfficiency: T;
    userIssue: T;
    // branchwiseKPI: T
    // fuel: T
    // userActivity: T
    graceTowerSummary: T
  };
  travelsReport: {
    occupancyReport: T;
    ticketSettlement: {
      view: T;
      creditIncomeSettle: T;
    };
    // income: T
    // creditor: T
  };
  transportMISReport: {
    routeMISReport: T;
    MISReport: T;
    branchMISReport: T;
  };
  oldReports: {
    bookingReport: T;
    deliveryReport: T;
  };
  // report: {
  //   ewaybill: T;//copy
  //   expense: T;//copy
  //   income: T;//copy
  //   daily: T;
  //   bookingSummary: T;//copy
  //   loading: T;//copy
  //   fuel: T;//copy
  //   MIS: T;//copy
  //   branchMIS: T;//copy
  //   branchRank: T;//copy
  //   pendingMemos: T;//copy
  //   shortage: T;//copy
  //   shortageAdmin: T;
  //   userIssue: T;
  //   userBalance: T;//copy
  //   incomingDocket: T;//copy
  //   fleetUtilization: T;//copy
  //   activityReport: T;
  //   branchwiseDemurrage: T;//copy
  //   booking: T;//copy
  //   delivery: T;//copy
  //   parcelTransferLogs: T;//copy
  //   branchwiseKPI: T;//copy
  //   docketwiseDiscount: T;//copy
  //   creditor: T;//copy
  //   vendor: T;//copy
  //   tallySync: T;//copy
  //   transfer: T;//copy
  //   userActivity: T;//copy
  // };
  preLogoutcheck: T;
  // memo: T;
  nonCash: T;
  // assign: {
  //   branch: T;
  //   department: T;
  //   fleet: T;
  // };
  // remarks: T;
  asset: {
    manageAsset: T;
    // types: T;
    // balance: T;
    // supplier: T;
    create: T;
    view: T;
    process: T;
    transfer: T;
    tracking: T;
    expenses: T;
  };
  checkListForm: {
    create: T;
    view: T;
  };

  franchise: T;
  // franchiseReport: T;
  notification: {
    checkIn: T;
    leave: T;
    cancelBuilty: T;
    unverifiedEwayBill: T;
    closeBranch: T;
    branchLateOpenAlert: T;
    userAbsentAlert: T;
    branchClosedAlert: T;
    userCashAmountAlert: T;
    vehicleOperation: T;
    accountsCalender: T;
  };
  addFleetDistanceTracking: T;
  feedback: {
    create: T;
    list: T;
    edit: T;
    viewAllBranches: T;
    analysis: T;
    process: T;
    //In Complain List
    myDutyProcessRight: T;
    viewComplaintsChat: T;
    chatInAllComplaints: T;
    intraCity:{
      view:T
    }
    partLoad:{
      view:T
    }
    fullLoad:{
      view:T
    }
  };
  wallet: {
    userWallet: T;
    companyWallet: {
      view: T;
      transfer: T;
      individualSettlement: T;
      franchiseMemoHistory: T;
      transactionDetails: T;
      viewAllUserDetails: T;
      viewAllCompanyUserDetails: T;
    };
    CCAvenueTxns:{
      view:T;
      viewAll:T;
    };
    advancePaymentToken:{
      view:T;
      viewAll:T;
    }
    franchiseMemoHistory: {
      send: T;
      receive: T;
    };
    incurExpense: {
      incur: T;
      nonCash: T;
      adminLevelIncur: T;
      edit: T;
      delete: T;
    };
    incurIncome: {
      incur: T;
      nonCash: T;
      adminLevelIncur: T;
      edit: T;
      delete: T;
    };
    franchiseWallet: T;
  };
  operationRequest: {
    branchDelivery: {
      view: T;
      process: T;
    };
    damurrage: {
      view: T;
      process: T;
    };
    gstCustodyPenalty: {
      view: T;
      process: T;
    };
    discount: {
      view: T;
      process: T;
    };
    doorDeliveryDiscount: {
      view: T;
      process: T;
    };
    changeDestination: {
      view: T;
      process: T;
    };
    cancelBookings: {
      view: T;
      process: T;
      createAll: T;
    };
    cancelDoorDeliveryBooking: {
      view: T;
      process: T;
    };
    packageUpdateRequestList: {
      view: T;
      process: T;
    };
    parcelTransferRequest: {
      view: T;
      process: T;
    };
    gstCustodyRequest: {
      view: T;
      process: T;
    };
    remarksRequest: {
      view: T;
      process: T;
    };
    claimSettlement: {
      view: T;
      remarkReqProcess: T;
      transportChargeClaim: T;
      goodsValueClaim: T;
    };
    reCheckInReq: {
      view: T;
      process: T;
    };
    backToHub: {
      view: T;
      process: T;
    };
    bookingUpdate: {
      view: T
      process: T
    },
    missingRequest: {
      view: T
      process: T
    }
    // trackingNumber: T;
    // BTH: T;
    // damrrage: T;
    // ND: T;
    // remarksRequest: T;
    // discount: T;
    // cancelBookings: T;
    // processCancelBookings: T;
    // claimSettlement: T;
    // reCheckInReq: T;
    // parcelTransferRequest: T;
    // gstCustodyRequest:T;
  };
  fleetOrder:{
    branchFleetOrder:{
      view:T
    }
    intracityFleetOrder:{
      view:T
    }
    partLoadFleetOrder:{
      view:T
    }
    fullLoadFleetOrder:{
      view:T
    }
  }
  myProfile: T;
  salary: T;
  training: T;
  organogram: T;
  performanceReview: T;
  performanceReviewTool: {
    allUser: T;
  };
  home: T;
  // nonCashRequests: T;
  marketingTools: {
    listbranch: T;
    mapView: T;
    //   syncState: T;
  };
  // missingList: T;
  hrm: {
    allowOverATime: T;
    updatePaidLeaveCount: T;
    applyPaidLeave: T;
    taskViewAll: T;
    attendenceReport: T;
    leaveReport: T;
    holiday: {
      set: T;
      view: T;
    };
    verifyAttendance: {
      view: T;
      process: T;
    };
    createJobPost: T;
    performanceReview: {
      create: T;
      view: T;
      allUser: false
    };
    salaryReport : T;
    attendanceSummaryReport: T;
    peerReviewReport: T;
    updateBreakLogs: T;
    // verifyDoc: T;
  };
  task: {
    create: T
  }
  subTask: {
    activityCost: T
  }
  boardSection: {
    activityCost: T
  }
  internalCommunication: {
    viewAll: T,
    createAll: T
  },
  // companySection: T;
  assetType: T;
  rewards: T;
  activityReport: T;
  userIssue: T;
  // salaryReport:T;
  // ewaybill: T;
  serviceSubType:{
    create:T;
    view:T;
  }
  builtyBanner: {
    create: T
    view: T
    update: T
  }
  reviewRating:{
    expressParcel:{
      view:T
    },
    intraCity:{
      view:T
    },
    partLoad:{
      view:T
    },
    fullLoad:{
      view:T
    },
  }
  partLoadRevenueReport:{
    bookingReport:T
    bookingReportSummary:T
    bookingReportMonthWise:T
  }
  fullLoadRevenueReport:{
   bookingReport:T
   bookingReportSummary:T
   bookingReportMonthWise:T
  }
  customerLeads:{
    expressParcel:{
      view:T
    }
    intraCity:{
      view:T
    }
    partLoad:{
      view:T
    }
    fullLoad:{
      view:T
    }
    courier:{
      view:T
    }
  }
}

// keep this interface in sync with node server code
export interface IAppDepartmentRights<T> {
  branchOperation: {
    booking: {
      viewDetailed: T;
      viewAll: T;
    };
    loading: {
      createAllCrossingBranch: T;
      missingRequestAdmin: T;
    };
    delivery: {
      viewAllTrackingReq: T;
    };
    backToHub: {
      bthAllEntity: T;
    }
  };
  fleetOperations: {
    fleetStatus: {
      EwayBillUpdate: T;
    };
    outForDelivery: {
      viewAllDoorDeliveryReq: T;
    }
  };
  operationRequest: {
    cancelBookingRequest: {
      viewCancelBookingReq: T;
      acceptCancleBookingReq: T;
      processCancelRequired: T;
      allowCancel: T
    };
    changeDestinationReq: {
      acceptBTOReq: T;
      viewAllBTHReq: T;
      acceptBTHReq: T;
      viewAllNDReq: T;
      acceptNDReq: T;
    };
    demurrageRequest: {
      viewAllDemurrageReq: T;
      acceptDemurrageReq: T;
    };
    gstCustodyPenaltyRequest: {
      viewAllGstCustodyPenaltyReq: T;
      acceptGstCustodyPenaltyReq: T;
    };
    discountRequest: {
      viewDiscountReq: T;
      acceptDiscountReq: T;
    };
    reCheckInRequest: {
      processReCheckInReq: T;
      viewReCheckInReq: T;
    };
    remarkRequest: {
      viewAllRemarksReq: T;
      acceptRemarksReq: T;
    };
    docketTransferReq: {
      processParcelTransfer: T;
    };
    packageUpdateReq: {
      processPackageUpdateRequest: T;
    };
    branchDeliveryChargeRequest: {
      processBranchDeliveryChargeReq: T;
      viewBranchDeliveryChargeReq: T;
    };
    claimSettlementRequest: {
      acceptRemarksTransportCharge: T;
      initiateRemarksCompensation: T;
      acceptRemarksCompensation: T;
      processGstCustodyPenaltyCompensation: T;
    };
    bookingUpdate: {
      viewAllBookingUpdateReq: T
      acceptBookingUpdateReq: T
    },
    fleetGstCustody: T;
    missingRequest: {
      viewMissingRequest: T
    }
  };
  feedback: {
    processResolveIssueReq: T;
  };
  fleetReport: {
    fleetActivityAmount: T;
    fleetSummary: T;
  };
  entities: {
    company: {
      createAll: T;
      updateAll: T;
      updateDepartmentAll: T;
      verifyDocs: T;
      agreement: T;
      addOtherCharges: T;
    };
  };
  myCompany: {

    branch: {
      createAll: T;
      viewAll: T;
      updateAll: T;
    };
    fleet: { manageAll: T 
      verifyDocs:T
    };
    user: {
      manageAll: T;
      verifyDocs:T
    };
  };

  paymentType: T;
  creditCustomer: {
    verify: T;
    creditAll: T;
  };
  customerWallet: {
    customerBalanceReport: T;
    customerTransactionList: T;
  },
  fixedCustomer: {
    directConnectCustomer: T;
  };
  demurrageReport: {
    demurrageAll: T;
  };
  discountReport: {
    discountAll: T;
  };
  expenseReport: {
    expenseAll: T
  };
  deliveryChargeReport: {
    deliveryChargeAll: T;
  };
  revenueReport: {
    booking: T;
    delivery: T;
    doorDeliveryDocketwise:T;
    deliveryAll: T;
    branchwise: T;
    branchToBranchDayWise: T;
    branchToBranchMonthWise: T;
    bookingReportDetails: T;
  };
  kpiReport: {
    branchRank: T;
    graceTowerSummaryAllCompany: T
  };
  CCAvenueTxnslist:{
    manualVerification:T;
  };
  franchiseWallet: {
    manageAll: T;
    viewAllCompanyReports: T;
    viewAllCompanyMonthlyReports:T;
    viewOutstandingReports: T;
    customerMemoReport: T;
    dailyMemoReport: T;
    doorDeliveryReports: T;
  };
  notification: {
    unverifiedPkgAlert: T;
    companyBalanceAlert: T;
    creditCustomerBalanceAlert: T;
    pendingMissingReqAlert: T;
  };
  nonCash: {
    viewAllRequest: T;
    acceptRequest: T;
    rejectRequest: T;
  };
  platformSettings: {
    smsTemplate: T;
    appDepartment: T;
    assignAppDepartment: T;
    materialTypes: T;
    packingTypes: T;
    handlingChargeSlab: T;
  };
  averageRate: T;
  standardRate:T;
  customerModule: {
    assetType: T;
    intraCityRates: T;
  };
  verification: {
    pettyCash: T;
  };
  customerCRM: {
    newCustomer: T;
    lostCustomer: T;
    customersFixedRates: T;
    connectedCustomerList:T;
    allCommunication: T;
    fixedCustomerDiscountList: T;
    customerNameUpdate: T;
    viewAllCustomer: T;
    processCustomerRequirement: T;
    processCustomerRateRequirement: T;
  };
  audit: {
    suspectedNumber: T;
    connectCustomer: {
      viewCustomerConnect: T;
      acceptCustomerConnect: T;
    }
  };
  oldReports: {
    booking: T;
    delivery: T;
  };
  asset: {
    viewAll: T;
    processTransfer: T;
    transferAutoApproval: T;
    create: T;
    transfer: T;
  };
  gstReport: {
    view: T;
  };
  serviceSubType:{
    create:T
    view:T
  };
  builtyBanner: {
    create: false,
    view: false,
    update: false
  }
  task: {
    taskPlanningRelaxation: T; 
  },
  platformServiceRights:{
    assign: T;
  },
  leads:{
    investor: T
  }
}

const recursiveMerge: <T, Y>(obj1: T, obje2: Y) => T = (
  obj1: any,
  obj2: any
) => {
  // if (!obj2 || !obj1) return;
  let result: any = {};
  try {
    Object.keys(obj1).filter((key) => {
      if (typeof obj1[key] === "object" && typeof obj2[key] === 'object' && obj1[key] !== null) {
        let mergedObj = recursiveMerge(obj1[key], obj2[key]);
        result[key] = mergedObj || obj1[key];
      } else {  
        result[key] =
          typeof obj1[key] === "object" && typeof obj2[key] !== "object"
            ? obj1[key]
            : obj2[key] || obj1[key];
      }
    });
  } catch (err : any) {
    return;
  }
  return result;
};

export const processRights = (
  departments: { rights: IDepartmentRights<boolean> }[]
) => {
  let defaultRights: IDepartmentRights<boolean> = {
    companyHome: {
      view: false,
      finance: false,
      operation: false,
      hr: false,
      feedback: false,
      subscription: false,
      entity: false,
    },
    companySettings: {
      charges: false,
      messages: false,
      addExpense: {
        create: false,
        update: false,
        view: false,
        delete: false,
      },
      addIncome: {
        create: false,
        update: false,
        view: false,
        delete: false,
      },
      userType: {
        create: false,
        update: false,
        view: false,
      },
      manage: false,
      reward: false,
      vendors: {
        create: false,
        update: false,
        view: false,
      },
      assetCategory: {
        create: false,
        view: false,
      },
      assetType: {
        create: false,
        view: false,
      },
      createCheckList: {
        create: false,
        view: false,
      },
    },
    myCompany: {
      companyInfo: false,
      branch: {
        create: false,
        view: false,
        delete: false,
      },
      branchSecondaryInfo: false,
      user: {
        paidLeaveCount: false,
        verifyDoc: false,
        create: false,
        update: false,
        view: false,
        viewAll: false,
        delete: false,
      },
      fleets: {
        create: false,
        update: false,
        view: false,
      },
      // assetBalance: false,
      // assetCount:false,
      department: {
        create: false,
        update: false,
      },
      transportationCharge: false,
      //   assetBalance:false,
      //   individualInfo: false,
      //  individualWithFleet: false,
    },
    paymentType: {
      create: false,
      view: false,
    },
    assetType: false,
    rewards: false,
    verification: {
      pettyCashList: false,
      expense: {
        view: false,
        accept: false,
        reject: false,
      },
      verifiedPackages: false,
      pod: {
        unverifiedPODlist: false,
        verifyPOD: false,
      },
      verifySettledAmount: false,
      // shortage: false,
      //  cancelBookings: false,
      //  processCancelBookings: false,
      controlTower: false,
    },
    customer: {
      view: false,
      edit: false,
      viewPastBooking: false,
      viewMonthWiseBooking: false,
      branchOnly: false,
      allCustomers: false,
    },
    route: {
      routes:false,
      individualRouteDetails:false,
      routeReport:false,
      services:false,
      manageOldRouteSystem:false,
      branchDistanceTime:false,
      branchRoute:false,
      routeBundle:{
        create:false,
        view:false
      }
    },
    pricing:{
    serviceSubType:{
      create:false,
      view:false
    },
    standardRates:{
      create:false,
      view:false
    },
    volumeDiscount:{
      create:false,
      view:false
    }
    },
    creditCustomers: {
      creditCustomer: false,
      creditBillList: false,
      // creditIncomeSettle: false,
      verifyCreditPermisson: false,
      creditVerify: {
        view: false,
        process: false,
      },
      assignCredit: false,
      creditReport: false,
    },
    fixedCustomer: {
      fixedCustomerRateList: {
        Update: false,
        view: false,
      },
      fixedCustomerList: false,
      fixedCustomerDiscountList: false,
    },
    crm: {
      newCustomer: false,
      lostCustomer: false,
      customerRequirementList: false,
      customerCommunication: false,
      customerNameUpdate: false,
      pendingDeliveryCallRecords: false,
      voiceCall: {
      allView: false,
      view: false,
      },
    },
    telecaller: {
      assignCustomer: false,
      handleCustomer: false,
    },
    telecallerUserAssignment: false,
    hrm: {
      allowOverATime: false,
      updatePaidLeaveCount: false,
      applyPaidLeave: false,
      taskViewAll: false,
      attendenceReport: false,
      leaveReport: false,
      holiday: {
        set: false,
        view: false,
      },
      verifyAttendance: {
        view: false,
        process: false,
      },
      createJobPost: false,
      performanceReview: {
        create: false,
        view: false,
        allUser: false
      },
      salaryReport : false,
      attendanceSummaryReport: false,
      peerReviewReport: false,
      updateBreakLogs: false
      // verifyDoc: false,
    },
    task: {
      create: false
    },
    subTask: {
      activityCost: false
    },
    boardSection: {
      activityCost: false
    },
    internalCommunication: {
      viewAll: false,
      createAll: false
    },
    expenseReport: {
      entityWiseExpenseReport: false,
      monthWiseExpense: false,
      dayWiseExpense: false,
      expenseBudget: false,
    },
    demurrageReport: {
      demurrageMonthWise: false,
      demurrageBranchWise: false,
      demurrageDayWise: false,
      demurrageDocketWise: false,
    },
    discountReport: {
      discountMonthWise: false,
      discountBranchWise: false,
      discountDayWise: false,
      discountDocketWise: false,
      shortageAdmin: false,
    },
    deliveryChargeReport: {
      deliveryChargeMonthWise: false,
      deliveryChargeBranchWise: false,
      deliveryChargeDayWise: false,
    },
    revenueReport: {
      bookingDocketWise: false,
      revenueSummary: false,
      revenueMonthWise: false,
      branchToBranchDayWise: false,
      branchToBranchMonthWise: false,
      revenueDayWise: false,
      doorDeliveryBranchDayWise: false,
      doorDeliveryDayWise: false,
      doorDeliveryDocketWise: false,
      shortage: false,
      branchwise: false,
      deliveryDocketWise: false,
      deliveryDocketWiseDetails: false,
      // booking: false,
      // bookingSummary: false,
      // delivery: false,
      // shortage: false,
      // docketwiseDiscount: false,
      // branchwiseDemurrage: false,
    },
    accountReport: {
      pendingMemos: false,
      userBalance: false,
      transferList: false,
      venderSettlement: false,
      tallySync: false,
      incomeReport: false,
      incentiveReport: false,
      branchWisePettyCash: false,
      bookingSummary: false,
      expenseReport: false,
      ccavenueTxnsDayWise:false,
      secondaryCompanySettlement: false
      // bookingSummary: false,
      // transfer: false,
      // vendor: false,
      // tallySync: false,
      // delivery: false,
      // expense: false,
      // income: false,
      // booking: false
    },
    kpiReport: {
      branchRank: false,
      branchwiseOperationKPI: false,
      branchwisePeformanceKPI: false,
      fuelEfficiencyRank: false,
      userActivity: false,
      userActivityDayWise: false,
      userActivityEfficiency: false,
      userIssue: false,
      // branchwiseKPI: false,
      // fuel: false,
      // userActivity: false,
      graceTowerSummary: false
    },
    travelsReport: {
      occupancyReport: false,
      ticketSettlement: {
        view: false,
        creditIncomeSettle: false,
      },
      // income: false,
      // creditor: false,
    },
    transportMISReport: {
      routeMISReport: false,
      MISReport: false,
      branchMISReport: false,
    },
    oldReports: {
      bookingReport: false,
      deliveryReport: false,
    },
    userIssue: false,
    home: false,
    customerOrders: false,
    branchOperations: {
      booking: {
        create: false,
        edit: false,
        delete: false,
      },
      fetchDocket: {
        fetch: false,
        viewCustomerTrackingNumber: false,
        updateDocketSize: false,
      },
      loading: false,
      unloading: false,
      doorDelivery: {
        view: false,
        viewAllBranches: false,
        pendingDeliverywithConact: false
      },
      backToHub: {
        view: false,
        viewAllBranches: false
      },
      delivery: false,
      verifyStocks: false,
      qrVerifyStocks: false,
      hubList: false,
      parcelTransferAllUser: false,
      // pendingProofOfDelievery: false,
      // verifyPackages: false,
      // viewLoadingAll: false,
      // viewUnloadingAll: false,
      // track: false,
      // extraDocket: false,
      // branchDistanceTime: false,
      // branchRoute: false,
    },
    fleetOperations: {
      tripPlanning: {
        create: false,
        edit: false,
        view: false,
        favouriteFleetAdmin: false,
        fleetOrderAdmin: false,
      },
      // fleetActivityLogs: false,
      fleetOrder: false,
      fleetTower: false,
      outForDelievery: false,
      fleetDriver: {
        edit: false,
        delete: false,
      },
      fleetStatus: {
        view: false,
        addGSTCustody: false,
        removeGSTCustody: false,
      },
      // tripList:false,
      // create: false,//copy
      // edit: false,//copy
      // parcelTransfer: false,
      // view: false,//copy
      // fleetOrderAdmin:false,//copy
      // favouriteFleetAdmin: false,
    },
    operationRequest: {
      cancelBookings: {
        view: false,
        process: false,
        createAll: false
      },
      cancelDoorDeliveryBooking: {
        view: false,
        process: false,
      },
      changeDestination: {
        view: false,
        process: false,
      },
      packageUpdateRequestList: {
        view: false,
        process: false,
      },
      remarksRequest: {
        view: false,
        process: false,
      },
      parcelTransferRequest: {
        view: false,
        process: false,
      },
      reCheckInReq: {
        view: false,
        process: false,
      },
      damurrage: {
        view: false,
        process: false,
      },
      gstCustodyPenalty: {
        view: false,
        process: false
      },
      discount: {
        view: false,
        process: false,
      },
      doorDeliveryDiscount: {
        view: false,
        process: false,
      },
      branchDelivery: {
        view: false,
        process: false,
      },
      backToHub: {
        view: false,
        process: false,
      },
      gstCustodyRequest: {
        view: false,
        process: false,
      },

      claimSettlement: {
        view: false,
        remarkReqProcess: false,
        transportChargeClaim: false,
        goodsValueClaim: false,
      },
      bookingUpdate: {
        view:false,
        process: false
      },
      missingRequest: {
        view: false,
        process: false
      }
      // trackingNumber: false,
      // BTH: false,
      // damrrage: false,
      // ND: false,
      // remarksRequest: false,
      // discount: false,
      // cancelBookings: false,
      // processCancelBookings: false,
      // claimSettlement: false,
      // reCheckInReq: false,
      // parcelTransferRequest: false,
      // gstCustodyRequest:false
    },
    fleetOrder:{
      branchFleetOrder:{
        view:false
      },
      intracityFleetOrder:{
        view:false
      },
      partLoadFleetOrder:{
        view:false
      },
      fullLoadFleetOrder:{
        view:false
      }
    },
    marketingTools: {
      listbranch: false,
      mapView: false,
      // syncState: false,
    },
    addFleetDistanceTracking: false,
    feedback: {
      create: false,
      list: false,
      edit: false,
      viewAllBranches: false,
      analysis: false,
      process: false,
      //In Complain List
      myDutyProcessRight: false,
      viewComplaintsChat: false,
      chatInAllComplaints: false,
      intraCity:{
        view:false
      },
      partLoad:{
        view:false
      },
      fullLoad:{
        view:false
      }
    },
    branchReport: {
      bookedReport: false,
      ewaybill: false,
      loadingReport: false,
      inTransitReport: false,
      pendingDeliveryReport: false,
      loadingReportList: false,
      fetchReportList: false,
      incidentalDocketsReport: false,
      customerTrackingNumber: false,
      eWayBillInvoiceView: false,
    },
    fleetReport: {
      fleetUtilization: false,
      fleetDistanceTracking: false,
      parcelTransferLogs: false,
      fuelReport: false,
      fleetActivityLogs: {
        view: false,
        viewPackagesDetails: false,
      },
      fleetSummary: false,
      dayWiseFleetSummary: false,
      allFleetDayWiseSummary: false,
      //copy
    },
    prospectiveCustomer: false,
    asset: {
      manageAsset: false,
      // types: false,
      // balance: false,
      // supplier:false,
      create: false,
      view: false,
      process: false,
      transfer: false,
      tracking: false,
      expenses: false,
    },

    myProfile: false,
    wallet: {
      companyWallet: {
        view: false,
        transfer: false,
        individualSettlement: false,
        transactionDetails: false,
        franchiseMemoHistory: false,
        viewAllUserDetails: false,
        viewAllCompanyUserDetails: false,
      },
      CCAvenueTxns:{
        view:false,
        viewAll:false
      },
      advancePaymentToken:{
        view:false,
        viewAll:false
      },
      franchiseMemoHistory: {
        send: false,
        receive: false,
      },
      incurExpense: {
        incur: false,
        nonCash: false,
        adminLevelIncur: false,
        edit: false,
        delete: false,
      },
      incurIncome: {
        incur: false,
        nonCash: false,
        adminLevelIncur: false,
        edit: false,
        delete: false,
      },
      userWallet: false,
      franchiseWallet: false,
    },
    attendance: {
      add: false,
      view: false,
      apply: {
        applyLeave: false,
        applyPaidLeave: false,
      },
      // report: false,
      leaveReport: false,
      // handBook: false,
      processRequest: false,
      viewAll: false,
      removeAttendance: false,
    },
    salary: false,
    activityReport: false,
    training: false,
    organogram: false,
    performanceReview: false,
    performanceReviewTool: {
      allUser: false,
    },
    checkListForm: {
      create: false,
      view: false,
    },
    preLogoutcheck: false,
    // individualInfo: false,
    dashboard2: false,
    // listbranch: false,
    // holiday: {
    //   create: false,
    //   viewAll: false,
    // },
    // creditor: false,
    // trip: { create: false, edit: false, view: false },
    // zone: {
    //   create: false,
    //   edit: false,
    //   delete: false,
    // },
    // department: false,
    // fuel: {
    //   create: false,
    //   edit: false,
    //   report: false,
    // },
    // creditSettle: false,
    // creditIncomeSettle: false,
    // creditCustomer: false,
    // route: {
    //   create: false,
    //   edit: false,
    //   delete: false,
    //   view: false,
    //   // branchDistanceTime: false,
    //   // branchRoute: false,
    // },
    // rate: {
    //   create: false,
    //   edit: false,
    //   delete: false,
    //   view: false,
    // },
    // report: {
    //   ewaybill: false,
    //   expense: false,
    //   income: false,
    //   daily: false,
    //  bookingSummary: false,
    //  loading: false,
    //   fuel: false,
    //   MIS: false,
    //   branchMIS: false,
    //  branchRank: false,
    //   pendingMemos: false,
    //  shortage: false,
    //   shortageAdmin: false,
    //   userIssue: false,
    //   userBalance: false,
    //   incomingDocket: false,
    //  fleetUtilization: false,
    //   activityReport: false,
    //   branchwiseDemurrage: false,
    //  booking: false,
    //  delivery: false,
    //  parcelTransferLogs: false,
    //   branchwiseKPI: false,
    //   docketwiseDiscount: false,
    //   creditor: false,
    //   vendor: false,
    //   tallySync: false,
    //   transfer: false,
    //   userActivity: false,
    // },  
    // memo: false,
    nonCash: false,
    // assign: {
    //   branch: false,
    //   department: false,
    //   fleet: false,
    // },
    // remarks: false,

    // transfer: false,
    franchise: false,
    // franchiseReport: false,
    notification: {
      checkIn: false,
      leave: false,
      cancelBuilty: false,
      unverifiedEwayBill: false,
      closeBranch: false,
      branchLateOpenAlert: false,
      userAbsentAlert: false,
      branchClosedAlert: false,
      userCashAmountAlert: false,
      vehicleOperation: false,
      accountsCalender: false,
    },
    // nonCashRequests: false,
    // missingList: false,
    // companySection: false,
    // individualSettlement: false,
    serviceSubType:{
      create:false,
      view:false
    },
    builtyBanner: {
      create: false,
      view: false,
      update: false
    },
    reviewRating:{
      expressParcel:{
        view:false
      },
      intraCity:{
        view:false
      },
      partLoad:{
        view:false
      },
      fullLoad:{
        view:false
      },
    },
    partLoadRevenueReport:{
      bookingReport:false,
      bookingReportSummary:false,
      bookingReportMonthWise:false,
    },
    fullLoadRevenueReport:{
     bookingReport:false,
     bookingReportSummary:false,
     bookingReportMonthWise:false
    },
    customerLeads:{
      expressParcel:{
        view:false,
      },
      intraCity:{
        view:false,
      },
      partLoad:{
        view:false,
      },
      fullLoad:{
        view:false
      },
      courier:{
        view:false,
      },
    }
  };

  //setting overall rights
  departments.forEach(({ rights }) => {
    defaultRights = recursiveMerge(defaultRights, rights);
  });

  return defaultRights;
};

export const processAppRights = (
  appDepartments: {
    rights: IAppDepartmentRights<boolean>;
  }[]
) => {
  let defaultAppRights: IAppDepartmentRights<boolean> = {
    branchOperation: {
      booking: {
        viewDetailed: false,
        viewAll: false,
      },
      loading: {
        createAllCrossingBranch: false,
        missingRequestAdmin: false,
      },
      delivery: {
        viewAllTrackingReq: false,
      },
      backToHub: {
        bthAllEntity: false,
      }
    },
    fleetOperations: {
      fleetStatus: {
        EwayBillUpdate: false,
      },
      outForDelivery: {
        viewAllDoorDeliveryReq: false,
      }
    },
    operationRequest: {
      cancelBookingRequest: {
        viewCancelBookingReq: false,
        acceptCancleBookingReq: false,
        processCancelRequired: false,
        allowCancel: false
      },
      changeDestinationReq: {
        acceptBTOReq: false,
        viewAllBTHReq: false,
        acceptBTHReq: false,
        viewAllNDReq: false,
        acceptNDReq: false,
      },
      demurrageRequest: {
        viewAllDemurrageReq: false,
        acceptDemurrageReq: false,
      },
      gstCustodyPenaltyRequest: {
        viewAllGstCustodyPenaltyReq: false,
        acceptGstCustodyPenaltyReq: false
      },
      discountRequest: {
        viewDiscountReq: false,
        acceptDiscountReq: false,
      },
      reCheckInRequest: {
        processReCheckInReq: false,
        viewReCheckInReq: false,
      },
      remarkRequest: {
        viewAllRemarksReq: false,
        acceptRemarksReq: false,
      },
      docketTransferReq: {
        processParcelTransfer: false,
      },
      packageUpdateReq: {
        processPackageUpdateRequest: false,
      },
      branchDeliveryChargeRequest: {
        processBranchDeliveryChargeReq: false,
        viewBranchDeliveryChargeReq: false
      },
      fleetGstCustody: false,
      claimSettlementRequest: {
        acceptRemarksTransportCharge: false,
        initiateRemarksCompensation: false,
        acceptRemarksCompensation: false,
        processGstCustodyPenaltyCompensation: false
      },
      bookingUpdate: {
        viewAllBookingUpdateReq: false,
        acceptBookingUpdateReq:  false,
      },
      missingRequest: {
        viewMissingRequest: false
      }
    },
    myCompany: {
      branch: {
        createAll: false,
        viewAll: false,
        updateAll: false,
      },
      user: {
        manageAll: false,
        verifyDocs:false,
      },
      fleet: { 
        manageAll: false,
        verifyDocs:false,
       },
    },

    feedback: {
      processResolveIssueReq: false,
    },
    fleetReport: {
      fleetActivityAmount: false,
      fleetSummary: false,
    },
    CCAvenueTxnslist:{
      manualVerification:false
    },
    franchiseWallet: {
      manageAll: false,
      viewAllCompanyReports: false,
      viewAllCompanyMonthlyReports:false,
      viewOutstandingReports: false,
      customerMemoReport: false,
      dailyMemoReport: false,
      doorDeliveryReports: false,
    },
    entities: {
      company: {
        createAll: false,
        updateAll: false,
        updateDepartmentAll: false,
        verifyDocs: false,
        agreement: false,
        addOtherCharges: false,
      },
    },
    notification: {
      unverifiedPkgAlert: false,
      companyBalanceAlert: false,
      creditCustomerBalanceAlert: false,
      pendingMissingReqAlert: false,
    },
    paymentType: false,
    verification: {
      pettyCash: false,
    },
    nonCash: {
      viewAllRequest: false,
      acceptRequest: false,
      rejectRequest: false,
    },
    creditCustomer: {
      verify: false,
      creditAll: false,
    },
    customerWallet: {
      customerBalanceReport: false,
      customerTransactionList: false,
    },
    fixedCustomer: {
      directConnectCustomer: false,
    },
    platformSettings: {
      smsTemplate: false,
      appDepartment: false,
      assignAppDepartment: false,
      materialTypes: false,
      packingTypes: false,
      handlingChargeSlab:false
    },
    standardRate:false,
    averageRate: false,
    customerModule: {
      assetType: false,
      intraCityRates: false,
    },
    customerCRM: {
      newCustomer: false,
      lostCustomer: false,
      customersFixedRates: false,
      connectedCustomerList:false,
      allCommunication: false,
      fixedCustomerDiscountList: false,
      customerNameUpdate: false,
      viewAllCustomer: false,
      processCustomerRequirement: false,
      processCustomerRateRequirement: false
    },
    demurrageReport: {
      demurrageAll: false,
    },
    discountReport: {
      discountAll: false,
    },
    deliveryChargeReport: {
      deliveryChargeAll: false,
    },
    expenseReport: {
      expenseAll: false,
    },
    revenueReport: {
      booking: false,
      delivery: false,
      doorDeliveryDocketwise:false,
      deliveryAll: false,
      branchwise: false,
      branchToBranchDayWise: false,
      branchToBranchMonthWise: false,
      bookingReportDetails: false,
    },
    kpiReport: {
      branchRank: false,
      graceTowerSummaryAllCompany: false
    },
    audit: {
      suspectedNumber: false,
      connectCustomer: {
        viewCustomerConnect: false,
        acceptCustomerConnect: false,
      }
    },
    oldReports: {
      booking: false,
      delivery: false,
    },

    asset: {
      viewAll: false,
      processTransfer: false,
      transferAutoApproval: false,
      create: false,
      transfer: false,
    },
    gstReport: {
      view: false,
    },
    serviceSubType:{
      create:false,
      view:false
    },
    builtyBanner: {
      create: false,
      view: false,
      update: false
    },
    task: {
      taskPlanningRelaxation: false 
    },
    platformServiceRights:{
      assign: false
    },
    leads:{
      investor: false
    }
  };

  if (appDepartments && Array.isArray(appDepartments)) {
    appDepartments.forEach((department) => {
      if (department && department.rights) {
        defaultAppRights = recursiveMerge(defaultAppRights, department.rights);
      }
    });
  }
  return defaultAppRights
};
