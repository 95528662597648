import React from "react";
import "./ErrorBoundary.css";
import { MdError } from "react-icons/md";
import { Button } from "../../../newSrc/components/UI";
import { showHttpError } from "../../../utils/message";

type ErrorBoundaryProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
    showHttpError(error);
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null });
    window.location.reload();
  };

  render(): React.ReactNode {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }
      return (
        <div className="taptap-error-boundary">
          <div className="taptap-error-boundary-content">
            <MdError className="taptap-error-boundary-error-icon" />
            <h1>Something went wrong.</h1>
            <p>
              Brace yourself till we get the error fixed. You may also refresh
              the page or try again later!
            </p>
            {this.state.error && (
              <p className="text-danger-400">
                Error: {this.state.error.message || "Something went wrong!"}
              </p>
            )}
            <div className="flex-center gap-1">
              <Button onClick={this.handleRetry}>Retry</Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

/**
 * ErrorBoundary Component
 * =======================
 * Description:
 * - A React component that catches JavaScript errors anywhere in its child component tree, logs those errors, and displays a fallback UI instead of the component tree that crashed.
 *
 * Props:
 * - children (ReactNode): The child components to be rendered within the error boundary.
 * - fallback (ReactNode): Optional fallback UI to be displayed when an error is caught.
 *
 * State:
 * - hasError (boolean): Indicates whether an error has been caught.
 * - error (Error | null): The error object that was caught.
 *
 * Methods:
 * - static getDerivedStateFromError(error: Error): Updates the state to indicate an error has been caught.
 * - componentDidCatch(error: Error, errorInfo: React.ErrorInfo): Logs the error and error information.
 * - handleRetry(): Resets the error state to allow retrying the rendering of child components.
 *
 * Usage:
 * ```jsx
 * import ErrorBoundary from './path/to/ErrorBoundary';
 *
 * function App() {
 *   return (
 *     <ErrorBoundary fallback={<div>Something went wrong. Please try again later.</div>}>
 *       <YourComponent />
 *     </ErrorBoundary>
 *   );
 * }
 *
 * export default App;
 * */
