export const fleetRegNumberRegex = /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/;

export const messageRegex = /^[a-zA-Z0-9 ,./?;':"<>\[\]{}\\|()!@#$%&*-_+=\n]+$/;

// 1st december
export const voucherImageRelaxationDate = new Date(2020, 11, 1, 0, 0, 0, 0);
// 10-December 2020
export const verifyPackagesEnforceDate = new Date(2021, 11, 10, 0, 0, 0, 0);
// Package must be verified every before 8 days of last verification
export const verifyPackageThreshold = 8;

export enum ServiceType {
  FullLoad = "FullLoad",
  PartLoad = "PartLoad",
  // DoorDelivery = "DoorDelivery",
  // DoorPickup = "DoorPickup",
  IntraCity = "IntraCity",
  PackersAndMovers = "PackersAndMovers",
}

// verify docket receiver by otp from 24-dec
export const verifyDocketReceiverRelaxationDate = new Date(
  2020,
  11,
  24,
  0,
  0,
  0,
  0
);

export const creditorType = [
  { label: "All", value: "" },
  { label: "PayTM", value: "PayTM" },
  { label: "RedBus", value: "RedBus" },
  { label: "Mantis", value: "Mantis" },
  { label: "Branch", value: "Branch" },
  { label: "Agent", value: "Agent" },
  { label: "Other", value: "Other" },
];

export const sendMemoRestriction = 10;

export const denominator = 2700;

export const reCaptchaKey = "6Ld5ft8bAAAAADAjHq8-bSS3meE2QVvRc7Yu0egS";

export const gstRegex = /(\d{2}.{10}\d[A-Z].)|^$/;

export const bookingGstForceNameUpdateReq = false;
export const bookingMaxGstCount = 5;
export const bookingMaxSecondaryContactCount = 4;
export const bookingMaxNameCount = 3;

export const COLOR = {
  PRIMARY_50: "#ccebff",
  PRIMARY_100: "#1aa3ff",
  PRIMARY_200: "#09f",
  PRIMARY_300: "#008ae6",
  PRIMARY_400: "#007acc",
  PRIMARY_500: "#006bb3",
  PRIMARY_600: "#005c99",
  PRIMARY_700: "#004c80",
  PRIMARY_800: "#003d66",
  PRIMARY_900: "#002e4d",

  SECONDARY_50: "#deedeb",
  SECONDARY_100: "#d0e6e3",
  SECONDARY_200: "#bcdcd8",
  SECONDARY_300: "#b6d8d4",
  SECONDARY_400: "#afd5d0",
  SECONDARY_500: "#a8d1cc",
  SECONDARY_600: "#a1cec8",
  SECONDARY_700: "#9bcac4",
  SECONDARY_800: "#94c7c0",
  SECONDARY_900: "#46867d",

  SUCCESS_50: "#d4f7d4",
  SUCCESS_100: "#52e052",
  SUCCESS_200: "#3cdd3c",
  SUCCESS_300: "#26d926",
  SUCCESS_400: "#22c322",
  SUCCESS_500: "#1fad1f",
  SUCCESS_600: "#1b981b",
  SUCCESS_700: "#178217",
  SUCCESS_800: "#136c13",
  SUCCESS_900: "#0f570f",

  DANGER_50: "#fbdfdf",
  DANGER_100: "#f28c8c",
  DANGER_200: "#f07575",
  DANGER_300: "#ed5e5e",
  DANGER_400: "#eb4747",
  DANGER_500: "#e83030",
  DANGER_600: "#e61919",
  DANGER_700: "#cf1717",
  DANGER_800: "#b81414",
  DANGER_900: "#a11212",

  WARNING_50: "#fec",
  WARNING_100: "#ffc34d",
  WARNING_200: "#fb3",
  WARNING_300: "#ffb31a3",
  WARNING_400: "#fa0",
  WARNING_500: "#e69900",
  WARNING_600: "#c80",
  WARNING_700: "#b37700",
  WARNING_800: "#960",
  WARNING_900: "#805500",
};

export const mapStyleTheme = [
  { elementType: "geometry", stylers: [{ color: "#EAF6FF" }] }, // Soft pastel blue background
  { elementType: "labels.text.fill", stylers: [{ color: "#4A6FA5" }] }, // Softer muted blue-gray text
  { elementType: "labels.text.stroke", stylers: [{ color: "#F2F9FF" }] }, // Light pastel outline
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#9BD3F3" }],
  }, // Darker pastel blue water for better visibility
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#D9D9D9" }],
  }, // Light gray roads
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#B0B0B0" }],
  }, // Slightly darker gray road borders
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{ color: "#B0B0B0" }],
  }, // Medium gray highways for better contrast
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#8E8E8E" }],
  }, // Stronger outline for better readability
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ color: "#C4C4C4" }],
  }, // Darker gray arterial roads
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [{ color: "#9E9E9E" }],
  }, // Defined arterial road strokes
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [{ color: "#E0E0E0" }],
  }, // Light gray local roads
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [{ color: "#B3B3B3" }],
  }, // Slightly darker local road borders
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  }, // Hide local road labels
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#D7F3E3" }],
  }, // Soft pastel green for parks
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  }, // Hide POI labels
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#BFDFF2" }],
  }, // Softer pastel transit lines
  {
    featureType: "transit",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  }, // Hide transit labels for a cleaner UI
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ color: "#F5FAFF" }],
  }, // Pastel blue-gray land areas
];

export const MIN_TIME_FOR_SKELETON_LOADING = 2000;

export const COLORS_FOR_CIRCLE_PACKING_BUBBLE_CHART = [
  "#33A1FF", // Bright Blue
  "#FFD700", // Gold
  "#4CAF50", // Green
  "#FF33A8", // Pink
  "#8A2BE2", // Blue Violet
  "#FF9800", // Deep Orange
  "#00BCD4", // Cyan
  "#9C27B0", // Purple
  "#E91E63", // Deep Pink
  "#FF5733", // Vibrant Red-Orange
];