import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCustomerNotificationService,
  getCustomerPendingActionNotificationCount,
} from "../../../services/user";
import { showHttpError } from "../../../utils/message";
import { RootState } from "../../store";
import { setNotificationLoading } from "./notificationSlice";

export const customernotificationService = createAsyncThunk(
  "notification/customernotification",
  async (_, { dispatch }) => {
    try {
      const initialOffset = 0;
      const fetchLimit = 20;
      const newNoti = await getCustomerNotificationService({
        offset: initialOffset,
        limit: fetchLimit,
      });
      const pendingActinoNotificationCount =
        await getCustomerPendingActionNotificationCount();
      if (newNoti) {
        return {
          notifications: newNoti,
          notificationLoadMore: newNoti.length === fetchLimit,
          offset: initialOffset,
          pendingActinoNotificationCount: pendingActinoNotificationCount,
        };
      }
    } catch (error) {
      showHttpError(error);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const loadMoreCustomerNotifications = createAsyncThunk(
  "notification/loadMoreNotifications",
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const notifications = state.memo?.notifications;
      const offset = (notifications && notifications.length) || 0;
      const newNoti = await getCustomerNotificationService({
        offset,
        limit: 20,
      });
      dispatch(setNotificationLoading(true));
      const data1 = await updatePendingActionCustometNotificationCountFun();
      const notificationPayload = {
        notifications: newNoti,
        notificationLoadMore: newNoti.length === 20,
        offset,
        updatePendingActionNotificationCountFunction: data1,
      };
      return notificationPayload;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch(setNotificationLoading(false));
    }
  }
);

export const updatePendingActionCustometNotificationCountFun =
  () => async () => {
    try {
      const response = await getCustomerPendingActionNotificationCount();
      return response;
    } catch (error) {
      showHttpError(error);
    }
  };

export const updatePendingActionCustomerNotificationCountAction =
  createAsyncThunk(
    "notification/updatePendingActionCustomerNotificationCountAction",
    async () => {
      try {
        const response = await getCustomerPendingActionNotificationCount();
        return response;
      } catch (error) {
        showHttpError(error);
      }
    }
  );
