import axios from "axios";
import { baseURL } from "../../../axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginPayload } from "./user.type";
import { RootState } from "../../store";
import {
  makeDocketMapRegNumWise,
  setupLocationInterval,
} from "./helper";
import { toastMessage } from "../../../Components/showMessages/toastMessage";
import { setRights } from "../rights/rightSlice";
import { setAppRights } from "../rights/appRightSlice";
import * as auth from "../../../actions/auth";
import { fetchFleets } from "../fleet/api";
import { listFilteredBranches, setBranches } from "../branch/api";
import { fetchUserAllBalances, setCities } from "../../globalApis/globalApi";
import { notificationService } from "../memo/api";
import {
  sidebareBadgesCount,
  updateOperationsAllCount,
} from "../sidebareBadges/api";
import { getLoadingReportList } from "../../../services/report";
import { pendingApproval } from "../../../services/franchise";
import showMessage, {
  failed,
  showHttpError,
  success,
} from "../../../utils/message";
import {
  fetchIncentiveAmount,
  fetchRouteForExpense,
} from "../../../services/expenses";
import { countsCCAvenueTxns } from "../../../services/paymentGateWay";
import { getOperationsCount } from "../../../services/operationsCount";
import { setOperatingBranch, setOperatingFleet } from "./userSlice";
import { resetOperatingCount } from "../sidebareBadges/sidebarBadgesSlice";
import jsonToFormdata from "../../../utils/jsonToFormdata";
import { addAttendanceService } from "../../../services/attendance";
import {
  HIDE_LOADER,
  SET_SENDER_ADDRESS_FROM_OPBRANCH,
  SHOW_LOADER,
} from "../../../constants/booking";
import { getOutForDeliveryData } from "../../../services/fleet";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import { fetchUnverifiedDocket } from "../../../services/verifyPOD";
import { listByCompany } from "../../../services/route";
import { getCityByPincode } from "../../../services/area";
import { changeSimple } from "../branch/branchSlice";
import { returnODAPackagesService } from "../../../services/delivery";
import { getCompanyList } from "../company/api";
import socket from "../../../socket/socket"

let watchLocationId: any;
// Async thunk for login
export const loginUser = createAsyncThunk(
  "auth/login",
  async (
    { contact, pwd, otp, deviceId }: LoginPayload,
    { dispatch, getState }
  ) => {
    const version = "1.0.5";
    const flag = {
      username: contact,
      password: pwd,
      OTP: otp,
      deviceId,
      version,
    };
    const url = baseURL;
    try {
      dispatch({ type: "START_LOADER" });

      const response = await axios.post(url + "/login", flag);
      toastMessage("Successfully signed-in!");
      sessionStorage.setItem("kabra:token", response.data.accessToken);
      sessionStorage.setItem("kabra:user", response.data.response.user._id);
      if (response.data.response.deviceId) {
        localStorage.setItem("ud", response.data.response.deviceId);
      }
      localStorage.setItem("loginBy", "transporter");

      if (response.data.response.otpRequired) {
        dispatch({ type: "END_LOADER" });
        return true;
      }
      let cid = response.data.response.user.company._id;
      const { _id: loggedInUser } = response.data.response.user;
      if (!cid || !loggedInUser) throw "fail";

      const { user } = response.data.response;
      const accessToken = response.data.accessToken;
      const refreshToken = response.data.refreshToken;
      // const token = response.data.accessToken;
      const intervalId = setupLocationInterval(
        getState as () => RootState,
        socket
      );
      const rightPayload = {
        rights: response.data.response.user.departments,
        role: response.data.role,
      };
      const appRights = response.data.response.user.appDepartments;
      dispatch(setRights(rightPayload));
      dispatch(setAppRights(appRights));
      dispatch(auth.registerSocket({ token: accessToken }));
      // // // Dispatch all required initial data fetching
      dispatch(fetchFleets());
      dispatch(setBranches());
      await dispatch(fetchUserAllBalances(user._id)).unwrap();
      dispatch(setCities());
      dispatch(listFilteredBranches(user.company._id));
      dispatch(notificationService(user._id));
      dispatch(getCompanyList()).unwrap();
      const sidebarBadgesPayload = {
        userId: user._id,
      };
      dispatch(sidebareBadgesCount(sidebarBadgesPayload));
      // Handle rights-based functionality
      // const state = getState() as RootState;
      const additionalApiPayload = {
        userId: user._id,
        company: user.company._id,
      };
      dispatch(loginAdditionalApis(additionalApiPayload));
      // ... other rights-based checks and dispatches
      const allowAttendanceFromAnywhere =
        response &&
        response.data.response.user &&
        response.data.response.user.userType &&
        response.data.response.user.userType.allowAttendanceFromAnywhere
          ? true
          : false;

      const data = response.data.response;

      return {
        user,
        todayCheckIn: data.todayCheckIn,
        thisMonthAttendanceCount: data.thisMonthAttendanceCount,
        userName: data.user.name.fName,
        fullName: data.user.name.fName + " " + data.user.name.lName,
        uid: data.user._id,
        contact: data.user.contact,
        maxBalance: data.user.maxBalance,
        token: "5b0ce34h6j",
        photoReq: data.user.photoReq,
        // rights: dep,
        branches: data.user.branches,
        fleets: data.user.fleets,
        userTypeForOrganogram: data.user.userType,
        userType: data.user.userType,
        userShiftStart: (data.user.userShiftDuty || {}).startTime,
        userShiftEnd: (data.user.userShiftDuty || {}).endTime,
        company: {
          id: data.user.company._id,
          name: data.user.company.companyName,
          // sizes: data.user.company.sizes,
          ...data.user.company,
        },
        documents: {
          user: {
            aadharCard: data?.user?.aadharCard ?? false,
            licence: data?.user?.licence ?? false,
            panCard: data?.user?.panCard ?? false,
          },
          fleet: {
            rcBook:
              data?.user?.fleets && data?.user?.fleets?.length > 0
                ? data?.user?.fleets[0]?.rcBook
                : false,
            insurance:
              data?.user?.fleets && data?.user?.fleets?.length > 0
                ? data?.user?.fleets[0]?.insurance
                : false,
            allIndiaPermit:
              data?.user?.fleets && data?.user?.fleets?.length > 0
                ? data?.user?.fleets[0]?.allIndiaPermit
                : false,
          },
        },
        accessToken,
        refreshToken,
        watchLocationId,
        intervalId,
        allowAttendanceFromAnywhere: allowAttendanceFromAnywhere,
      };
    } catch (err: any) {
      let errorMessage = "Login failed";
      if (err.response) {
        errorMessage = err.response.data.message || "Invalid credentials!";
      } else if (err.request) {
        errorMessage =
          err.message === "Network Error" ? "No Internet!" : err.message;
      }
      showHttpError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const logoutUser = createAsyncThunk("auth/logout", (_, { dispatch }) => {
  socket.emit("logout", sessionStorage.getItem("kabra:token"));
  dispatch({ type: "LOG_OUT" });
  console.log(`jsnlvzdfv  7`)
  // navigator.geolocation.clearWatch(watchLocationId);
});

export const loginAdditionalApis = createAsyncThunk(
  "auth/reports",
  async (
    { userId, company }: { userId: string; company: string },
    { dispatch }
  ) => {
    try {
      const loadingReportList = await getLoadingReportList({
        receiver: userId,
        latest: true,
      });
      const franchiseRequestsNotification =
        await setFranchiseRequestsNotifications(company);

      const updateIncentiveAmount = await updateIncentive();
      const CCAvenueTxnsCountAmount = await CCAvenueTxnsCount({
        user: userId,
        countTypes: ["notSettled"],
      });
      const data = {
        loadingReportList: loadingReportList,
        franchiseRequestsNotification,
        updateIncentiveAmount,
        CCAvenueTxnsCountAmount,
      };
      console.log(data, "quwyefqywufeuqfueqw");
      return data;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

const setFranchiseRequestsNotifications = async (company: string) => {
  try {
    const data = await pendingApproval(company);

    let parentCompanies = data.map((d: any) => d.parent_company);
    let childCompanies = data.map((d: any) => d.child_company);
    let companies = parentCompanies.concat(childCompanies);
    let companyMap = new Set(companies);

    let companySet = new Set();

    data.forEach((r: any) => {
      const { parent_company, child_company } = r;
      let intentCompany =
        parent_company != company ? parent_company : child_company;
      if (companyMap.has(intentCompany) && child_company == company) {
        companySet.add(intentCompany);
      }
    });
    const franchiseRequests = Array.from(companySet);
    return franchiseRequests;
    // dispatch({
    //   type: "INIT:SET_FRANCHISE_REQUESTS",
    //   payload: franchiseRequests,
    // });
  } catch (err: any) {
    showHttpError(err);
  }
};

const updateIncentive = async () => {
  try {
    let amount = await fetchIncentiveAmount();
    return amount;
  } catch (err: any) {
    showHttpError(err);
  }
};

const CCAvenueTxnsCount = async (data: any) => {
  try {
    let amount = await countsCCAvenueTxns(data);
    const payoadAmount =
      Number(amount?.notSettledCount) >= 0
        ? Number(amount?.notSettledCount).toFixed(0)
        : "N/A";
    return payoadAmount;
  } catch (err: any) {}
};

export const selectOperatingBranch = createAsyncThunk(
  "user/selectOperatingBranch",
  async (
    id: { value: string; isSocketAction?: boolean; payload2?: any },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const { user } = state;
    const payload = {
      user: user.uid,
      sub: "B",
      entity: id.value,
      company: user.company._id,
    };
    try {
      // Emit socket event
      socket.emit("branchLogin", id?.payload2 ? id?.payload2 : payload);
      // if (!state.user?.branches?.length) return state;
      console.log(state.user?.branches, "state.user?.branches");
      const opBranch = state.user?.branches.find(
        (br: any) => br._id === id?.value
      );

      if (!id.isSocketAction) {
        showMessage(
          "Operating Branch Set : " + opBranch?.branchName,
          success,
          2000,
          { position: "bottom-center" }
        );
      }
      // if (global.utils && global.utils.setupData) {
      //   setTimeout(global.utils.setupData, 500);
      // }

      if (opBranch?._id) {
        const { _id, branchName } = opBranch;
        socket.emit("branchLogin", { opBranch: { _id, branchName } });
      }
      const opBranchData = {
        ...opBranch,
        biltyPrintConfig: {
          margin: {
            top: opBranch?.biltyPrintConfig?.margin?.top || -0.2,
            bottom: opBranch?.biltyPrintConfig?.margin?.bottom || 12,
            left: opBranch?.biltyPrintConfig?.margin?.left || 0,
            right: opBranch?.biltyPrintConfig?.margin?.right || 0,
          },
        },
        loginType: "B",
      };

      console.log(opBranchData, "opBranchData");
      // Dispatch branch selection
      dispatch(setOperatingBranch(opBranchData));

      // Fetch operations count if login type is branch
      // if (state.user.loginType === "B") {
      const loading = (state?.rights as any)?.operations?.loading as boolean;
      const unloading = (state?.rights as any)?.operations
        ?.unloading as boolean;
      const delivery = (state?.rights as any)?.operations?.delivery as boolean;
      const verifyPackages = (state?.rights as any)?.operations
        ?.verifyPackages as boolean;

      let operationPayload: any = {};
      operationPayload = {
        branch: state.user.opBranch._id,
      };
      if (loading) {
        operationPayload.loading = true;
      }
      if (unloading) {
        operationPayload.unloading = true;
      }
      if (delivery) {
        operationPayload.delivery = true;
        operationPayload.outforDelivery = true;
        operationPayload.pendingPOD = true;
      }
      if (verifyPackages) {
        operationPayload.verifyStock = true;
      }
      const response = await getOperationsCount(operationPayload);
      // dispatch({
      //   type: UPDATE_OPERATIONS_ALL_COUNT,
      //   payload: response,
      // });
      return response;
      // }

      // return id;
    } catch (err) {
      console.error("Branch selection error:", err);
      throw err;
    }
  }
);

export const selectOperatingFleet = createAsyncThunk(
  "user/selectOperatingFleet",
  async (id: { value: string }, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      let opFleet: any;
      const fleets = state.fleet?.fleets;
      fleets?.forEach((fl: any) => {
        if (fl?._id == id?.value) {
          opFleet = fl;
        }
      });
      // if (global.utils && global.utils.setupData) {
      //   setTimeout(global.utils.setupData, 500);
      // }
      showMessage("Operating Fleet Set : " + opFleet.regNumber, success, 2000, {
        position: "bottom-center",
      });

      console.log("fleetLogin", opFleet.allowedOFDCities);
      if (opFleet.allowedOFDCities && opFleet.allowedOFDCities.length > 0) {
        // socket.emit("fleetLogin", {allowedOFDCities: opFleet.allowedOFDCities});
      }
      const payload = {
        opFleet,
        loginType: "F",
      };
      dispatch(setOperatingFleet(payload));
      dispatch(resetOperatingCount());
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const addAttendanceServiceAction = createAsyncThunk(
  "user/addAttendanceService",
  async (data: any, {}) => {
    try {
      const attendanceData = await addAttendanceService(jsonToFormdata(data));
      if (attendanceData != null) {
        showMessage("Attendence Added Successfull!");
        // changeAttendanceCount(attendanceData.thisMonthAttendanceCount);
        return attendanceData.thisMonthAttendanceCount;
      }
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const getMyOdaPackages = createAsyncThunk(
  "user/outForDeliveryFleet",
  async ({ payload }: { payload: any; noloader?: any }, { dispatch }) => {
    try {
      dispatch({ type: SHOW_LOADER });
      // await dispatch(fetchingUnload());
      const response = await getOutForDeliveryData(payload);
      if (!response || response.length === 0) return null;
      return makeDocketMapRegNumWise(response);
    } catch (err) {
      showHttpError(err);
    } finally {
      dispatch({ type: HIDE_LOADER });
    }
  }
);

export const getVerifyList = createAsyncThunk(
  "user/fetchUnverifiedDocket",
  async (data: any, { dispatch }) => {
    try {
      dispatch(showLoader());
      let response = await fetchUnverifiedDocket(data);
      return response;
      // dispatch({
      //   type: "GET_VERIFY_LIST",
      //   payload: response.list,
      // });
    } catch (error: any) {
      showMessage(error.message || "Couldn't list Package", failed);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const setRoutesAction = createAsyncThunk(
  "user/setRoutesAction",
  async (_, { getState, dispatch }) => {
    try {
      dispatch(showLoader());
      const state = getState() as RootState;
      const companyId = state.user.company._id;
      let routes = await listByCompany(companyId);
      return routes;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const individualOpBranch = createAsyncThunk(
  "user/individualOpBranch",
  async (id: { value: string }, { dispatch, getState }) => {
    const state = getState() as RootState;
    dispatch(showLoader());
    try {
      const { user } = state;
      const payload = {
        user: user.uid,
        sub: "B",
        entity: id.value,
        company: user.company._id,
      };
      try {
        socket.emit("branchLogin", payload);
      } catch (err: any) {}
      dispatch({ type: SET_SENDER_ADDRESS_FROM_OPBRANCH });
      let opBranch: any = null;
      opBranch = id;

      showMessage(
        "Operating Branch Set : " + opBranch.branchName,
        success,
        2000,
        { position: "bottom-center" }
      );
      // if (global.utils && global.utils.setupData) {
      //   setTimeout(global.utils.setupData, 500);
      // }
      dispatch(updateOperationsAllCount());
      return {
        opBranch: opBranch,
        loginType: "B",
      };
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const getRoutesAction = createAsyncThunk(
  "user/getRoutesAction",
  async (_, { dispatch }) => {
    dispatch(showLoader());
    try {
      let response = await fetchRouteForExpense();
      return response;
    } catch (error) {
      showHttpError(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const getCity = createAsyncThunk(
  "user/getCity",
  async (pin: number, { dispatch }) => {
    // let response = await axios.get(url + '/area/city/' + pin)
    try {
      const city = await getCityByPincode(pin);
      // if (response.data.status != 1) throw "Something went wrong"
      const payload = {
        index: ["city"],
        value: city,
      };
      dispatch(changeSimple(payload));
    } catch (err: any) {
      showMessage(err.message || "Invalid Pincode", failed);
    }
  }
);

export const outForDeliveryRemarkApi = createAsyncThunk(
  "user/outForDeliveryRemark",
  async (data1: any, { dispatch, getState }) => {
    const url = baseURL;
    try {
      let { user, ...rest } = data1?.data;
      let { setRemarks, cb } = data1;
      const state = getState() as RootState;
      // throw 'Yuhi'
      await axios.post(url + "/package/remark", jsonToFormdata(rest));
      const company = state.user.company._id,
        branch = state.user.opBranch._id;

      let packagePayload = {
        token: state.user.token,
        uid: state.user.uid,
        sub: state.user.loginType,
        entity:
          state.user.loginType == "B"
            ? state.user.opBranch._id
            : state.user.opFleet._id,
        company,
        branch,
      };
      const data = {
        payload: packagePayload,
        noloader: true,
      };
      await dispatch(getMyOdaPackages(data)).unwrap();
      await setRemarks();
      cb();
    } catch (error) {}
  }
);

export const outForDeliveryRemark = (data: any, cb: any, setRemarks: any) => {
  return (dispatch: any) => {
    const payload = {
      data,
      cb,
      setRemarks,
    };
    dispatch(outForDeliveryRemarkApi(payload));
  };
};

export const returnODAPackages = createAsyncThunk(
  "user/returnODAPackages",
  async (data: any, { dispatch, getState }) => {
    try {
      const state = getState() as RootState
      dispatch(showLoader());
      await returnODAPackagesService(data);

      let packagePayload = {
        sub: state.user.loginType,
        entity:
          state.user.loginType == "B"
            ? state.user.opBranch._id
            : state.user.opFleet._id,
      };
      const payload = {
        payload: packagePayload,
        noloader: true,
      };
      await dispatch(getMyOdaPackages(payload)).unwrap();
      dispatch(hideLoader());
    } catch (error) {
      showMessage("Something went wrong!", "salmon");
      dispatch(hideLoader());
    }
  }
);
