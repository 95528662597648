import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLocked: false
}

const driverloginSlice = createSlice({
    name: "driverLogin",
    initialState,
    reducers: {
        driverLoginLocked: (state, action) => {
            state.isLocked = action.payload;
        },
    },
});

export const { driverLoginLocked } = driverloginSlice.actions;

export default driverloginSlice.reducer;