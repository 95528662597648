import { createSlice } from "@reduxjs/toolkit";
import { appActionsType } from "./appActions.type";

const initialState: appActionsType = {
    isReloadAppDialogOpen: false
}

const appActionsSlice = createSlice({
    name: "complaintChatList",
    initialState,
    reducers: {
        setReloadAppDialog : (state,action) => {
            state.isReloadAppDialogOpen = action.payload;
        }
    },
});

export const { setReloadAppDialog } = appActionsSlice.actions;

export default appActionsSlice.reducer;