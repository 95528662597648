import { createSlice } from "@reduxjs/toolkit";
import { liveLocationType } from "./liveLocation.type";
import { setDriverLocationData } from "./api";

const initialState: liveLocationType = {
  driverLocation: {
    isStarted: false,
  },
  customerDocketLocation: {
    isStarted: false,
  },
};

const liveLocationSlice = createSlice({
  name: "missing",
  initialState,
  reducers: {
    setCustomerDocketLocation: (state, action) => {
      state.customerDocketLocation = {
        ...state.customerDocketLocation,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setDriverLocationData.fulfilled, (state, action) => {
      state.driverLocation = {
        ...state.driverLocation,
        ...action.payload,
      };
    });
  },
});

export const { setCustomerDocketLocation } = liveLocationSlice.actions;

export default liveLocationSlice.reducer;
