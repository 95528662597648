import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHttpError } from "../../../utils/message";
import { listUsersService } from "../../../services/user";
import { hideLoader, showLoader } from "../../../actions/UserActions";

export const userList = createAsyncThunk(
  "list/userList",
  async (_, { dispatch }) => {
    dispatch(showLoader());
    try {
      const response = await listUsersService();
      return response;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch(hideLoader());
    }
  }
);
