import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { BranchInitialValuesType } from "./branch.type";
import { fromJS } from "immutable";
import { branchSchemaFieldWise, days, procWorking } from "./helper";
import { branchCreateReq, deleteBranch, fetchBranch } from "./api";
import { isArrayCheck } from "../../../containers/generics/CheckArray";
import showMessage, { success } from "../../../utils/message";

const initialState: BranchInitialValuesType = {
  allbranches: [],
  allPlatformBranches: [],
  branchMap: new Map(),
  filteredBranches: {
    query: {
      company: "",
    },
    data: {
      breanches: [],
    },
  },
  branchForm: fromJS({
    branchName: "",
    shortName: "",
    active: true,
    working: {
      rohh: "",
      rchh: "",
      mohh: "",
      mchh: "",
      tohh: "",
      tchh: "",
      wohh: "",
      wchh: "",
      thohh: "",
      thchh: "",
      fohh: "",
      fchh: "",
      saohh: "",
      sachh: "",
      suohh: "",
      suchh: "",
    },
    pContact: "",
    sContact: "",
    email: "",
    paid: false,
    topay: false,
    credit: false,
    foc: false,
    noncash: false,
    dCredit: false,
    dNoncash: false,
    isBooking: false,
    isDelivery: false,
    ownership: "o",
    isHub: false,
    isWarehouse: false,
    isIntermediateBranch: false,
    childBranches: [],
    childFleets: [],
    maxBalance: "",
    tableCash: "",
    branchImage: "",
    businessType: "",
    overhead: "",

    // [changes
    deliveryCharge: {
      interState: false,
      intraState: false,
    },
    // changes]
    cashBranch: false,
    customerVerification: false,
    allDocketVerification: false,
    withoutEWayBillDocketVerification: false,
    photoVerification: false,
    otpVerification: false,
    receiverAllDocketVerification: false,
    receiverPhotoVerification: false,
    receiverOtpVerification: false,
    pendingDelivery: false,
    doorDeliverySms: false,
    qrPrinter: false,
    deliveryWithQr: false,
    potentialCustomerAmount: "",
    maxPendingDeliveryAmount: "",
    maxPendingDeliveryPackages: "",
    cams: [],
    l1: "",
    l2: "",
    pin: "",
    city: "",
    placeId: "",
    shortAddress: "",
    rented: {
      rentAgreement: null,
      rentAmount: null,
      rentDate: null,
    },
    errors: {
      branchName: null,
      shortName: null,
      active: null,
      working: {
        rohh: "",
        rchh: "",
        mohh: "",
        mchh: "",
        tohh: "",
        tchh: "",
        wohh: "",
        wchh: "",
        thohh: "",
        thchh: "",
        fohh: "",
        fchh: "",
        saohh: "",
        sachh: "",
        suohh: "",
        suchh: "",
      },
      pContact: null,
      sContact: null,
      email: null,
      paid: null,
      topay: null,
      credit: null,
      foc: null,
      noncash: null,
      dCredit: null,
      dNoncash: null,
      isBooking: null,
      isDelivery: null,
      ownership: null,
      isHub: null,
      isWarehouse: null,
      isIntermediateBranch: null,
      cashBranch: null,
      childBranches: [],
      childFleets: [],
      maxBalance: "",
      tableCash: "",
      cams: [],
      l1: null,
      l2: null,
      pin: null,
      city: null,
      shortAddress: null,
      placeId: null,
      rented: {
        rentAgreement: null,
        rentAmount: null,
        rentDate: null,
      },
      maxPendingDeliveryAmount: null,
      potentialCustomerAmount: null,
      maxPendingDeliveryPackages: null,
      bpc_margin_top: 0,
      bpc_margin_bottom: 12,
      bpc_margin_left: 0,
      bpc_margin_right: 0,
      deliveryCharge: {
        interState: "",
        intraState: "",
      },
    },
  })
};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    setAllPlatformBranchesData: (state, action) => {
      state.allPlatformBranches = action.payload;
    },
    setBranchesData: (state, action) => {
      const branches = action.payload;
      const branchMap = new Map();
      for (let i = 0; i < branches.length; i++) {
        if (branchMap.has(branches[i]._id)) {
          continue;
        } else {
          branchMap.set(branches[i]._id, branches[i]);
        }
      }
      state.allbranches = branches;
      state.branchMap = branchMap;
      // return {
      //   ...state,
      //   allbranches: branches,
      //   branchMap: branchMap,
      // };
    },
    setFilteredBranch: (state, action) => {
      const Branches = action.payload.data;
      // const Branches = filteredBranches.reduce((prev: any, now: any) => {
      //   return {...prev, [now.company._id]: now}
      // }, {})
      state.filteredBranches = {
        query: {
          ...state.filteredBranches?.query,
          company: action.payload.query,
        },
        data: {
          breanches: [
            ...(state.filteredBranches?.data?.breanches as any[]),
            ...Branches,
          ],
        },
      };
    },
    changeSimple: (state, action) => {
      console.log(action.payload, "action.payload1111111111111");
      let branchForm = state.branchForm;
      const { index, value } = action.payload;
      branchForm = branchForm.setIn(index, value);
      try {
        branchSchemaFieldWise.getIn(index).validateSync(value);
        branchForm = branchForm.setIn(["errors", ...index], null);
      } catch (err: any) {
        if (err.name == "ValidationError") {
          branchForm = branchForm.setIn(["errors", ...index], err.message);
        }
      }
      if (
        index.length == 2 &&
        ["rohh", "romm", "rchh", "rcmm"].includes(index[1])
      ) {
        days.forEach((day) => {
          if (day.includes(index[1].substring(1, 4))) {
            branchForm = branchForm.setIn(["working", day], value);
          }
        });
        state.branchForm = branchForm;
      }
      state.branchForm = branchForm;
    },
    setSimpleError: (state, action) => {
      try {
        let { branchForm } = state;
        const { payload } = action;
        branchSchemaFieldWise
          .getIn(payload)
          .validateSync(branchForm.getIn(payload));
        branchForm = branchForm.setIn(["errors", ...payload], null);
        state.branchForm = branchForm;
      } catch (err: any) {
        const { payload } = action;
        if (err.name == "ValidationError") {
          let { branchForm } = state;
          branchForm = branchForm.setIn(["errors", ...payload], err.message);
          state.branchForm = branchForm;
        }
      }
      return state;
    },
    clearBranch: (state) => {
      state.branchForm = initialState.branchForm;
    },
    brCamRemove: (state, action) => {
      let i = action.payload;
      let branchForm = state.branchForm;
      let cams = branchForm.get("cams").toJS().slice();
      cams.splice(i, 1);
      branchForm = branchForm.set("cams", fromJS(cams));
      state.branchForm = branchForm;
    },
    brCam: (state, action) => {
      let branchForm = state.branchForm;
      let cams = branchForm.get("cams").toJS().slice();
      cams.push(action.payload);
      branchForm = branchForm.set("cams", fromJS(cams));
      state.branchForm = branchForm;
    },
    changeBranchHub: (state, action) => {
      let branchForm = state.branchForm;
      state.branchForm = branchForm.set("childBranches", action.payload);
    },
    changeChildFleets: (state, action) => {
      let branchForm = state.branchForm;
      state.branchForm = branchForm.set("childFleets", action.payload);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchBranch.fulfilled, (state, action) => {
        let branchForm = state.branchForm;
        let data = action.payload,
          sameFields = [
            "branchName",
            "shortName",
            "pContact",
            "sContact",
            "email",
            "isBooking",
            "isDelivery",
            "_id",
            "childBranches",
            "childFleets",
            "maxBalance",
            "tableCash",
            "rentAgreement",
            "branchImage",
            "businessType",
            "overhead",
            "qrPrinter",
            "customerVerification",
            "allDocketVerification",
            "withoutEWayBillDocketVerification",
            "photoVerification",
            "otpVerification",
            "receiverAllDocketVerification",
            "receiverPhotoVerification",
            "receiverOtpVerification",
            "pendingDelivery",
            "doorDeliverySms",
            "deliveryWithQr",
            // "expenseDepartment",

            "deliveryCharge",
            "active",
            "maxPendingDeliveryPackages",
            "maxPendingDeliveryAmount",
            "potentialCustomerAmount",
            "shortAddress",
          ];
        sameFields.forEach((field) => {
          branchForm = branchForm.setIn([field], data[field]);
        });
        branchForm = branchForm.setIn(["cams"], fromJS(data["cams"]));

        let { paid, toPay: topay, credit, foc, noncash } = data.booking;
        let { credit: dCredit, noncash: dNoncash } = data.delivery;
        let { booking: isBooking, delivery: isDelivery } = data.operation;
        let { l1, l2, pincode: pin, city, placeId } = data.address;

        let biltyPrintConfig = {
          margin: {
            top: 0,
            bottom: 12,
            left: 0,
            right: 0,
          },
        };

        if (data.biltyPrintConfig && data.biltyPrintConfig.margin) {
          biltyPrintConfig = {
            margin: {
              top: data.biltyPrintConfig.margin?.top || -0.2,
              bottom: data.biltyPrintConfig.margin?.bottom || 12,
              left: data.biltyPrintConfig.margin?.left || 0,
              right: data.biltyPrintConfig.margin?.right || 0,
            },
          };
        }
        let {
          hub: isHub,
          isWarehouse: isWarehouse,
          isIntermediateBranch: isIntermediateBranch,
          cashBranch: cashBranch,
          bLocation: bLocation,
          manager: manager,
        } = data;
        let own;
        switch (data.ownership) {
          case 0: {
            own = "o";
            break;
          }
          case 1: {
            own = "r";
            branchForm = branchForm.set("rented", fromJS(data.rented));
            break;
          }
        }

        branchForm = branchForm
          .set("topay", topay)
          .set("paid", paid)
          .set("credit", credit)
          .set("dCredit", dCredit)
          .set("dNoncash", dNoncash)
          .set("foc", foc)
          .set("noncash", noncash)
          .set("isBooking", isBooking)
          .set("isDelivery", isDelivery)
          .set("l1", l1)
          .set("l2", l2)
          .set("pin", pin)
          .set("city", city)
          .set("placeId", placeId?.placeId)
          .set("placeValue", placeId?.placeValue)
          .set("ownership", own)
          .set("isHub", isHub)
          .set("isWarehouse", isWarehouse)
          .set("isIntermediateBranch", isIntermediateBranch)
          .set("cashBranch", cashBranch)
          .set("bLocation", bLocation)
          .set("manager", manager)
          .set("bpc_margin_top", biltyPrintConfig?.margin?.top)
          .set("bpc_margin_bottom", biltyPrintConfig?.margin?.bottom)
          .set("bpc_margin_left", biltyPrintConfig?.margin?.left)
          .set("bpc_margin_right", biltyPrintConfig?.margin?.right);

        if (isArrayCheck(data.working.custom) && data.working.regular) {
          const result = procWorking(data.working);
          Object.keys(result).map((key) => {
            branchForm = branchForm.setIn(["working", key], result[key]);
          });
        } else {
          const result = procWorking(data.working);
          Object.keys(result).map((key) => {
            branchForm = branchForm.setIn(["working", key], result[key]);
          });
        }
        state.branchForm = branchForm;
      })
      .addCase(branchCreateReq.fulfilled, (state, action) => {
        const branch = action.payload?.response;
        const message = action.payload?.response?.message;

        let branches = action.payload?.branchesArr.map((tempBranch:any) => {
          if (tempBranch._id == branch._id) {
            return branch;
          } else {
            return tempBranch;
          }
        });
        if (action.payload?.status !== 0) {
          state.branchForm = initialState.branchForm;
          state.branches = branches;
          state.opBranch =
            state.opBranch && state.opBranch._id == branch._id
              ? branch
              : state.opBranch;
          showMessage(message || "Branch Updated Successfully", success);
        }
      })
      .addCase(deleteBranch.fulfilled, (state) => {
        state.branchForm = initialState.branchForm;
      }),
});

export const {
  setBranchesData,
  setFilteredBranch,
  changeSimple,
  setSimpleError,
  clearBranch,
  brCamRemove,
  brCam,
  changeBranchHub,
  changeChildFleets,
  setAllPlatformBranchesData,
} = branchSlice.actions;

export default branchSlice.reducer;

export const selectRight = (state: RootState) => state.branch;
// export const selectIsLoading = (state: RootState) => state.user.isLoading;
// export const selectError = (state: RootState) => state.user.error;
