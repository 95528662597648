import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { changeIncomeTypeApi, incomeSubmit, setIncomeRecord } from "./api";
import { assetType, IncomeRecord, IncomeType } from "./income.type";
import {
  incur_Income_Boolean,
  incur_Income_Validation,
  validateIncome,
} from "./helper";
import { isArrayCheck } from "../../../containers/generics/CheckArray";
import { SelectValueType } from "../../../newSrc/pages/CustomerModule/Components/CustomerBooking/types/booking.type";
import { get, set } from "lodash";

const initialState: IncomeType = {
  fetchedInputTypes: [],
  fetchedSubInputTypes: [],
  assetTypes: [],
  selectRefTypes: [],
  selectSubInputTypes: [],
  selectAssetTypes: [],
  postIncomeRecord: {
    gst: "",
    commission: "",
    type: "add",
    ref: "",
    assetType: "",
    amt: "",
    sub: "",
    sub_val: "",
    route: "",
    start_date: new Date(),
    end_date: new Date(),
    user: "",
    is_route: false,
    branch: "",
    category: "",
    non_cash: false,
    remarks: "",
    monthly_duration: false,
    paymentType: "",
    transactionImage: "",
    transactionId: "",
    creditor: "",
    Agent: "",
    Branch: "",
    // incomeRecordValid: false
  },
  assetBalances: [],
  postIncomeRecordErrors: { ...incur_Income_Boolean(true) },
  postIncomeRecordTouched: { ...incur_Income_Boolean(false) },
};

const incomeSlice = createSlice({
  name: "income",
  initialState,
  reducers: {
    changeAddIncomeType: (state, action) => {
      let data = state.fetchedInputTypes;
      data.push(action.payload);
      state.fetchedInputTypes = data;
    },
    setAssetTypes: (state, action) => {
      let assetTypes = action.payload;
      const mapAssetTypes = isArrayCheck(assetTypes)
        ? assetTypes.map((a: { [k: string]: any }) => ({
            label: a.assetName,
            value: a._id,
          }))
        : [];
      state.assetTypes = assetTypes;
      state.selectAssetTypes = mapAssetTypes;
    },
    getAssetBal: (state, action) => {
      const selectAssetBal = isArrayCheck(action.payload)
        ? action.payload.map((t: { [k: string]: any }) => ({
            label: t.uniqueId ? t.uniqueId : t.qty + " " + t.type.assetName,
            value: t && t._id ? t._id : "",
          }))
        : [];
      state.fetchedSubInputTypes = action.payload;
      state.selectSubInputTypes = selectAssetBal;
    },
    getSubTypes: (state, action) => {
      const selectBranches = isArrayCheck(action.payload)
        ? action.payload.map((e: { [k: string]: any }) => ({
            label: e.branchName ? e.branchName : "N/A",
            value: e._id ? e._id : "",
          }))
        : [];
      state.fetchedSubInputTypes = action.payload;
      state.selectSubInputTypes = selectBranches;
    },
    setIncomeRecordData: (state, action) => {
      let data = action.payload;
      data["type"] = "edit";
      state.postIncomeRecord = data;
    },
    clearAddIncome: (state) => {
      state.fetchedSubInputTypes = [];
      state.selectSubInputTypes = [];
      state.postIncomeRecord.sub_val = "";
      state.postIncomeRecord.assetType = "";
    },
    getIncomeTypesData: (state, action) => {
      const mapfetchedInputTypes = isArrayCheck(action.payload)
        ? action.payload.map((t: { [k: string]: any }) => ({
            label: t.name,
            value: t.id,
          }))
        : [];
      state.fetchedInputTypes = action.payload;
      state.selectRefTypes = mapfetchedInputTypes;
    },
    getIncomeTypesFailed: (state) => {
      return state;
    },
    changeAddIncome: (state, action) => {
      let { what, val } = action.payload;
      let finalValue = val;
      if (what == "non_cash") {
        finalValue = !(state.postIncomeRecord.non_cash === true);
      }
      set(state, ["postIncomeRecord", ...what], finalValue);
      set(
        state,
        ["postIncomeRecordErrors", ...what],
        validateIncome(get(incur_Income_Validation, what), finalValue)
      );
      set(state, ["postIncomeRecordTouched", ...what], true);
    },
    reactSelectError: (state, action) => {
      let { what } = action.payload;
      set(state, ["postIncomeRecordErrors", ...what], "This is required field");
    },
    resetIncurIncome: (state) => {
      let data: IncomeRecord = {
        gst: "",
        commission: "",
        type: "add",
        ref: "",
        assetType: "",
        no_of_seats: "",
        amt: "",
        sub: "",
        sub_val: "",
        route: "",
        is_route: false,
        start_date: new Date(),
        end_date: new Date(),
        user: "",
        branch: "",
        category: "",
        non_cash: false,
        remarks: "",
        monthly_duration: false,
        incomeRecordValid: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        creditor: "",
        Agent: "",
        Branch: "",
      };
      state.postIncomeRecord = data;
      state.postIncomeRecordErrors = incur_Income_Boolean(true);
      state.fetchedSubInputTypes = [];
      state.assetTypes = [];
      state.selectSubInputTypes = [];
    },
    updatePastIncomes: {
      reducer: (state, action: PayloadAction<{ data: any; option?: any }>) => {
        let { data } = action.payload;
        const options = action.payload?.option
        if (options && options.remove === true){
          set(state,["pastIncomes"],get(state,["pastIncomes"])?.filter((i: any) => i.id != options.id))
          set(state,["postIncomeRecord"],get(initialState,["postIncomeRecord"]))
        }
        if (options && options.update === true) {
          set(state,["pastIncomes"],get(state,["pastIncomes"])?.map((i: any) => {
            if (i.id == options.id) {
              return { ...i, amt: data?.amt };
            }
            return i;
          }))
          set(state,["postIncomeRecord"],get(initialState,["postIncomeRecord"]))
        }
        set(state,["pastIncomes"],[action.payload].concat(get(state,["pastIncomes"])))
      },
      prepare: (data: any, options?: any) => ({
        payload: {
          data,
          options,
        },
      }),
    },
    postIncomeRecordSuccess : (state) => {
      
      let data : IncomeRecord = {
        gst: "",
        commission: "",
        type: "add",
        ref: "",
        assetType: "",
        amt: "",
        sub: "",
        sub_val: "",
        is_route: false,
        route: "",
        start_date: new Date(),
        end_date: new Date(),
        user: "",
        branch: "",
        category: "",
        non_cash: false,
        remarks: "",
        monthly_duration: false,
        incomeRecordValid: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        creditor: "",
        Agent: "",
        Branch: "",
      };
      state.postIncomeRecord = data;
    },
    postIncomeRecordFailed : (state) => {
      return state;
    },
    editIncomeRecordFailed : (state) => {
      return state;
    },
    setPastIncome : (state,action) => {
      set(state,["pastIncomes"],action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeIncomeTypeApi.fulfilled, (state, action) => {
        let assetTypes: assetType[] = action.payload;
        const mapAssetTypes: SelectValueType[] = isArrayCheck(assetTypes)
          ? assetTypes.map((a) => ({
              label: a.assetName,
              value: a._id,
            }))
          : [];
        //here sub input is asset balance
        state.assetTypes = assetTypes;
        state.selectAssetTypes = mapAssetTypes;
      })
      .addCase(incomeSubmit.fulfilled, (state, action) => {
        let data = state.fetchedInputTypes;
        data.push(action.payload);
        state.fetchedInputTypes = data;
      })
      .addCase(setIncomeRecord.fulfilled, (state) => {
        return state;
      });
  },
});

export const {
  changeAddIncomeType,
  setAssetTypes,
  getAssetBal,
  getSubTypes,
  setIncomeRecordData,
  clearAddIncome,
  getIncomeTypesData,
  getIncomeTypesFailed,
  changeAddIncome,
  reactSelectError,
  resetIncurIncome,
  updatePastIncomes,
  postIncomeRecordSuccess,
  postIncomeRecordFailed,
  editIncomeRecordFailed,
  setPastIncome
} = incomeSlice.actions;

export default incomeSlice.reducer;
