import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHttpError } from "../../../utils/message";
import { getCallRecordForDoorDelivery, getVoiceCallRecordsCount } from "../../../services/report";
import { SmsCallRecordPayload } from "./smsAndCallRecords.type";

export const setSmsAcceptedRecords = createAsyncThunk(
    "smsAcceptedRecords",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getCallRecordForDoorDelivery(payload);
            return response;
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setSmsRejectedRecords = createAsyncThunk(
    "smsRejectedRecords",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getCallRecordForDoorDelivery(payload);
            return response;
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setNormalCallAcceptedRecords = createAsyncThunk(
    "normalCallAcceptedRecords",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getCallRecordForDoorDelivery(payload);
            return {data : response};
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setNormalCallPendingRecords = createAsyncThunk(
    "normalCallPendingRecords",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getCallRecordForDoorDelivery(payload);
            return {data : response};
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setNormalCallRejectedRecords = createAsyncThunk(
    "normalCallRejectedRecords",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getCallRecordForDoorDelivery(payload);
            return {data : response};
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setDoorDelCallAcceptedWithOptionPressedRecords = createAsyncThunk(
    "doorDelCallAcceptedWithOptionPressedRecords",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getCallRecordForDoorDelivery(payload);
            return {data : response};
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setDoorDelCallAcceptedWithoutOptionPressedRecords = createAsyncThunk(
    "doorDelCallAcceptedWithoutOptionPressedRecords",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getCallRecordForDoorDelivery(payload);
            return {data : response};
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setDoorDelCallPendingRecords = createAsyncThunk(
    "doorDelCallPendingRecords",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getCallRecordForDoorDelivery(payload);
            return {data : response};
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setDoorDelCallRejectedRecords = createAsyncThunk(
    "doorDelCallRejectedRecords",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getCallRecordForDoorDelivery(payload);
            return {data : response};
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setTotalCallSmsCount = createAsyncThunk(
    "totalCallSmsCount",
    async (
        payload: SmsCallRecordPayload,
    ) => {
        try {
            const response = await getVoiceCallRecordsCount(payload);
            return {
                deliveryAcceptCount: response?.[0]?.deliveryAcceptCount,
                deliveryCount: response?.[0]?.deliveryCount,
                deliveryPendingCount: response?.[0]?.deliveryPendingCount,
                deliveryRejectCount: response?.[0]?.deliveryRejectCount,
                doorDeliveryAcceptCount: response?.[0]?.doorDeliveryAcceptCount,
                doorDeliveryAcceptNOpCount: response?.[0]?.doorDeliveryAcceptNOpCount,
                doorDeliveryAcceptOpCount: response?.[0]?.doorDeliveryAcceptOpCount,
                doorDeliveryCount: response?.[0]?.doorDeliveryCount,
                doorDeliveryPendingtCount: response?.[0]?.doorDeliveryPendingtCount,
                doorDeliveryRejectCount: response?.[0]?.doorDeliveryRejectCount,
                smsCount: response?.[0]?.smsCount,
                smsRejectCount: response?.[0]?.smsRejectCount,
                smsSendCount: response?.[0]?.smsSendCount,
                voiceCount: response?.[0]?.voiceCount
              };
        } catch (err) {
            showHttpError(err);
        }
    }
);
