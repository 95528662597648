import { buildQueryString, ServiceMaker } from "./index";

export const readyForDelivery = (data: {
  companyId?: string;
  startDate: Date;
  endDate: Date;
}) => ServiceMaker("booking/ready/for/delivery", "POST", data);

export const bulkBookingApicall = (data : any) => ServiceMaker("doorDelivery/autoBooked", "POST", data);

export const listBookedDockets = (data: {
  companyId?: string;
  startDate: Date;
  endDate: Date;
}) => ServiceMaker("booking/list/booked/docket", "POST", data);

export const listInTransitDockets = (data: {
  companyId?: string;
  startDate: Date;
  endDate: Date;
}) => ServiceMaker("booking/list/intransit/docket", "POST", data);

export const listLoadingDockets = (data: {
  companyId?: string;
  startDate: Date;
  endDate: Date;
}) => ServiceMaker("booking/list/loadingdockets", "POST", data);

export const fetchFedexCharges = (data: {
  sourcePincode: number;
  destinationPincode: number;
  useDimensions?: boolean;
  packages: [
    {
      qty: any;
      weight: any;
      length: any;
      width: any;
      height: any;
      unit: string;
    }
  ];
}) => ServiceMaker("fedex/availableRate", "POST", data);

export const fetchBranchwiseBookingCounters = (data: any) =>
  ServiceMaker(`booking/branchwisecounts?${buildQueryString(data)}`, "GET");

export const getSenderList = (data: any) =>
  ServiceMaker(`customer/search/for/booking`, "POST", data);

export const getRateBookingData = (id: number, data: any) =>
  ServiceMaker(`rate/bookingRate/${id}?${buildQueryString(data)}`, "GET");

export const getBranchwiseBokingReport = (data: any) =>
  ServiceMaker(`report/branchBookingReport?${buildQueryString(data)}`, "GET");

export const getBranchToBranchReport = (data: any) =>
  ServiceMaker(
    `report/branchToBranchBookingReport?${buildQueryString(data)}`,
    "GET"
  );

export const getLastBookedDocket = (data: any) =>
  ServiceMaker("booking/lastbooking", "POST", data);

export const updateCustomerName = (data: any) =>
  ServiceMaker("customer/update", "PATCH", data);

export const customerUpdateReq = (data: any) =>
  ServiceMaker("customer/customerUpdateReq", "POST", data);

export const getPrimaryCustomerList = (data: any) =>
  ServiceMaker(`customer/getPrimaryCustomer?${buildQueryString(data)}`, "GET");

export const listDoorDelivery = (data: any) =>
  ServiceMaker(`booking/listDoorDeliveryReq?${buildQueryString(data)}`, "GET");

export const getDaywiseReport = (data: any) =>
  ServiceMaker(
    `report/branchToBranchDaywiseBookingReport?${buildQueryString(data)}`,
    "GET"
  );

export const getlistBookingRequest = () =>
  ServiceMaker("booking/listBookingRequest", "GET");

export const getBranchDaywiseReport = (data: any) =>
  ServiceMaker(
    `report/branchWiseDayWiseBookingReport?${buildQueryString(data)}`,
    "GET"
  );

export const removeNames = (data: any) =>
  ServiceMaker(`customer/removeNames`, "PATCH", data);
  
  export const AddRemarkToDoorDeliveryOrder = (data: any) =>
    ServiceMaker(`booking/addDeliveryRemark`, "PATCH", data);

    export const listOfWaitingDeliveryDocket = (data: any) =>
    ServiceMaker(`booking/listOfWaitingDeliveryDocket`, "POST", data)

export const getBranchWiseCustomerWiseData = (data: any) =>
ServiceMaker(`report/BranchWiseCustWiseDocketData`, "POST", data)

export const customerValidationSendOTP = (data: any) =>
  ServiceMaker(`customer/customerValidation/sendOtp`, "POST", data)

export const customerValidationVerifyOTP = (data: any) =>
  ServiceMaker(`customer/customerValidation/verifyOTP`, "PATCH", data)

export const getDocketBasicDetails = (data: any) =>
  ServiceMaker(
    `booking/basic/details?${buildQueryString(data)}`,
    "GET"
  );