import { createSlice } from "@reduxjs/toolkit";
import { setSettingsGeneralAll } from "./api";
import { AllGeneralSettingType } from "./allGeneralSettings.type";

const initialState: AllGeneralSettingType = {
  sizes: [],
  blockedUsers: [],
  builtyChargeSlab: [],
  insuredCompanies: [],
  handlingChargeSlab: [],
  deliveryChargesSlab: [],
  minValueCharge: 0,
  perPackageValueCharge: 0,
  version: '',
  bannerImage: '',
  smsOperator: '',
  settledCompany: '',
  memoApiOn: false,
  gstUpdate: false,
  ewayBillUpdate: false,
  valueChargeEnabled: false,
  lastBookingSocketOn: false,
  phoneVerificationSetting: false,
}

const allGeneralSettingsSlice = createSlice({
  name: "allGeneralSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setSettingsGeneralAll.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
  },
});

export default allGeneralSettingsSlice.reducer;