import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  creditorSettlememtCount,
  getFleetOrderRightsData,
  OperationsRequestCount,
  sidebareBadgesCount,
  updateAllIssueCounts,
  updateCameraStatusDetails,
  updateComplaintsChatBadges,
  updateFeedbackCount,
  updateOperationsAllCount,
  updateOperationsDeliveryCount,
  updateOperationsLoadingCount,
  updateOperationsPendingPODCount,
  updateOperationsUnloadingCount,
  updateOperationsVerifystocksCount,
  updateVerifyCancelBookingBadges,
  updateVerifyShortageBadges,
} from "./api";
import { selectOperatingBranch } from "../user/api";
import { SidebarBadgesInitialValuesType } from "./sidebarBadges.type";

const initialState: SidebarBadgesInitialValuesType = {
  verify: {
    expenses: {
      pendingVerify: 0,
      pendingVoucherUpload: 0,
    },
    cancelBooking: {
      pendingCancel: 0,
    },
    pod: {
      pendingPod: 0,
    },

    verifyStock: {
      pendingStock: 0,
    },
    transactions: {
      pettyCash: 0,
    },
    verifyShortage: {
      pendingShortage: 0,
    },
  },
  cameraStatusDetails: null,
  issuesPendingCount: 0,
  allIssueCounts: 0,
  complaintChatCount: {
    _id: null,
    totalCount: 0,
    readByCount: 0,
    totalMessagesCount: 0,
    unreadCount: 0,
    zeroMessages: 0,
    totalUnread: 0,
  },
  operations: {
    loading: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    unloading: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    delivery: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    outforDelivery: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    pendingPOD: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    verifyStock: {
      allCount: 0,
      inners: [0, 0, 0],
    },
  },
  RequestOperations: {
    BTH: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    ND: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    demurrage: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    remarks: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    discount: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    doorDeliveryDiscount: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    customerConnect: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    claim: { allCount: 0, inners: [0, 0, 0] },
    fleetOrderRecheckIn: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    parcelTransfer: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    cancelBooking: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    cancelDoorDeliveryBooking: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    branchDelivery: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    packageUpdate: {
      allCount: 0,
      inners: [0, 0, 0],
    },
  },
  graceCount: {
    company: 0,
    user: 0,
    creditCoustomer: 0,
    branch: 0,
    fleet: 0,
  },
  creditSettlementCount: {
    amount: 0,
  },
  feedbackCount: {
    branchWiseCount: 0,
    userWiseCount: 0,
    roleWiseCount: 0,
  },
  fleetOrderRightsData: [],
};

const sidebarBadgesSlice = createSlice({
  name: "sidebareBadges",
  initialState,
  reducers: {
    resetOperatingCount: (state) => {
      state.operations = {
        ...state.operations,
        loading: {
          allCount: 0,
          inners: [0, 0, 0],
        },
        unloading: {
          allCount: 0,
          inners: [0, 0, 0],
        },
        delivery: {
          allCount: 0,
          inners: [0, 0, 0],
        },
        outforDelivery: {
          allCount: 0,
          inners: [0, 0, 0],
        },
        pendingPOD: {
          allCount: 0,
          inners: [0, 0, 0],
        },
        verifyStock: {
          allCount: 0,
          inners: [0, 0, 0],
        },
      };
    },
    setVerifyExpenseBadgeCounts: (state, action) => {
      state.verify.expenses.pendingVerify = action.payload.pendingVerify;
      state.verify.expenses.pendingVoucherUpload =
        action.payload.pendingVoucherUpload;
    },
    updateVerifyStockBadges: (state, action) => {
      state.verify.verifyStock.pendingStock = action.payload;
    },
    updatePettyCashBadges: (state, action) => {
      state.verify.transactions.pettyCash = action.payload;
    },
    updateVerifyStock: (state, action) => {
      state.verify.verifyStock.pendingStock = action.payload;
    },
    decreasePendingVerifyCount: (state) => {
      const { pendingVerify, pendingVoucherUpload } = state.verify.expenses;
      if (typeof pendingVerify === "number" && pendingVerify > 0) {
        state.verify = {
          ...state.verify,
          expenses: {
            pendingVerify: pendingVerify - 1,
            pendingVoucherUpload,
          },
        };
      }
    },
    graceTowerCountFromModule: (state, action) => {
      state.graceCount.company = action.payload.company;
      state.graceCount.user = action.payload.user;
      state.graceCount.creditCoustomer = action.payload.creditCoustomer;
      state.graceCount.branch = action.payload.branch;
      state.graceCount.fleet = action.payload.fleet;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sidebareBadgesCount.fulfilled, (state, action) => {
        if (action.payload.verifyStockCount > 0) {
          state.verify = {
            ...state.verify,
            verifyStock: {
              pendingStock: action.payload?.verifyStockCount,
            },
          };
        }
        if (action.payload.tableCashCount > 0) {
          state.verify = {
            ...state.verify,
            transactions: {
              pettyCash: action.payload?.tableCashCount,
            },
          };
        }
        if (action.payload.shortageCount > 0) {
          state.verify = {
            ...state.verify,
            verifyShortage: {
              pendingShortage: action.payload?.shortageCount,
            },
          };
        }
        if (action.payload.verifyExpenseBagdeCount) {
          state.verify = {
            ...state.verify,
            expenses: {
              pendingVerify:
                action.payload?.verifyExpenseBagdeCount?.pendingVerify,
              pendingVoucherUpload:
                action.payload?.verifyExpenseBagdeCount?.pendingVoucherImage,
            },
          };
        }
        if (action.payload.fetchUnverifiedDocketCountData) {
          state.verify = {
            ...state.verify,
            pod: {
              pendingPod: action.payload?.fetchUnverifiedDocketCountData,
            },
          };
        }
        if (action.payload.graceTowerCountData) {
          state.graceCount = {
            company: action.payload?.graceTowerCountData?.company,
            user: action.payload?.graceTowerCountData?.user,
            creditCoustomer:
              action.payload?.graceTowerCountData?.creditCoustomer,
            branch: action.payload?.graceTowerCountData?.branch,
            fleet: action.payload?.graceTowerCountData?.fleet,
          };
        }
      })
      .addCase(updateAllIssueCounts.fulfilled, (state, action) => {
        state.allIssueCounts = action.payload
      })
      .addCase(updateAllIssueCounts.rejected, () => {
        return initialState;
      })
      .addCase(updateVerifyShortageBadges.fulfilled, (state, action) => {
        state.verify = {
          ...state.verify,
          verifyShortage: {
            pendingShortage: action.payload,
          },
        }
      })
      .addCase(updateVerifyShortageBadges.rejected, () => {
        return initialState;
      })
      .addCase(updateVerifyCancelBookingBadges.fulfilled, (state, action) => {
        state.verify = {
          ...state.verify,
          cancelBooking: {
            pendingCancel: action.payload,
          },
        }
      })
      .addCase(updateVerifyCancelBookingBadges.rejected, () => {
        return initialState;
      })
      .addCase(sidebareBadgesCount.rejected, () => {
        return initialState;
      })
      .addCase(selectOperatingBranch.fulfilled, (state, action) => {
        state.operations = {
          ...state.operations,
          ...action.payload,
        };
      })
      .addCase(updateCameraStatusDetails.fulfilled, (state, action) => {
        state.cameraStatusDetails = action.payload;
      })
      .addCase(updateComplaintsChatBadges.fulfilled, (state, action) => {
        state.complaintChatCount = action.payload;
      })
      .addCase(updateOperationsDeliveryCount.fulfilled, (state, action) => {
        if (action.payload) {
          state.operations.delivery.allCount = action.payload.delivery.allCount;
          state.operations.delivery.inners = action.payload.delivery.inners;
          state.operations.outforDelivery.allCount = action.payload.outforDelivery.allCount;
          state.operations.outforDelivery.inners = action.payload.outforDelivery.inners;
        }
      })
      .addCase(updateFeedbackCount.fulfilled, (state, action) => {
        if (action.payload) {
          state.feedbackCount = {
            ...action.payload,
          };
        }
      })
      .addCase(getFleetOrderRightsData.fulfilled, (state : any, action) => {
        if (action.payload) {
          state.fleetOrderRightsData = [...action.payload];
        }
      })
      .addCase(OperationsRequestCount.fulfilled, (state, action) => {
        state.RequestOperations = {
          ...state.RequestOperations,
          ...(action.payload.BTH
            ? {
              BTH: {
                allCount: action.payload.BTH.allCount,
                inners: action.payload.BTH.inners,
              },
            }
            : {}),
          ...(action.payload.ND
            ? {
              ND: {
                allCount: action.payload.ND.allCount,
                inners: action.payload.ND.inners,
              },
            }
            : {}),
          ...(action.payload.demurrage
            ? {
              demurrage: {
                allCount: action.payload.demurrage.allCount,
                inners: action.payload.demurrage.inners,
              },
            }
            : {}),
          ...(action.payload.remarks
            ? {
              remarks: {
                allCount: action.payload.remarks.allCount,
                inners: action.payload.remarks.inners,
              },
            }
            : {}),
          ...(action.payload.discount
            ? {
              discount: {
                allCount: action.payload.discount.allCount,
                inners: action.payload.discount.inners,
              },
            }
            : {}),
          ...(action.payload.doorDeliveryDiscount
            ? {
              doorDeliveryDiscount: {
                allCount: action.payload.doorDeliveryDiscount.allCount,
                inners: action.payload.doorDeliveryDiscount.inners,
              },
            }
            : {}),
          ...(action.payload.customerConnect
            ? {
              customerConnect: {
                allCount: action.payload.customerConnect.allCount,
                inners: action.payload.customerConnect.inners,
              },
            }
            : {}),
          ...(action.payload.claim
            ? {
              claim: {
                allCount: action.payload.claim.allCount,
                inners: action.payload.claim.inners,
              },
            }
            : {}),
          ...(action.payload.parcelTransfer
            ? {
              parcelTransfer: {
                allCount: action.payload.parcelTransfer.allCount,
                inners: action.payload.parcelTransfer.inners,
              },
            }
            : {}),
          ...(action.payload.cancelBooking
            ? {
              cancelBooking: {
                allCount: action.payload.cancelBooking.allCount,
                inners: action.payload.cancelBooking.inners,
              },
            }
            : {}),
          ...(action.payload.cancelDoorDeliveryBooking
            ? {
              cancelDoorDeliveryBooking: {
                allCount: action.payload.cancelDoorDeliveryBooking.allCount,
                inners: action.payload.cancelDoorDeliveryBooking.inners,
              },
            }
            : {}),
          ...(action.payload.fleetOrderRecheckIn
            ? {
              fleetOrderRecheckIn: {
                allCount: action.payload.fleetOrderRecheckIn.allCount,
                inners: action.payload.fleetOrderRecheckIn.inners,
              },
            }
            : {}),
          ...(action.payload.branchDelivery
            ? {
              branchDelivery: {
                allCount: action.payload.branchDelivery.allCount,
                inners: action.payload.branchDelivery.inners,
              },
            }
            : {}),
          ...(action.payload.packageUpdate
            ? {
              packageUpdate: {
                allCount: action.payload.packageUpdate.allCount,
                inners: action.payload.packageUpdate.inners,
              },
            }
            : {}),
        };
      })
      .addCase(updateOperationsVerifystocksCount.fulfilled, (state, action) => {
        state.operations = {
          ...state.operations,
          verifyStock: {
            allCount: action.payload.verifyStock.allCount,
            inners: action.payload.verifyStock.inners,
          },
        };
      })
      .addCase(updateOperationsPendingPODCount.fulfilled, (state, action) => {
        state.operations = {
          ...state.operations,
          pendingPOD: {
            allCount: action.payload.pendingPOD.allCount,
            inners: action.payload.pendingPOD.inners,
          },
        };
      })
      .addCase(updateOperationsUnloadingCount.fulfilled, (state, action) => {
        state.operations = {
          ...state.operations,
          unloading: {
            allCount: action.payload.unloading.allCount,
            inners: action.payload.unloading.inners,
          },
        };
      })
      .addCase(creditorSettlememtCount.fulfilled, (state, action) => {
        state.creditSettlementCount = { amount: action.payload.amount };
      })
      .addCase(updateOperationsLoadingCount.fulfilled, (state, action) => {
        state.operations = {
          ...state.operations,
          loading: {
            allCount: action.payload?.loading?.allCount || 0,
            inners: action.payload?.loading?.inners,
          },
        };
      })
      .addCase(updateOperationsAllCount.fulfilled, (state, action) => {
        state.operations = {
          ...state.operations,
          ...action.payload,
        };
      })
  },
});

export const {
  resetOperatingCount,
  setVerifyExpenseBadgeCounts,
  updateVerifyStockBadges,
  updatePettyCashBadges,
  decreasePendingVerifyCount,
  graceTowerCountFromModule,
} = sidebarBadgesSlice.actions;

export default sidebarBadgesSlice.reducer;

export const selectRight = (state: RootState) => state.branch;
// export const selectIsLoading = (state: RootState) => state.user.isLoading;
// export const selectError = (state: RootState) => state.user.error;
