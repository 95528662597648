import { createSlice } from "@reduxjs/toolkit";
import { complaintChatList } from "./complaintChatList.type";
import { complaintChatListingFunc } from "./api";

const initialState: complaintChatList = {
    complaintChat: []
}

const complaintChatListSlice = createSlice({
    name: "complaintChatList",
    initialState,
    reducers: {
        updateCountInChat: (state, action) => {
            // console.log(`kjSDNv 0.6`, state, action);

            const messageId = action.payload;
            state.complaintChat.forEach((content) => {
                if (content?._id === messageId) {
                    content.readByCount = content.totalCount;
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(complaintChatListingFunc.fulfilled, (state, action) => {
            // console.log(`kjSDNv 0.1`, state, action);
            state.complaintChat = action.payload
        });
    },
});

export const { updateCountInChat } = complaintChatListSlice.actions;

export default complaintChatListSlice.reducer;