import { createAsyncThunk } from "@reduxjs/toolkit";
import { toastMessage } from "../../../Components/showMessages/toastMessage";
import { LoginPayload } from "./customer.type";
import axios from "axios";
import { baseURL } from "../../../axios";
import * as auth from "../../../actions/auth";
import { showHttpError } from "../../../utils/message";
import { customernotificationService } from "../notificatioin/api";
import { getDoorDeliveryBookedByAddresses } from "../../../services/customer";

export const loginCustomer = createAsyncThunk(
  "auth/loginCustomer",
  async (
    { contact, pwd, rememberMe, deviceId, latitude, longitude }: LoginPayload,
    { dispatch }
  ) => {
    //   const version = "1.0.5";
    const flag: any = {
      username: contact,
      password: pwd,
      rememberMe: rememberMe,
    };
    if (deviceId) {
      flag.deviceId = deviceId;
    }
    if (latitude) {
      flag.latitude = latitude;
    }
    if (longitude) {
      flag.longitude = longitude;
    }
    const url = baseURL;
    try {
      dispatch({ type: "START_LOADER" });
      const response = await axios.post(url + "/customerLogin", flag);
      toastMessage("Successfully signed-in!");
      const { customer } = response.data.response;
      const accessToken = response.data.response?.accessToken;
      const accessTokenExpiresAt = response.data.response?.accessTokenExpiresAt;
      const refreshToken = response.data.response?.refreshToken;
      const refreshTokenExpiresAt =
        response.data.response?.refreshTokenExpiresAt;
      localStorage.setItem("loginBy", "customer");
      localStorage.setItem("kabra:customertoken", accessToken);
      // sessionStorage.setItem("kabra:user", customer._id);
      localStorage.setItem(
        "kabra:customer",
        response.data.response.customer?._id
      );
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("refreshTokenExpiresAt", refreshTokenExpiresAt);
      localStorage.setItem("accessTokenExpiresAt", accessTokenExpiresAt);
      // const token = response.data.accessToken;
      dispatch(auth.registerSocket({ token: accessToken }));
      await dispatch(customernotificationService()).unwrap();
      return {
        customer,
        token: "5b0ce34h6j",
        accessToken,
        refreshToken,
      };
    } catch (err: any) {
      let errorMessage = "Login failed";
      if (err.response) {
        errorMessage = err.response.data.message || "Invalid credentials!";
      } else if (err.request) {
        errorMessage =
          err.message === "Network Error" ? "No Internet!" : err.message;
      }
      showHttpError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const customerLoginByOTP = createAsyncThunk(
  "auth/loginCustomerByOTP",
  async (
    {
      contact,
      rememberMe,
      deviceId,
      latitude,
      longitude,
      otp,
      name,
    }: LoginPayload,
    { dispatch }
  ) => {
    //   const version = "1.0.5";
    const flag: any = {
      contact,
      OTP: otp,
      rememberMe: rememberMe,
    };
    if (deviceId) {
      flag.deviceId = deviceId;
    }
    if (name) {
      flag.name = name;
    }
    if (latitude) {
      flag.latitude = latitude;
    }
    if (longitude) {
      flag.longitude = longitude;
    }
    const url = baseURL;
    try {
      dispatch({ type: "START_LOADER" });
      const response = await axios.post(url + "/customerLogin/verifyOTP", flag);
      toastMessage("Successfully signed-in!");
      const { customer } = response.data.response;
      const accessToken = response.data.response?.accessToken;
      const accessTokenExpiresAt = response.data.response?.accessTokenExpiresAt;
      const refreshToken = response.data.response?.refreshToken;
      const refreshTokenExpiresAt =
        response.data.response?.refreshTokenExpiresAt;
      localStorage.setItem("loginBy", "customer");
      sessionStorage.setItem("role", "customer");
      localStorage.setItem("kabra:customertoken", accessToken);
      // sessionStorage.setItem("kabra:user", customer._id);
      localStorage.setItem(
        "kabra:customer",
        response.data.response.customer?._id
      );
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("refreshTokenExpiresAt", refreshTokenExpiresAt);
      localStorage.setItem("accessTokenExpiresAt", accessTokenExpiresAt);
      // const token = response.data.accessToken;
      dispatch(auth.registerSocket({ token: accessToken }));
      await dispatch(customernotificationService()).unwrap();
      return {
        customer,
        token: "5b0ce34h6j",
        accessToken,
        refreshToken,
      };
    } catch (err: any) {
      let errorMessage = "Login failed";
      if (err.response) {
        errorMessage = err.response.data.message || "Invalid credentials!";
      } else if (err.request) {
        errorMessage =
          err.message === "Network Error" ? "No Internet!" : err.message;
      }
      showHttpError(errorMessage);
      throw new Error(errorMessage);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const friendAddressSuggestionAction = createAsyncThunk(
  "customer/friendAddressSuggestionAction",
  async () => {
    let arr : any[] = []
    try {
      const response1 = await getDoorDeliveryBookedByAddresses({});
      response1?.map((res: any) => {
        res?.doorDeliveryAddr.map((data: any) => {
          arr.push(data);
        });
      });
      return arr;
    } catch (err) {
      showHttpError(err);
    }
  }
);

export const setCustomerDetails = createAsyncThunk(
  "customer/setCustomerDetails",
  async (_,{dispatch}) => {
    try {
      const url = baseURL;
      dispatch({
        type: "START_LOADER",
      });
      const response = await axios.get(url + "/customerProfile/getDetails");
      const { customer } = response.data.response;
      return {
        customer
      };
    } catch (err) {
      showHttpError(err);
    }
    finally{
      dispatch({
        type: "END_LOADER",
      });
    }
  }
)
