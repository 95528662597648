import { createAsyncThunk } from "@reduxjs/toolkit";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import {
  departmentListService,
  getUserByContact,
  listUsersService,
} from "../../../services/user";
import showMessage, {
  failed,
  showHttpError,
  success,
} from "../../../utils/message";
import { getCategoryList, vehicleCategory } from "../../../services/category";
import {
  changeAddExpenseType,
  changeAddIncomeType,
  changeAddIncomeTypes,
  changeCompanyDocData,
  changeEntityId,
  changeFixedExpenseData,
  clearCompanyDoc,
  deleteExpenseTypeData,
  deleteFixedExpenseTypeData,
  editExpenseSuccess,
  editIncomeTypeSuccess,
  getCompanyListData,
  getCompanyReqSuccess,
  getExpenseTypes,
  getFixedExpenseById,
  getPostedSubTypes,
  getVehicleIdData,
  incomeTypeDeleted,
  initCompanyDoc,
  removeIncomeType,
  setAssetTypes,
  setCompanyDoc,
  setExpenseTypeData,
  setFixedExpenseTypeData,
  setIncomeTypeForEdit,
  submitExpenseSuccess,
  submitFixedExpenseSuccess,
} from "./companySlice";
import {
  createCompanyService,
  listCompanyService,
  updateCompanyService,
  viewCompanyService,
} from "../../../services/company";
import {
  deleteExpense,
  deleteFixedExpenseType,
  editFixedExpenseType,
  expenseEditHandler,
  expenseSubmitHandler,
  fixedExpenseSubmitHandler,
  getExpenseList,
  getFixedExpenseTypeById,
} from "../../../services/expenses";
import { RootState } from "../../store";
import {
  getAssetBalance,
  listAssetType,
  viewAssetBalanceDetails,
} from "../../../services/asset";
import {
  deleteIncomeTypes,
  editIncomeType,
  getSubTypes,
} from "../../../services/income";
import { getIncomeTypes } from "../income/api";
import { resolveAPI } from "../../../services/helpers/error";
import { toast } from "react-toastify";
import { getCityByPincode } from "../../../services/area";
import jsonToFormdata from "../../../utils/jsonToFormdata";
import { cloneDeep } from "lodash";

export const changeIncomeTypeApi = createAsyncThunk(
  "conpany/changeIncomeType",
  async ({ what, val }: { what: string; val: string }, { dispatch }) => {
    let payload = { what, val };
    try {
      dispatch(showLoader());
      let response = await getCategoryList();
      dispatch(setAssetTypes(response));
      dispatch(changeAddIncomeTypes(payload));
      return response;
    } catch (error) {
      showHttpError(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const changeIncomeType = (what: any, val: any) => {
  return (dispatch: any) => dispatch(changeIncomeTypeApi({ what, val }));
};

export const getUserDetails = createAsyncThunk(
  "userForm/getUserDetails",
  async (contact: number, { dispatch }) => {
    try {
      dispatch(showLoader());
      let response = await getUserByContact(contact);
      if (response != null) {
        return response;
      }
      return response;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const fetchDepartmentList = createAsyncThunk(
  "userForm/fetchDepartmentList",
  async ({ companyId }: any) => {
    try {
      const response = await departmentListService(companyId);
      return response;
    } catch (error) {
      showHttpError("Could Not Fetch Departments!");
    }
  }
);

export const getCompany = createAsyncThunk(
  "conpany/changeIncomeType",
  async (company: string, { dispatch }) => {
    try {
      dispatch(showLoader());
      let companyDoc = await viewCompanyService(company);
      console.log(companyDoc, "companyDoc11111");
      showMessage("Fetched company successfully", success);
      dispatch(getCompanyReqSuccess(companyDoc));
      dispatch(hideLoader());
    } catch (err: any) {
      dispatch(hideLoader());
      showMessage(err.message || "No such company exists", failed);
    }
  }
);

export const changesExpenseTypeApi = createAsyncThunk(
  "conpany/changesExpenseType",
  async (payload: { what: string | string[]; val: any }, { dispatch }) => {
    try {
      console.log(payload, "payload111111111");
      const { val, what } = payload;
      dispatch(showLoader());
      dispatch(changeAddExpenseType(payload));
      if (what == "sub" && val == "A") {
        let response = await getCategoryList();
        dispatch(setAssetTypes(response));
      }
      dispatch(hideLoader());
    } catch (error: any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't update message!", failed);
    }
  }
);

export const changesExpenseType = (what: string | string[], val: any) => {
  return (dispatch: any) => {
    dispatch(changesExpenseTypeApi({ what, val }));
  };
};

export const ExpenseSubmitHandler = createAsyncThunk(
  "conpany/ExpenseSubmitHandler",
  async (
    payload: { what: string | string[]; val: any },
    { dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      dispatch(showLoader());
      await expenseSubmitHandler(payload);
      dispatch(submitExpenseSuccess());
      showMessage("Expense type created successfully!", success);
      let company = state.user.company.id;
      await dispatch(fetchExpenseList({ company }));
    } catch (error: any) {
      showMessage(error.message || "Couldn't Add Expense Type !", failed);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const expenseTypeEdit = createAsyncThunk(
  "conpany/expenseTypeEdit",
  async (
    payload: { what: string | string[]; val: any },
    { dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      dispatch(showLoader());
      await expenseEditHandler(payload);
      dispatch(editExpenseSuccess());
      showMessage("Expense Type Edited Successfully!", success);
      let company = state.user.company.id;
      await dispatch(fetchExpenseList({ company }));
    } catch (error: any) {
      showMessage(error.message || "Couldn't Edit Expense Type", failed);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const fixedExpenseSubmit = createAsyncThunk(
  "conpany/fixedExpenseSubmit",
  async (payload: any, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      dispatch(showLoader());
      let data = {
        ...payload,
        company: state.user.company._id,
      };
      let res: any = await fixedExpenseSubmitHandler(data);
      if (res.status !== 200) throw "err";
      dispatch(submitFixedExpenseSuccess());
      await dispatch(fetchFixedExpenseTypesById(payload.ref));
      dispatch(hideLoader());
      showMessage("Fixed Expense type created successfully!", success);
    } catch (error: any) {
      dispatch(hideLoader());
      showMessage(
        (error.data && error.data.message) || "Couldn't create fixed expense",
        failed
      );
    }
  }
);

export const fetchExpenseList = createAsyncThunk(
  "conpany/fetchExpenseList",
  async (data: any, { dispatch }) => {
    try {
      dispatch(showLoader());
      const expenseList:any = await getExpenseList(data);
      dispatch(getExpenseTypes(expenseList));
      dispatch(hideLoader());
    } catch (error: any) {
      // await dispatch({ type: GET_EXPENSE_TYPES_FAILED })
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't fetch expenses.", failed);
    }
  }
);

export const fetchFixedExpenseTypesById = createAsyncThunk(
  "conpany/fetchFixedExpenseTypesById",
  async (id: string, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      await dispatch(showLoader());
      let company = state.user.company.id;
      let data = { id, company };
      let response = await getFixedExpenseTypeById(data);
      dispatch(getFixedExpenseById(response));
    } catch (error: any) {
      showMessage(
        error && error.message
          ? error.message
          : "couldn't fetch fixed expense.",
        failed
      );
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const editFixedExpense = createAsyncThunk(
  "conpany/editFixedExpense",
  async (data: any, { dispatch }) => {
    try {
      dispatch(showLoader());
      await editFixedExpenseType(data);
      // dispatch({ type: EDIT_FIXED_EXPENSE_SUCCESS })
      await dispatch(fetchFixedExpenseTypesById(data.ref));

      dispatch(hideLoader());
      showMessage("Fixed Expense edited!");
    } catch (error: any) {
      showMessage(error.message || "Could Not Edit Fixed Expense !", failed);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const deleteFixedExpenseApi = createAsyncThunk(
  "conpany/deleteFixedExpense",
  async ({ data, id }: { data: any; id: string }, { dispatch }) => {
    try {
      dispatch(showLoader());
      await deleteFixedExpenseType(id);
      dispatch(deleteFixedExpenseTypeData());
      showMessage("Fixed Expense Type Deleted !", success);
      await dispatch(fetchFixedExpenseTypesById(data.ref.value));

      dispatch(hideLoader());
    } catch (error: any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't delete expense.", failed);
    }
  }
);

export const deleteFixedExpense = (data: any, id: string) => {
  const payload = {
    data,
    id,
  };
  return deleteFixedExpenseApi(payload);
};

export const changeFixedExpenseApi = createAsyncThunk(
  "conpany/changeFixedExpenseApi",
  async ({ what, val }: { what: any; val: any }, { dispatch, getState }) => {
    const { user, company, branch } = getState() as RootState;
    try {
      let payload = { what, val };
      let type = { ...what };
      if (type[0] == "entity") {
        dispatch(changeFixedExpenseData(payload));
        let subTypes;
        let assetTypes;
        switch (val) {
          case "G": {
            subTypes = [];
            let data = {
              what: ["entity_id"],
              val: user.company._id,
            };
            dispatch(changeFixedExpenseData(data));
            break;
          }
          case "B": {
            company;
            subTypes = branch.allbranches;
            break;
          }
          case "U": {
            subTypes = await listUsersService();
            break;
          }
          case "A": {
            let companyJS: any = company;
            let { value }: any =
              companyJS.fixed_expenses.post_fixed_expense.ref;
            let { fetched_expense_types }: any = companyJS.fixed_expenses;
            let categoryId;
            Array.isArray(fetched_expense_types) &&
              fetched_expense_types.forEach((e) => {
                if (e.id == value) {
                  categoryId = e.category;
                }
              });
            assetTypes = await listAssetType({
              company: user.company._id,
              categoryId,
            });
            break;
          }
          default:
            break;
        }
        if (val == "A") {
          dispatch(setAssetTypes(assetTypes));
        } else {
          dispatch(changeEntityId(subTypes));
        }
      } else {
        dispatch(changeFixedExpenseData(payload));
      }
    } catch (error: any) {
      showHttpError(error);
    }
  }
);

export const changeFixedExpense = (what: any, val: any) => {
  const payload = {
    what,
    val,
  };
  return changeFixedExpenseApi(payload);
};

export const selectAssetTypesApi = createAsyncThunk(
  "conpany/selectAssetTypesApi",
  async ({ ref, asset }: { ref: any; asset: any }, { dispatch, getState }) => {
    try {
      dispatch(showLoader());
      const state = getState() as RootState;
      let assetTypeId = state.company.fixed_expenses.assetType._id;
      let category;
      let { fetched_expense_types } = state.company.fixed_expenses;
      Array.isArray(fetched_expense_types) &&
        fetched_expense_types.forEach((e) => {
          if (ref == e.id) {
            category = e.category;
          }
        });
      let response;

      if (assetTypeId == category) {
        let data = {
          assetType: asset,
          company: state.user.company._id,
        };
        response = await getAssetBalance(data);
      } else {
        let data = {
          assetType: asset,
          company: state.user.company._id,
          branch: state.user.opBranch._id,
        };
        response = await getAssetBalance(data);
      }
      dispatch(changeEntityId(response));
    } catch (error: any) {
      showMessage(error.message, failed);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const selectAssetTypes = (ref: any, asset: any) => {
  const payload = {
    ref,
    asset,
  };
  return selectAssetTypesApi(payload);
};

export const getVehicleId = createAsyncThunk(
  "conpany/getVehicleId",
  async (_, { dispatch }) => {
    try {
      let response = await vehicleCategory();
      dispatch(getVehicleIdData(response));
    } catch (error) {
      showHttpError(error);
    }
  }
);

export const setIncomeTypesForEdit = createAsyncThunk(
  "conpany/setIncomeTypesForEdit",
  async (data: any, { dispatch }) => {
    try {
      dispatch(showLoader());
      dispatch(setIncomeTypeForEdit(data));
      switch (data.sub) {
        case "A":
          let categoryList = await getCategoryList();
          dispatch(setAssetTypes(categoryList));
          let val = {};
          if (Array.isArray(categoryList)) {
            categoryList.map((c) => {
              if (c._id == data.category) {
                val = { label: c.name, value: c._id };
              }
            });
          }
          dispatch(changeAddIncomeType({ what: ["category"], val }));
          break;
        default:
          break;
      }
    } catch (error: any) {
      showMessage(error.message, failed);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const incomeTypeEdit = createAsyncThunk(
  "conpany/incomeTypeEdit",
  async (data: any, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      dispatch(showLoader());
      await editIncomeType(data);
      showMessage("Income Type Edited Successfully ", success);
      dispatch(editIncomeTypeSuccess());
      let companyId = state.user.company.id;
      await dispatch(getIncomeTypes(companyId));
      dispatch(hideLoader());
    } catch (error: any) {
      dispatch(hideLoader());
      showMessage(error.message || "Couldn't edit income type.", failed);
    }
  }
);

export const getSubIncomeTypesForList = createAsyncThunk(
  "conpany/getSubIncomeTypesForList",
  async (_, { dispatch }) => {
    try {
      dispatch(showLoader());
      let response: any = await getSubTypes();
      dispatch(getPostedSubTypes(response.response));
    } catch (error: any) {
      showMessage(error.message || "No sub incomes fetched.", failed);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const deleteIncomeType = createAsyncThunk(
  "conpany/deleteIncomeType",
  async (id: string, { dispatch }) => {
    try {
      dispatch(showLoader());
      await resolveAPI(deleteIncomeTypes, [id]);
      showMessage("Income Type Deleted ", success);
      dispatch(incomeTypeDeleted());
      dispatch(removeIncomeType(id));
    } catch (error: any) {
      if (typeof error === "string") toast.error(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const setExpenseType = createAsyncThunk(
  "conpany/setExpenseType",
  async (data: any, { dispatch }) => {
    try {
      dispatch(showLoader());
      dispatch(setExpenseTypeData(data));
      switch (data.sub) {
        case "A":
          let categoryList = await getCategoryList();
          dispatch(setAssetTypes(categoryList));
          let val = {};
          if (Array.isArray(categoryList)) {
            categoryList.map((c) => {
              if (c._id == data.category) {
                val = { label: c.name, value: c._id };
              }
            });
          }
          dispatch(changeAddExpenseType({ what: ["category"], val }));
          break;
        default:
          break;
      }
      dispatch(hideLoader());
    } catch (error) {
      showMessage("Couldn't set expense type", failed);
      dispatch(hideLoader());
    }
  }
);

export const deleteExpenseType = createAsyncThunk(
  "conpany/deleteExpenseType",
  async (id: number, { dispatch }) => {
    try {
      dispatch(showLoader());
      await deleteExpense(id);
      dispatch(deleteExpenseTypeData());
      showMessage("Expense Type Deleted !", success);
      dispatch(hideLoader());
    } catch (error: any) {
      showMessage(error.message || "Couldn't delete expense type", failed);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const setFixedExpenseType = createAsyncThunk(
  "conpany/setFixedExpenseType",
  async (data: any, { dispatch, getState }) => {
    try {
      dispatch(showLoader());
      const { user, company } = getState() as RootState;
      let newData = { ...data };
      // let fixedExpenseById = {...newData}
      let fetched_expense_types = company.fixed_expenses.fetched_expense_types;
      // fixedExpenseById.ref = {label: newData.name, value: newData.id}
      // await dispatch(changeFixedExpense(['entity'], newData.sub))
      let entity_types = company.fixed_expenses.fetched_entity_types;
      switch (newData.sub) {
        case "U": {
          let getuserPayload = {
            // branch: user.opBranch._id,
          };
          let users = await listUsersService(getuserPayload);
          dispatch(changeEntityId(users));
          if (Array.isArray(users) && users.length > 0) {
            users.map((e: any) => {
              if (e._id == newData.entity_id) {
                newData["entity_id"] = {
                  label: e.name.fName + " " + e.name.lName,
                  value: e._id,
                };
              }
            });
          }
          break;
        }
        case "B": {
          company;
          let subTypes = (user as any)?.allbranches;
          dispatch(changeEntityId(subTypes));
          if (Array.isArray(entity_types)) {
            entity_types.map((b) => {
              if (b._id == newData.entity_id) {
                newData["entity_id"] = { label: b.branchName, value: b._id };
              }
            });
          }
          break;
        }
        case "A": {
          let companyJS: any = company;
          // let { value } = companyJS.fixed_expenses.post_fixed_expense.ref
          let { fetched_expense_types }: any = companyJS.fixed_expenses;
          let categoryId = newData;
          Array.isArray(fetched_expense_types) &&
            fetched_expense_types.forEach((e: any) => {
              if (e.name == newData.name) {
                categoryId = e.category;
              }
            });
          let assetTypes = await listAssetType({
            company: user.company._id,
            categoryId,
          });
          dispatch(setAssetTypes(assetTypes));

          let assetBal = await viewAssetBalanceDetails(newData.entity_id);
          newData["assetType"] = {
            label: assetBal.type.assetName,
            value: assetBal.type._id,
          };
          newData["entity_id"] = {
            label: assetBal.uniqueId
              ? assetBal.uniqueId
              : assetBal.qty + " " + assetBal.type.assetName,
            value: assetBal._id,
          };
          let response;
          let data = {
            assetType: newData["assetType"].value,
            company: user.company._id,
          };
          response = await getAssetBalance(data);
          dispatch(changeEntityId(response));
          // await dispatch({ type: CHANGE_ENTITY_ID, payload: assetBal })
          break;
        }
        default:
          break;
      }
      if (Array.isArray(fetched_expense_types)) {
        fetched_expense_types.map((e) => {
          if (e.name == newData.name) {
            newData.ref = { label: e.name, value: e.id };
          }
        });
      }
      dispatch(setFixedExpenseTypeData(newData));
      dispatch(hideLoader());
    } catch (error: any) {
      dispatch(hideLoader());
      showMessage(error.message || "Set Fixed expense failed.", failed);
    }
  }
);

export const getCompanyList = createAsyncThunk(
  "conpany/getCompanyList",
  async (_, { dispatch }) => {
    try {
      // dispatch(showLoader());
      let response = await listCompanyService();
      dispatch(getCompanyListData(response));
      ``;
    } catch (error: any) {
      showMessage(error.message || "Couldn't list company", failed);
    } finally {
      // dispatch(hideLoader());
    }
  }
);

export const changeCompanyDocApi = createAsyncThunk(
  "conpany/changeCompanyDoc",
  async (
    {
      what,
      value,
    }: {
      what: any;
      value: any;
    },
    { dispatch }
  ) => {
    dispatch(
      changeCompanyDocData({
        what: what,
        value: value,
      })
    );
    if (
      what[0] == "address" &&
      what[1] == "pincode" &&
      value &&
      value.length == 6
    ) {
      try {
        const city = await getCityByPincode(value);
        dispatch(
          changeCompanyDocData({
            what: ["address", "city"],
            value: city._id,
          })
        );
      } catch (err: any) {
        showHttpError(err);
      }
    }
  }
);

export const changeCompanyDoc = (what: any, value: any) => {
  return (dispatch: any) => {
    const data = {
      what,
      value,
    };
    dispatch(changeCompanyDocApi(data));
  };
};

export const createCompanyDocAction = createAsyncThunk(
  "conpany/createCompanyDocAction",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const companyObj = cloneDeep(state).company.company_doc as any;
    let company = companyObj;
    company.fleetType = companyObj.fleetType.value;
    company.assetSubType = companyObj.assetSubType.value;
    company.primaryCompany = companyObj.primaryCompany.value;
    // company['user'] = getstate().user.uid
    delete company["errors"];
    if (company.individual) {
      delete company["companyName"];
      delete company["email"];
      delete company["url"];
      delete company["kind"];
      delete company["companyLogo"];
      delete company["_id"];
      delete company["isNew"];
      if (!company["regNumber"]) {
        delete company["modelNumber"];
        delete company["capacity"];
        delete company["volume"];
        delete company["distanceCovered"];
        delete company["ownerShip"];
        delete company["idealFuel"];
        delete company["insuranceDate"];
        delete company["fitnessDate"];
        delete company["permitDate"];
        delete company["emiDate"];
        delete company["allowedOFDCities"];
        delete company["overhead"];
        delete company["fleetId"];
        delete company["noOfSeats"];
        delete company["businessType"];
        delete company["fuelType"];
        delete company["bodyType"];
        delete company["fleetType"];
        delete company["assetSubType"];
      }
    } else {
      // delete company["licenceImage"];
      // delete company["licenceExpiry"];
      // delete company["licence"];
      // delete company["licenceImage"];
      delete company["userProfileImage"];
      delete company["userProfile"];
      delete company["_id"];
      delete company["isNew"];

      delete company["bodyType"];
      delete company["regNumber"];
      delete company["fleetType"];
      delete company["assetSubType"];

      delete company["modelNumber"];
      delete company["capacity"];
      delete company["volume"];
      delete company["distanceCovered"];
      delete company["ownerShip"];
      delete company["idealFuel"];
      delete company["insuranceDate"];
      delete company["fitnessDate"];
      delete company["permitDate"];
      delete company["emiDate"];
      delete company["allowedOFDCities"];
      delete company["overhead"];
      delete company["fleetId"];
      delete company["noOfSeats"];
      delete company["businessType"];
      delete company["fuelType"];
    }
    delete company["user"];

    try {
      if (company.regNumber) company.individualFleet = true;
      dispatch(showLoader());
      await createCompanyService(jsonToFormdata(company));
      dispatch(getCompanyList());
      dispatch(clearCompanyDoc(state.company.company_doc.individual));
      dispatch(hideLoader());
      showMessage("Company Created.", success);
    } catch (err: any) {
      dispatch(hideLoader());
      showMessage(err.message || "Company creation failed.", failed);
    }
  }
);

export const updateCompanyDocAction = createAsyncThunk(
  "conpany/updateCompanyDocAction",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    let company = cloneDeep(state).company.company_doc as any;
    const companyId = company._id;
    // company['user'] = getstate().user.uid
    company["contactPerson"] = {
      ...company["contactPerson"],
    };
    delete company["errors"];
    delete company.contactPerson["password"];

    if (!company?.aadharCardImage) {
      delete company?.aadharCard;
    }
    if (!company?.panCardImage) {
      delete company?.panCard;
    }
    if (!company?.userProfileImage) {
      delete company?.userProfile;
    }
    if (!company?.agreementImage) {
      delete company?.agreement;
    }
    if (!company?.licenceImage) {
      delete company?.licence;
    }
    if (!company?.companyLogoImage) {
      delete company?.companyLogo;
    }

    if (company?.panCardImage) {
      if (company?.panCard?.image === "") {
        // If panCard image is an empty string, delete only the image property
        delete company.panCard.image;
      } else if (company?.panCard?.image) {
        // If the panCard image is not empty, keep panCard as is
        company.panCard = {
          ...company.panCard,
        };
      }
    }
    if (company?.panCard?.identifier === null) {
      delete company.panCard.identifier;
    }

    if (company?.userProfileImage) {
      if (company?.userProfile?.image === "") {
        // If userProfile image is an empty string, delete only the image property
        delete company.userProfile.image;
      } else if (company?.userProfile?.image) {
        // If the userProfile image is not empty, keep userProfile as is
        company.userProfile = {
          ...company.userProfile,
        };
      }
    }
    if (company?.userProfile?.identifier === null) {
      delete company.userProfile.identifier;
    }

    if (company?.aadharCardImage) {
      if (company?.aadharCard?.image === "") {
        // If aadharCard image is an empty string, delete only the image property
        delete company.aadharCard.image;
      } else if (company?.aadharCard?.image) {
        // If the aadharCard image is not empty, keep aadharCard as is
        company.aadharCard = {
          ...company.aadharCard,
        };
      }
    }
    if (company?.aadharCard?.identifier === null) {
      delete company.aadharCard.identifier;
    }

    if (company?.agreementImage) {
      if (company?.agreement?.image === "") {
        // If agreement image is an empty string, delete only the image property
        delete company.agreement.image;
      } else if (company?.agreement?.image) {
        // If the agreement image is not empty, keep agreement as is
        company.agreement = {
          ...company.agreement,
        };
      }
    }
    if (company?.agreement?.identifier === null) {
      delete company.agreement.identifier;
    }

    if (company?.licenceImage) {
      if (company?.licence?.image === "") {
        // If licence image is an empty string, delete only the image property
        delete company.licence.image;
      } else if (company?.licence?.image) {
        // If the licence image is not empty, keep licence as is
        company.licence = {
          ...company.licence,
        };
      }
    }
    if (company?.licence?.identifier === null) {
      delete company.licence.identifier;
    }

    if (company?.companyLogoImage) {
      if (company?.companyLogo?.image === "") {
        // If companyLogo image is an empty string, delete only the image property
        delete company.companyLogo.image;
      } else if (company?.companyLogo?.image) {
        // If the companyLogo image is not empty, keep companyLogo as is
        company.companyLogo = {
          ...company.companyLogo,
        };
      }
    }
    if (company?.companyLogo?.identifier === null) {
      delete company.companyLogo.identifier;
    }

    if (company.individual) {
      delete company["companyName"];
      delete company["email"];
      delete company["url"];
      delete company["kind"];
      delete company["companyLogo"];
      delete company["_id"];
      delete company["isNew"];
      if (!company["regNumber"]) {
        delete company["modelNumber"];
        delete company["capacity"];
        delete company["volume"];
        delete company["distanceCovered"];
        delete company["ownerShip"];
        delete company["idealFuel"];
        delete company["insuranceDate"];
        delete company["fitnessDate"];
        delete company["permitDate"];
        delete company["emiDate"];
        delete company["allowedOFDCities"];
        delete company["overhead"];
        delete company["fleetId"];
        delete company["noOfSeats"];
        delete company["businessType"];
        delete company["fuelType"];
        delete company["bodyType"];
        delete company["fleetType"];
        delete company["assetSubType"];
      }
      if (!company["fleetId"]) delete company["fleetId"];
    } else {
      delete company["bodyType"];
      delete company["regNumber"];
      delete company["fleetType"];
      delete company["assetSubType"];
      // delete company["licenceImage"];
      // delete company["licenceExpiry"];
      delete company["licence"];
      delete company["licenceImage"];
      delete company["userProfileImage"];
      delete company["userProfile"];
      delete company["_id"];
      delete company["isNew"];

      delete company["modelNumber"];
      delete company["capacity"];
      delete company["volume"];
      delete company["distanceCovered"];
      delete company["ownerShip"];
      delete company["idealFuel"];
      delete company["insuranceDate"];
      delete company["fitnessDate"];
      delete company["permitDate"];
      delete company["emiDate"];
      delete company["allowedOFDCities"];
      delete company["overhead"];
      delete company["fleetId"];
      delete company["noOfSeats"];
      delete company["businessType"];
      delete company["fuelType"];
    }
    try {
      dispatch(showLoader());
      if (company.assetSubType) {
        company.assetSubType = company.assetSubType._id;
      }
      if (company.fleetType) {
        company.fleetType = company.fleetType._id;
      }
      company.primaryCompany = company.primaryCompany;
      let finalCompany: any = jsonToFormdata(company);
      await updateCompanyService(companyId, finalCompany);
      dispatch(clearCompanyDoc(state.company.company_doc.individual));
      dispatch(hideLoader());
      showMessage("Company Updated Successfull", success);
    } catch (err: any) {
      dispatch(hideLoader());
      showMessage(err.message || "Couldn't update company.", failed);
    }
  }
);

export const setCompanyDocAction = createAsyncThunk(
  "conpany/setCompanyDocAction",
  async (id: string, { dispatch }) => {
    try {
      dispatch(showLoader());
      const result = await viewCompanyService(id);
      let newresult: any = {
        isNew: false,
        individual: result.individual || false,
        individualFleet: result.individualFleet || false,
        _id: result._id,
        companyName: result.companyName,
        active: result.active,
        isDocVerified: result.isDocVerified,
        twoFactorAuthentication: result.twoFactorAuthentication,
        userAttendance: result.userAttendance,
        branchTiming: result.branchTiming,
        cameraStatus: result.cameraStatus,
        phoneStatus: result.phoneStatus,
        customerVerification: result.customerVerification,
        allDocketVerification: result.allDocketVerification,
        withoutEWayBillDocketVerification:
          result.withoutEWayBillDocketVerification,
        photoVerification: result.photoVerification,
        otpVerification: result.otpVerification,
        receiverAllDocketVerification: result.receiverAllDocketVerification,
        receiverPhotoVerification: result.receiverPhotoVerification,
        receiverOtpVerification: result.receiverOtpVerification,
        pendingDelivery: result.pendingDelivery,
        cancelBooking: result.cancelBooking,
        loading: result.loading,
        unloading: result.unloading,
        backToHub: result.backToHub,
        myDuty: result.myDuty,
        creditCustomer: result.creditCustomer,
        pendingVoucher: result.pendingVoucher,
        userPendingMemo: result.userPendingMemo,
        userPendingReport: result.userPendingReport,
        branchPendingMemo: result.branchPendingMemo,
        branchPendingReport: result.branchPendingReport,
        mandatoryExpVerification: result.mandatoryExpVerification,
        contactVerified: result.contactVerified,
        // interCompanyCashReceiver: result.interCompanyCashReceiver,
        // intraCompanyCashReceiver: result.intraCompanyCashReceiver,
        // interCompanyCashUser: result.interCompanyCashUser || [],
        // intraCompanyCashUser: result.intraCompanyCashUser || [],

        verifiedPackages: result.verifiedPackages,
        qrPrinter: result.qrPrinter,
        userVerification: result.userVerification,
        fleetDocVerification: result.fleetDocVerification,
        deliveryWithQr: result.deliveryWithQr,
        contactPerson: {
          contact: result.contactPerson && result.contactPerson.contact,
          name: {
            fName: result.contactPerson && result.contactPerson.name.fName,
            lName: result.contactPerson && result.contactPerson.name.lName,
          },
          email: result.contactPerson && result.contactPerson.email,
          password: result.contactPerson && result.contactPerson.password,
          // panCard: {
          //   status: result.contactPerson && result.contactPerson?.panCard?.status, // Ensure status defaults to 'P'
          //   image: result.contactPerson && result.contactPerson?.panCard?.image || '', // Ensure status defaults to 'P'
          // },
          // agreement: {
          //   status: result.contactPerson && result.contactPerson?.agreement?.status, // Ensure status defaults to 'P'
          //   image: result.contactPerson && result.contactPerson?.agreement?.image || '',
          // },
        },

        // panCard: result?.contactPerson?.panCard ? result.contactPerson.panCard : {}, // Move panCard out of contactPerson
        // panCardImage: result?.contactPerson?.panCard?.image || "", // Flattened panCard image
        address: {
          l1: result.address && result.address.l1,
          l2: result.address && result.address.l2,
          city: result.address && result.address.city,
          pincode: result.address && result.address.pincode,
        },
        bankAccount: result.bankAccount,
        ifscCode: result.ifscCode,
        // panNumber: result.panNumber,
        // panCardImage: result.panCardImage,
        displayName: result.displayName,
        businessSubType: result?.businessSubType,
        companyType: result?.companyType,
        settleCompany: result?.settleCompany,
        messages: { ...result?.messages },
        primaryCompany: result.primaryCompany?._id,
        themePreference: result?.themePreference
          ? result?.themePreference
          : initCompanyDoc.themePreference,
        autoEwayBillPartBVerification: result?.autoEwayBillPartBVerification
          ? result?.autoEwayBillPartBVerification
          : initCompanyDoc.autoEwayBillPartBVerification,
        eWaybillValidityCheck: result?.eWaybillValidityCheck
          ? result?.eWaybillValidityCheck
          : initCompanyDoc.eWaybillValidityCheck,
        cashDiscount: result?.cashDiscount
          ? result?.cashDiscount
          : initCompanyDoc.cashDiscount,
        cashDemurrageDiscount: result?.cashDemurrageDiscount
          ? result?.cashDemurrageDiscount
          : initCompanyDoc.cashDemurrageDiscount,
        shortCode: result?.shortCode,
      };
      if (newresult.individual == false && newresult.individualFleet == false)
        newresult.banner = result?.banner ? result?.banner : {};
      if (newresult.individual == false && newresult.individualFleet == false)
        newresult.bannerImage = result?.banner?.image
          ? result?.banner?.image
          : "";
      if (newresult.individual == false && newresult.individualFleet == false) {
        newresult.panCard = result?.panCard ? result?.panCard : {};
        newresult.panCardImage = result?.panCard?.image
          ? result?.panCard?.image
          : "";
      } else if (
        newresult.individual == true ||
        newresult.individualFleet == true
      ) {
        newresult.panCard = result?.contactPerson?.panCard
          ? result?.contactPerson?.panCard
          : {};
        newresult.panCardImage = result?.contactPerson?.panCard?.image
          ? result?.contactPerson?.panCard?.image
          : "";
      }
      // newresult.comPanCard = result?.panCard ? result?.panCard : {}
      // newresult.comPanCardImage = result?.panCard?.image ? result?.panCard?.image : ""
      newresult.aadharCard = result?.contactPerson?.aadharCard
        ? result?.contactPerson?.aadharCard
        : {};
      newresult.aadharCardImage = result?.contactPerson?.aadharCard?.image
        ? result?.contactPerson?.aadharCard?.image
        : "";
      //  aadharCardImage:"",
      //aadharCard:{},
      // newresult.aadharCard = result?.aadharCard ? result?.aadharCard : {}
      // newresult.aadharCardImage = result?.aadharCard?.image ? result?.aadharCard?.image : ""
      if (newresult.individual == false && newresult.individualFleet == false)
        newresult.companyLogo = result?.companyLogo ? result?.companyLogo : {};
      if (newresult.individual == false && newresult.individualFleet == false)
        newresult.companyLogoImage = result?.companyLogo?.image
          ? result.companyLogo?.image
          : "";
      if (newresult.individual == false)
        newresult.agreement = result?.agreement?.image ? result?.agreement : {};
      if (newresult.individual == false)
        newresult.agreementImage = result?.agreement?.image
          ? result?.agreement?.image
          : "";
      if (newresult.individual == true || newresult.individualFleet == true)
        newresult.licence = result?.contactPerson?.licence
          ? result?.contactPerson?.licence
          : {};

      if (newresult.individual == true || newresult.individualFleet == true)
        newresult.licenceImage = result?.contactPerson?.licence?.image
          ? result?.contactPerson?.licence?.image
          : "";
      // if (newresult.individual == true || newresult.individualFleet == true) newresult.licence = result?.licence ? result?.licence : {};
      // if (newresult.individual == true || newresult.individualFleet == true) newresult.licenceImage = result?.licence?.image ? result.licence?.image : "";
      if (
        !(newresult.individual == false && newresult.individualFleet == false)
      )
        newresult.userProfile = result?.contactPerson?.userProfile
          ? result?.contactPerson?.userProfile
          : {};
      if (
        !(newresult.individual == false && newresult.individualFleet == false)
      )
        newresult.userProfileImage = result?.contactPerson?.userProfile?.image
          ? result?.contactPerson?.userProfile?.image
          : "";
      if (newresult.individual == false && newresult.individualFleet == true)
        newresult.rcBook = result?.contactPerson?.fleets[0]?.rcBook
          ? result?.contactPerson?.fleets[0]?.rcBook
          : {};
      if (newresult.individual == false && newresult.individualFleet == true)
        newresult.rcBookImage = result?.contactPerson?.fleets[0]?.rcBook?.image
          ? result?.contactPerson?.fleets[0]?.rcBook?.image
          : "";
      if (newresult.individual == false && newresult.individualFleet == true)
        newresult.insurance = result?.contactPerson?.fleets[0]?.insurance
          ? result?.contactPerson?.fleets[0]?.insurance
          : {};
      if (newresult.individual == false && newresult.individualFleet == true)
        newresult.insuranceImage = result?.contactPerson?.fleets[0]?.insurance
          ?.image
          ? result?.contactPerson?.fleets[0]?.insurance?.image
          : "";
      if (newresult.individual == false && newresult.individualFleet == true)
        newresult.allIndiaPermit = result?.contactPerson?.fleets[0]
          ?.allIndiaPermit
          ? result?.contactPerson?.fleets[0]?.allIndiaPermit
          : {};
      if (newresult.individual == false && newresult.individualFleet == true)
        newresult.allIndiaPermitImage = result?.contactPerson?.fleets[0]
          ?.allIndiaPermit?.image
          ? result?.contactPerson?.fleets[0]?.allIndiaPermit?.image
          : "";
      if (newresult.individualFleet === true) newresult.individual = true;
      if (newresult.individualFleet === true)
        newresult.settledBranch = result.settledBranch;
      if (newresult.individual === true || newresult.individualFleet === true) {
        newresult.licenseExpiry = result.licenseExpiry;
        newresult.userDocVerified = result?.contactPerson?.docVerified;
        if (
          newresult.individualFleet === true &&
          result.contactPerson &&
          result.contactPerson.fleets &&
          result.contactPerson.fleets.length > 0
        ) {
          newresult.bodyType = result.contactPerson.fleets[0].bodyType;
          newresult.regNumber = result.contactPerson.fleets[0].regNumber;
          newresult.fleetType = {
            _id: result.contactPerson.fleets[0].assetSubType?.assetType?._id,
            value: result.contactPerson.fleets[0].assetSubType?.assetType?._id,
            label:
              result.contactPerson.fleets[0].assetSubType?.assetType?.assetName,
          };
          newresult.assetSubType = {
            _id: result.contactPerson.fleets[0].assetSubType?._id,
            value: result.contactPerson.fleets[0].assetSubType?._id,
            label: result.contactPerson.fleets[0].assetSubType?.name,
          };
          // newresult.contactPerson= {
          //   contact: result.contactPerson && result.contactPerson.contact,
          //   name: {
          //     fName: result.contactPerson && result.contactPerson.name.fName,
          //     lName: result.contactPerson && result.contactPerson.name.lName,
          //   },
          //   email: result.contactPerson && result.contactPerson.email,
          //   password: result.contactPerson && result.contactPerson.password,
          // },

          // newresult.fleetType = result.contactPerson.fleets[0].fleetType;
          // newresult.assetSubType = result.contactPerson.fleets[0].assetSubType?._id;
          // newresult.fleetType = result.contactPerson.fleets[0].assetSubType?.assetType?.assetName;
          // newresult.assetSubType = result.contactPerson.fleets[0].assetSubType?.name;

          newresult.modelNumber = result.contactPerson.fleets[0].modelNumber;
          // newresult.contactPerson = {
          //   panCard: result.contactPerson.panCard.status,
          //   // panCardImage: result.contactPerson.panCard.image,
          //   aggrement: result.contactPerson.aggrement.status,
          //   // aggrementImage: result.contactPerson.aggrement.image
          // };
          newresult.capacity = result.contactPerson.fleets[0].capacity;
          newresult.settledBranch = result.settledBranch;
          newresult.distanceCovered =
            result.contactPerson.fleets[0].distanceCovered;
          newresult.ownerShip = result.contactPerson.fleets[0].ownerShip;
          newresult.idealFuel = result.contactPerson.fleets[0].idealFuel;
          newresult.insuranceDate = result.contactPerson.fleets[0].insuranceDate
            ? result.contactPerson.fleets[0].insuranceDate
            : new Date();
          newresult.fitnessDate = result.contactPerson.fleets[0].fitnessDate
            ? result.contactPerson.fleets[0].fitnessDate
            : new Date();
          newresult.permitDate = result.contactPerson.fleets[0].permitDate
            ? result.contactPerson.fleets[0].permitDate
            : new Date();
          newresult.emiDate = result.contactPerson.fleets[0].emiDate
            ? result.contactPerson.fleets[0].emiDate
            : new Date();
          newresult.allowedOFDCities =
            result.contactPerson.fleets[0].allowedOFDCities;
          newresult.volume = result.contactPerson.fleets[0].volume;
          newresult.businessType = result.contactPerson.fleets[0].businessType;
          newresult.overhead = result.contactPerson.fleets[0].overhead;
          newresult.fleetId = result.contactPerson.fleets[0]._id;
          newresult.noOfSeats = result.contactPerson.fleets[0].noOfSeats;
          newresult.fuelType = result.contactPerson.fleets[0].fuelType;
        } else {
          newresult.bodyType = "";
          newresult.regNumber = "";
          newresult.fleetType = "";
          newresult.assetSubType = "";
          newresult.modelNumber = "";
          newresult.capacity = "";
          newresult.settledBranch = "";
          newresult.distanceCovered = "";
          newresult.ownerShip = "";
          newresult.idealFuel = "";
          newresult.insuranceDate = new Date();
          newresult.fitnessDate = new Date();
          newresult.permitDate = new Date();
          newresult.emiDate = new Date();
          newresult.allowedOFDCities = "";
          newresult.volume = { l: "", b: "", h: "" };
          newresult.businessType = "";
          newresult.overhead = "";
          newresult.fleetId = "";
          newresult.noOfSeats = "";
          newresult.fuelType = "";
        }
      } else {
        newresult.kind = result.kind;
        newresult.url = result.url;
        newresult.email = result.email;
      }

      await dispatch(setCompanyDoc(newresult));
      dispatch(hideLoader());
    } catch (err: any) {
      dispatch(hideLoader());
      showHttpError("Couldn't fetch company data");
    }
  }
);
