import { combineReducers } from "redux";
import {
  userSlice,
  rightSlice,
  appRightSlice,
  fleetSlice,
  sidebarBadgesSlice,
  memoSlice,
  allGeneralSettingsSlice,
  driverloginSlice,
  smsAndCallRecordsSlice,
  taskModuleSlice,
  feedbackFiltersSlice,
  branchSlice,
  listOfAllUserSlice,
  verifyStocksSlice,
  paymentGateWaySlice,
  paymentTypeListSlice,
  complaintChatListSlice,
  allPlatformUserListSlice,
  allPlatformDriverListSlice,
  customerChatSlice,
  customerTrackingSlice,
  notificationSlice,
  customerSlice,
  missingSlice,
  appActionsSlice,
  qrSlice,
  liveLocationSlice,
  userFormSlice,
  customerBookingSlice,
  newRouteSlice,
  incomeSlice,
  companySlice,
  customerErpSlice,
  expenseSlice
} from ".";
import routeReducer from "../reducers/route";
import BookingReducer from "../reducers/booking";
import { globalReducer } from "../store/global.reducer";

const rootReducer = combineReducers({
  user: userSlice,
  rights: rightSlice,
  appRights: appRightSlice,
  fleet: fleetSlice,
  sidebarBadges: sidebarBadgesSlice,
  branch: branchSlice,
  memo: memoSlice,
  company: companySlice,
  customer: customerErpSlice,
  income: incomeSlice,
  userForm : userFormSlice,
  route: routeReducer,
  newRoute: newRouteSlice,
  appActions: appActionsSlice,
  booking: BookingReducer,
  expense: expenseSlice,
  missing: missingSlice,
  customerLoginReducer: customerSlice,
  qr: qrSlice,
  customerBooking: customerBookingSlice,
  liveLocation: liveLocationSlice,
  customerChatHistory: customerChatSlice,
  customerTracking: customerTrackingSlice,
  feedbackFilter: feedbackFiltersSlice,
  taskModule: taskModuleSlice,
  driverLock: driverloginSlice,
  complaintChatList: complaintChatListSlice,
  smsAndCallRecordsData: smsAndCallRecordsSlice,
  userList: listOfAllUserSlice,
  allGeneralSettings: allGeneralSettingsSlice,
  allPlatformUsersList: allPlatformUserListSlice,
  allPlatformDriverList: allPlatformDriverListSlice,
  paymentType: paymentTypeListSlice,
  verifyStocks: verifyStocksSlice,
  paymentGateWay: paymentGateWaySlice,
  global: globalReducer,
  notification: notificationSlice,
});

export default rootReducer;
