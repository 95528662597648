import { buildQueryString, ServiceMaker } from "./index";
import API from "../axios";
import * as Yup from "yup";
import axios from "axios";
import { url } from "../actions/UserActions";

const CancelToken = axios.CancelToken;

let cancel: any;

export const editDocketService = (id: string, data: any) =>
  ServiceMaker(`booking/update/${id}`, "POST", data);

export const sendDeliverMessage = (data: any) =>
  ServiceMaker(`delivery/deliveryMessage`, "POST", data);

export const fetchDocketDetails = (data: any) =>
  ServiceMaker(`booking/viewdocket?${buildQueryString(data)}`, "GET");

export const gstinDetailsApi = (data: any) =>
  ServiceMaker(`customer/addGstConnectedCustomer`, "PATCH" , data);

export const fetchDoDelivery= (data: any) =>
  ServiceMaker(`booking/doorDelivery`, "PATCH",data);

export const fetchStrackDetailsForMsgLink = (id:string,data:any) => 
  ServiceMaker(`customerreport/sTrack/${id}?${buildQueryString(data)}`,"GET")

export const fetchStrackDetails = (id:string) =>
  ServiceMaker(`customerreport/sTrack/${id}`,"GET")

export const RequestComplain = (docketNumber: string, data: any) =>
  ServiceMaker(
    `delivery/requestTrackingNumber/${docketNumber}?${buildQueryString(data)}`,
    "GET"
  );

export const fetchTrackingDocketDetails = (data: any) => {
  if (cancel !== undefined) {
    cancel();
  }
  return axios.get(url + `/booking/fetchdocket?${buildQueryString(data)}`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
};

export const bookingDoorDelBooking = (data: any) => 
  ServiceMaker(`booking/doorDelBooking?${buildQueryString(data)}`,"GET")


export const fetchDocketBoxService = (data: any) =>
  ServiceMaker("booking/box/" + data.docketNumber, "POST", data);

export const getAcrossService = (
  fronBranch: any,
  toCity: any,
  isDirectUrl: any
) =>
  ServiceMaker(
    `city/${isDirectUrl ? "openapi/" : ""}across/${fronBranch}/${toCity}`,
    "GET"
  );

export const specificDeliveryVerifyService = (data: {
  docket: string;
  PIN: number;
}) => ServiceMaker("booking/specific_del", "POST", data);
// export const getBillDetails = (data) => ServiceMaker('ewaybill/india/details', 'POST', data)

export const getBillDetails = (data: any) => {
  return new Promise((resolve, reject) => {
    API.post("ewaybill/india/details", data)
      .then((res) => resolve(res.data.response.message))
      .catch((err) => reject(err));
  });
};

export const cancelDocketService = (data: {
  docket: string;
  entity: string;
  sub: string;
  cancelReason?: string;
  newDocket?: string;
  otp?: number;
  reciverName?: string;
  reciverContact?: number;
}) => ServiceMaker("booking/cancel/request", "POST", data);

export const cancelDocketByCustomerService = (data: {
  docket: string;
  entity: string;
  sub: string;
  remark?: string;
  customerContact?: number;
  otp?: number;
}) => ServiceMaker("booking/cancel/byCustomer", "POST", data);

export const resendSmsService = (id: string) =>
  ServiceMaker("message/resend", "POST", { docket: id });

export const getDocketAmountService = (docketNo: string) =>
  ServiceMaker(`booking/amount/${docketNo}`, "GET");

export const makePayment = (data: any) => ServiceMaker("delivery/makePayment", "PATCH", data);

export const deliverDocket = (data: any) =>
  ServiceMaker("delivery/deliverDocket", "PATCH", data);

export const getPreloadReport = (data: {
  reportNumber?: string;
  loginType: string;
}) => ServiceMaker("report/preload", "POST", data);

export const createBoxService = (data: any) => {
  const schema = Yup.object().shape({
    // company: Yup.string().required(),
    sub: Yup.string().required(),
    entity: Yup.string(),
    // user: Yup.string().required(),
    packages: Yup.array(Yup.string()),
  });

  if (schema.isValidSync(data)) {
    return ServiceMaker("package/box/create", "POST", data);
  } else {
    return false;
  }
};

export const getBoxesAt = (data: { sub: string; entity: string }) =>
  ServiceMaker("package/box/filter", "POST", data);

export const addToBoxService = (data: any) => {
  const schema = Yup.object().shape({
    packages: Yup.array().required(),
    boxNumber: Yup.string()
      .matches(/[A-Z]{3,5}[0-9]{10}/)
      .required(),
  });


  if (schema.isValidSync(data)) {
    return ServiceMaker("package/box/assign/package", "POST", data);
  } else {
    return false;
  }
};

export const removeFromBoxService = (data: { packages: [string] }) => {
  const schema = Yup.object().shape({
    packages: Yup.array().required(),
  });
  if (schema.isValidSync(data)) {
    return ServiceMaker("package/box/remove/package", "POST", data);
  } else {
    return false;
  }
};

export const loadRequestService = async (data: {
  packages: [string];
  sub: string;
  entity: string;
  regNumber: string;
  driverContact: number;
}) => {
  const schema = Yup.object({
    packages: Yup.array().required(),
    sub: Yup.string()
      .oneOf(["B", "F"])
      .required(),
    entity: Yup.string()
      .matches(/[0-9a-z]{24}/)
      .required(),
    regNumber: Yup.string().required(),
  });
  await schema.validate(data);
  return ServiceMaker("package/loading/request", "POST", data);
};

export const loadUncheckedService = (data: any) => {
  return ServiceMaker("package/stillYetBranch", "PATCH", data);
};

export const loadApproveService = async (data: {
  sub: string;
  entity: string;
  reportNumber: string;
}) => {
  const schema = Yup.object({
    reportNumber: Yup.string().required(),
    sub: Yup.string().oneOf(["B", "F"]),
    entity: Yup.string()
      .matches(/[0-9a-z]{24}/)
      .required(),
  });
  await schema.validate(data);
  return ServiceMaker("package/loading/approve", "POST", data);
};

export const loadRejectService = async (data: { reportNumber: string }) =>
  ServiceMaker("package/loading/reject", "POST", data);

export const addNewConnectedcontact = (data:any) =>
  ServiceMaker("customer/connectReq", "POST", data);

export const updateNewDoordelivary = (data:any) =>
  ServiceMaker("booking/updateDoorDelivery", "POST", data);
  
export const listNewConnectedCustomer = (data:any) =>
  ServiceMaker(`customer/connectReqList?${buildQueryString(data)}`, "GET");



export const getdataFromLatLng = (data:any) =>
  ServiceMaker(`googleMap/getGeocode?${buildQueryString(data)}`, "GET");

export const newConnectedCustomerAction = (id:string,data:any) =>
  ServiceMaker(`customer/connectReq/${id}`, "PATCH", data);

export const removeConnectedCustomer = (data:any) =>
  ServiceMaker("customer/removeConnectedCustomer", "PATCH", data);
  
export const getFixedConnectedCustomers = (data:any) =>
  ServiceMaker(`customer/connectCustomerList/list?${buildQueryString(data)}`, "GET");
  
export const makeparcelTransferRequest = (data:any) =>
  ServiceMaker('operation/createParcelTransferRequest',"POST" , data);
  
export const getParcelTransferRequestList  = (data?:any)=>
  ServiceMaker(`operation/listParcelTransferRequest?${buildQueryString(data)}`,"GET");
  
export const processParcelTransferRequest = (data:any) =>
  ServiceMaker("operation/processParcelTransferRequest", "PATCH", data);
  
export const processGstCustodyRequest = (data:any) =>
  ServiceMaker("operation/processFleetGstCustodyReq", "POST", data);

export const getGstCustodyReqList  = (data?:any)=>
  ServiceMaker(`operation/listFleetGstCustodyReq?${buildQueryString(data)}`,"GET");
  
export const listPackageUpdateRequest  = (data?:any)=>
  ServiceMaker(`operation/listPackageUpdateRequest?${buildQueryString(data)}`,"GET");
 
export const createPackageUpdateRequest = (data:any) =>
  ServiceMaker("/operation/createPackageUpdateRequest","POST",data)
  
export const processPackageUpdateRequest = (data:any) =>
  ServiceMaker("/operation/processPackageUpdateRequest","POST",data)

export const doorDeliveryMakePayment = (data:any) => ServiceMaker("delivery/doorDeliveryMakePayment", "POST", data)

export const setDoorDelivery = (data:any) => ServiceMaker("booking/doorDelivery", "PATCH", data)

export const listCustomerBookingRequest = (data?:any) =>
  ServiceMaker(`/operation/listCustomerBookingRequest?${buildQueryString(data)}`,"GET")
    
// export const listCustomerBookingRequest = (data?:any) =>
//   ServiceMaker(`/operation/listCustomerBookingRequest`,"POST" , data)

export const processCustomerBookingRequest = (data:any) =>
  ServiceMaker("/operation/processCustomerBookingRequest","POST",data)
  
export const pendingCountsreqTypeWise = (data?:any) =>
 ServiceMaker(`operation/pendingCounts/reqTypeWise?${buildQueryString(data)}`,"GET")

export const addQrReprintPackageLog = (data?:any) =>
  ServiceMaker('package/addQrReprintPackageLog', "PATCH", data)

export const docketQrPrint = (data?: { docketNumber: string, entity: string }) =>
  ServiceMaker('booking/qr/print', "POST", data)