import axios from "axios";
import { getAcrossService } from "../services/operations";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_DOCKET,
  SET_ACROSS,
  RESET_EDIT,
} from "../constants/booking";
import { baseURL } from "../axios";
import { failed, showMessage } from "../utils/message";
import { fetchBranchwiseBookingCounters } from "../services/booking";
// import getOperationRequestCount from "../services/operationsCount";
import { Dispatch } from "redux";
import { getMaterialList } from "../services/intraCityRate";
import { RootState } from "../redux/store";

const url = baseURL;
// const url = 'http://192.168.1.142:3000/api'

export { url };
export * from "./booking";
export * from "./auth";

//overall entities : material packing
export const getMaterial = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let reduxData = getState();
      if (reduxData?.booking?.material?.length <= 0) {
        let data = await getMaterialList();
        // if (response.data.status != 1) throw "Could not fetch materials";
        dispatch({
          type: "SET_MATERIAL",
          payload: data,
        });
      }
      else {
        dispatch({
          type: "SET_MATERIAL",
          payload: reduxData?.booking?.material,
        });
      }
    } catch (err : any) { }
  };
};

export const getPackingType = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let reduxData = getState();
      if (reduxData?.booking?.packingType?.length === undefined) {
        let response = await axios.get(url + "/packing/type/list");
        if (response.data.status != 1) {
          throw "Could not fetch packages";
        }
        dispatch({
          type: "SET_PACKING_TYPE",
          payload: response.data.response,
        });
      }
      else {
        dispatch({
          type: "SET_PACKING_TYPE",
          payload: reduxData?.booking?.packingType,
        });
      }
    } catch (err : any) { }
  };
};

export const setCities = () => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: url + "/city/list",
      });
      dispatch({
        type: "SET_CITIES",
        payload: res.data.response,
      });
    } catch (error) { }
  };
};

export const resetEdit = (data?: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    dispatch({ type: RESET_EDIT, payload: { ...data, loggedInUser: getState().user } });
  }
};

export const setDocket = (payload?: any, func?: any, args?: any) => {
  let deliveryFlag = /delivery/.test(args);
  return async (dispatch: any, getState: () => RootState) => {
    try {
      let { packages, isDirectUrl } = payload;

      if (packages.length < 1) {
        // showErrorMessage("this docket has no packages.");
        showMessage("this docket has no packages.", failed);
        return;
      }
      if ((!getState().user as any)?.allCities) {
        await dispatch(setCities());
      }
      let { originBranch, destCity } = packages[0];
      if (
        typeof originBranch == "object" &&
        originBranch.hasOwnProperty("_id")
      ) {
        originBranch = originBranch._id;
      }
      if (typeof destCity == "object" && destCity.hasOwnProperty("_id")) {
        destCity = destCity._id;
      }
      let across = await getAcrossService(
        originBranch,
        payload.docket.isFedexBooking
          ? payload.docket.destBranch.address.pincode
          : destCity,
        isDirectUrl
      );

      await dispatch({ type: SET_ACROSS, payload: across });
      const { allcities } = getState().user;
      dispatch({
        type: SET_DOCKET,
        payload: {
          ...payload,
          // routes,
          delivery: deliveryFlag,
          allcities,
        },
      });
      if (typeof func === "function") {
        func(args);
      }
    } catch (err : any) {
      return err
    }
  };
};

export const showLoader = () => ({ type: SHOW_LOADER });

export const hideLoader = () => ({ type: HIDE_LOADER });

export const updateBookingCounters = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const user = getState().user;
      if (user.loginType) {
        const sub = user.loginType;
        const entity =
          user.loginType === "B" ? user.opBranch._id : user.opFleet._id;
        const result = await fetchBranchwiseBookingCounters({ sub, entity });
        dispatch({
          type: "CHANGE_BOOKING_COUNTERS",
          payload: result,
        });
      }
    } catch (err : any) { }
  };
};

