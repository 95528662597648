import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHttpError } from "../../../utils/message";
import {
  getNotificationService,
  getPendingActionNotificationCount,
} from "../../../services/user";
import { getUnapprovedMemosService } from "../../../services/memo";
import { RootState } from "../../store";
import { setNotificationLoading } from "./memoSlice";

export const notificationService = createAsyncThunk(
  "memo/notification",
  async ({ user }: { user?: string }, { dispatch }) => {
    try {
      const initialOffset = 0;
      const fetchLimit = 20;
      const newNoti = await getNotificationService({
        offset: initialOffset,
        limit: fetchLimit,
      });
      const pendingActinoNotificationCount =
        await getPendingActionNotificationCount();
      const memoResult = await getUnapprovedMemosService({ user });
      if (newNoti) {
        return {
          notifications: newNoti,
          notificationLoadMore: newNoti.length === fetchLimit,
          offset: initialOffset,
          pendingActinoNotificationCount: pendingActinoNotificationCount,
          notifyMemos: memoResult,
        };
      }
    } catch (error) {
      showHttpError(error);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const loadMoreNotifications = createAsyncThunk(
  "memo/loadMoreNotifications",
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const notifications = state.memo?.notifications;
      const offset = (notifications && notifications.length) || 0;
      const newNoti = await getNotificationService({ offset, limit: 20 });
      dispatch(setNotificationLoading(true));
      const data1 = await updatePendingActionNotificationCountFun();
      const notificationPayload = {
        notifications: newNoti,
        notificationLoadMore: newNoti.length === 20,
        offset,
        updatePendingActionNotificationCountFunction: data1,
      };
      return notificationPayload;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch(setNotificationLoading(false));
    }
  }
);

export const updatePendingActionNotificationCountFun = () => async () => {
  try {
    const response = await getPendingActionNotificationCount();
    return response;
  } catch (error) {
    showHttpError(error);
  }
};

export const updatePendingActionNotificationCountAction = createAsyncThunk(
  "memo/updatePendingActionNotificationCountAction",
  async () => {
    try {
      const response = await getPendingActionNotificationCount();
      return response;
    } catch (error) {
      showHttpError(error);
    }
  }
);
