import { createSlice } from "@reduxjs/toolkit";
import { setAllPlatformUsersList } from "./api";

const initialState: { label: string; value: any }[] = []; // Explicitly define type

const allPlatformUserListSlice = createSlice({
    name: "set/allPlatformUsersList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setAllPlatformUsersList.fulfilled, (state, action) => {
            console.log(`Zndc`, state, action);
            return action?.payload?.allPlatformUserListData ?? []; // Ensure it always returns an array
        });
    },
});

export default allPlatformUserListSlice.reducer;
