import { createSlice } from "@reduxjs/toolkit";
import { SubTask, TaskModuleInitState } from "./taskModule.type";
import { setTaskListFromMainTask } from "./api";

const initialState: TaskModuleInitState = {
    title: "",
    description: "",
    dueDate: new Date(),
    estimatedHour: 0,
    estimatedMin: 0,
    estimatedOutput: null,
    user: null,
    status: "",
    _id: "",
    isFromTaskList: 0,
    subTasks: [{
        id: 0,
        title: "",
        description: "",
        scheduledDate: new Date(),
        estimatedHour: 0,
        estimatedMin: 0,
        estimatedCost: 0,
        estimatedOutput: null,
        actualHours: 0,
        actualMin: 0,
        actualCost: 0,
        actualOutput: null,
        user: null,
        iSubmited: false,
        actionType: "",
        lastStarted: "",
        isWellDiscussed: false,
        urgency: "",
        importance: "",
        isDeployable: null,
        isDeployed: null,
        isReviewed: null,
        isRemarkable: null,
        isServiceable: null,
        isChallenging: null,
        upSkilled: null,
        enjoyed: null
    }]
}

const taskModuleSlice = createSlice({
    name: "update/taskModule",
    initialState,
    reducers: {
        // done
        clearTaskModule: (state) => {
            state.subTasks = [{
                id: 0,
                title: "",
                description: "",
                scheduledDate: new Date(),
                estimatedHour: 0,
                estimatedMin: 0,
                estimatedCost: 0,
                estimatedOutput: null,
                actualHours: 0,
                actualMin: 0,
                actualCost: 0,
                actualOutput: null,
                user: null,
                iSubmited: false,
                _id: "",
                actionType: "",
                lastStarted: "",
                isWellDiscussed: false,
                urgency: "",
                importance: "",
                isDeployable: null,
                isReviewed: null,
                isRemarkable: null,
                isServiceable: null,
                isChallenging: null,
                upSkilled: null,
                enjoyed: null,
                isDeployed: null,
            }]
        },
        // done
        addSubTask: (
            state,
            action
        ) => {
            console.log(`jasdcvmnSdlvsdv`, `jkzsdfvnxdf`, action)
            state.subTasks.push({
                id: state.subTasks.length,
                title: "",
                description: "",
                scheduledDate: new Date(),
                estimatedHour: 0,
                estimatedMin: 0,
                estimatedCost: 0,
                estimatedOutput: null,
                actualHours: 0,
                actualMin: 0,
                actualCost: 0,
                actualOutput: null,
                user: action.payload ? action.payload : null,
                iSubmited: false,
                actionType: "",
                lastStarted: "",
                isWellDiscussed: false,
                urgency: "",
                importance: "",
                isDeployable: null,
                isReviewed: null,
                isRemarkable: null,
                isServiceable: null,
                isChallenging: null,
                upSkilled: null,
                enjoyed: null,
                isDeployed: null,
            });
        },
        // done
        addDuplicateSubTask: (
            state,
            action
        ) => {
            console.log(`jasdcvmnSdlvsdv`, `jkzsdfvnxdf 0.2`, action.payload)
            let { title, description, estimatedOutput, userDetails, estimatedHour, estimatedMin,isWellDiscussed, urgency, importance } = action.payload;
            state.subTasks.push({
                id: state.subTasks.length,
                title: title ?? "",
                description: description ?? "",
                scheduledDate: new Date(),
                estimatedHour: estimatedHour ?? 0,
                estimatedMin: estimatedMin ?? 0,
                estimatedCost: 0,
                estimatedOutput: estimatedOutput ?? null,
                actualHours: 0,
                actualMin: 0,
                actualCost: 0,
                actualOutput: null,
                user: userDetails ?? null,
                iSubmited: false,
                actionType: "",
                lastStarted: "",
                isWellDiscussed: isWellDiscussed ?? false,
                urgency: urgency ?? "",
                importance: importance ?? "",
                isDeployable: null,
                isReviewed: null,
                isRemarkable: null,
                isServiceable: null,
                isChallenging: null,
                upSkilled: null,
                enjoyed: null,
                isDeployed: null,
            });
        },
        // done
        setSubTask: (
            state,
            action
        ) => {
            console.log(`SDDcsddcsv`, state, action.payload);
            const { what, val, index } = action.payload;
            if (state.subTasks[index]) {
                state.subTasks[index] = setDeep(state.subTasks[index], what, val);
            }
        },
        // done
        removeSubtask: (state) => {
            state.subTasks.pop();
        },
        // done
        setupTaskModule: (
            state,
            action
        ) => {
            const { what, val } = action.payload;
            (state as any)[what] = val;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setTaskListFromMainTask.fulfilled, (state, action) => {
            const response = action.payload;

            if (!response || response.length === 0) return;

            const task = response[0]?.task;
            const subTasks = response[0]?.subTasks || [];

            state.title = task?.title || "";
            state.status = task?.status || "";
            state.isFromTaskList = 1;
            state._id = task?._id || "";

            state.subTasks = subTasks.map((eleForSubtask: SubTask, index: number) => ({
                id: index,
                title: eleForSubtask?.title || "",
                description: eleForSubtask?.description || "",
                scheduledDate: eleForSubtask?.scheduledDate || new Date(),
                estimatedHour: eleForSubtask?.estimatedHour ? +eleForSubtask.estimatedHour : 0,
                estimatedMin: eleForSubtask?.estimatedMin ? +eleForSubtask.estimatedMin : 0,
                estimatedCost: eleForSubtask?.estimatedCost || 0,
                estimatedOutput: eleForSubtask?.estimatedOutput || null,
                actualHours: eleForSubtask?.actualHours || 0,
                actualMin: eleForSubtask?.actualMin ? +eleForSubtask.actualMin : 0,
                actualCost: eleForSubtask?.actualCost || 0,
                actualOutput: eleForSubtask?.actualOutput || null,
                user: eleForSubtask?.user
                    ? {
                        label: `${eleForSubtask.user.name.fName} ${eleForSubtask.user.name.lName}`,
                        value: eleForSubtask.user._id,
                    }
                    : null,
                iSubmited: true,
                _id: eleForSubtask?._id || "",
                actionType: eleForSubtask?.status || "",
                lastStarted: eleForSubtask?.lastStarted || "",
                isWellDiscussed: eleForSubtask?.isWellDiscussed || false,
                urgency: eleForSubtask?.urgency || "",
                importance: eleForSubtask?.importance || "",
                isDeployable: eleForSubtask?.isDeployable,
                isReviewed: eleForSubtask?.isReviewed,
                isRemarkable: eleForSubtask?.isRemarkable,
                isServiceable: eleForSubtask?.isServiceable,
                isChallenging: eleForSubtask.isChallenging,
                upSkilled: eleForSubtask?.upSkilled,
                enjoyed: eleForSubtask?.enjoyed,
                isDeployed: eleForSubtask?.isDeployed,
            }));
        });
    },
});

function setDeep(obj: any, path: string, value: any): any {
    const keys = path.split(".");
    let temp = obj;
    for (let i = 0; i < keys.length - 1; i++) {
        temp = temp[keys[i]] = temp[keys[i]] || {};
    }
    temp[keys[keys.length - 1]] = value;
    return obj;
}

export const { clearTaskModule, addSubTask, setSubTask, removeSubtask, setupTaskModule, addDuplicateSubTask } = taskModuleSlice.actions;

export default taskModuleSlice.reducer;