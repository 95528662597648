import { buildQueryString, ServiceMaker } from ".";

export const listIssues = (data : any) =>
    ServiceMaker(`internalCommunication/list?${buildQueryString(data)}`, "GET");

export const listIssueCounts = (data : any) =>
    ServiceMaker(`internalCommunication/count?${buildQueryString(data)}`, "GET");

export const raiseIssue = (data : any) =>
    ServiceMaker(`internalCommunication/init`, "POST", data);
    
export const allIssueCounts = () =>
    ServiceMaker(`internalCommunication/typeWise/count`, "GET");
    
export const addRemarksIssues = (id : string ,data : any) =>
    ServiceMaker(`internalCommunication/process/updates/${id}`, "PATCH" , data);

export const changeConcernedPersonFunc = (id : string ,data : any) =>
    ServiceMaker(`internalCommunication/assign/concernPerson/${id}`, "PATCH" , data);

export const adminRemarksAction = (id : string ,data : any) =>
    ServiceMaker(`internalCommunication/process/updates/${id}`, "PATCH" , data);
    