import { createAsyncThunk } from "@reduxjs/toolkit";
import showMessage, { failed } from "../../../utils/message";
import { RootState } from "../../store";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import { wholeNewRouteValidation } from "../../../reducers/schemas/newRoute";
import { createNewRoutes, updateNewRoute } from "../../../services/newRoute";

export const newRouteSetRouteAction = createAsyncThunk(
    "newRouteSetRouteAction",
    async (data: any, { getState }) => {
        const state = getState() as RootState;
        const { route, companyList, companyWiseBranchList } = data;
        try {
            let sortedRoutes: any = route?.stops && route?.stops?.sort((a: any, b: any) => { return a["index"] - b["index"] });
            const newRoute = {
                ...route,
                routeName: { label: route?.routeName, value: route._id },
                modeOfTransport: {
                    label: route.modeOfTransport,
                    value: route.modeOfTransport,
                },
                stops: sortedRoutes.map((dest: any) => {
                    const company = companyList.find(
                        ({ value }: any) => value == dest.company
                    );
                    const city = state?.user.allcities.find(
                        (city: any) => city._id === dest?.branch?.address?.city?._id
                    );
                    const companyId = dest.company || state?.user?.company?._id;
                    const branch = (companyWiseBranchList?.[companyId] || []).find(
                        ({ value }: any) => value === dest.branch._id
                    );
                    return {
                        ...dest,
                        company: company
                            ? company
                            : {
                                label: state?.user.company.companyName,
                                value: state?.user.company._id,
                            },
                        city: city
                            ? { label: city.name, value: city._id }
                            : { label: "no city", value: "any" },
                        branch: branch
                            ? { ...branch }
                            : { label: "no branch", value: "any", city: "any" },
                    };
                }),
            };
            return newRoute;
        } catch (error: any) {
            showMessage(error || "Couldn't list Missing Package", failed);
            return [];
        }
    }
);

export const newRouteCreateRouteAction = createAsyncThunk(
    "newRouteCreateRouteAction",
    async (_: any, { dispatch, getState }) => {
        const state = getState() as RootState;
        try {
            dispatch(showLoader());
            const route = {
                active: state.newRoute.active,
                modeOfTransport: state.newRoute.modeOfTransport.value,
                id: 0,
                stops: state
                    .newRoute
                    .stops.map((dest: any, index: any) => ({
                        id: 1,
                        index: index + 1,
                        company: dest?.company?.value,
                        city: dest?.city?.value,
                        branch: dest?.branch?.value,
                        noOfDays: dest?.noOfDays,
                        loading: dest?.loading,
                        unloading: dest?.unloading,
                        arrivalTime: dest?.arrivalTime,
                        departureTime: dest?.departureTime,
                    })),
                serviceSubType: state.newRoute.serviceSubType?.value,
            };
            wholeNewRouteValidation.validateSync(route);
            await createNewRoutes(route);
            dispatch(hideLoader());
            showMessage("Route created successfully!");
            return true;
        } catch (err: any) {
            dispatch(hideLoader());
            showMessage(err.message || "Route Create failed!", "salmon");
        }
    }
);

export const newRouteUpdateRouteAction = createAsyncThunk(
    "newRouteUpdateRouteAction",
    async (_: any, { dispatch, getState }) => {
        const state = getState() as RootState;
        try {
            dispatch(showLoader());
            const route: any = {
                active: state.newRoute.active,
                modeOfTransport: state.newRoute.modeOfTransport.value,
                id: 0,
                stops: state
                    .newRoute
                    .stops.map((dest: any, index: any) => ({
                        id: 1,
                        index: index,
                        company: dest?.company?.value,
                        city: dest?.city?.value,
                        branch: dest?.branch?.value,
                        noOfDays: dest?.noOfDays,
                        loading: dest?.loading,
                        unloading: dest?.unloading,
                        arrivalTime: dest?.arrivalTime,
                        departureTime: dest?.departureTime,
                    })),
            };
            wholeNewRouteValidation.validateSync(route);
            let response = await updateNewRoute(state.newRoute._id, route);
            dispatch(hideLoader());
            showMessage("Route Updated!");
            return response;
        } catch (err: any) {
            dispatch(hideLoader());
            showMessage(err.message || "Route update failed!", "salmon");
        }
    }
);
