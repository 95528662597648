import { useAppDispatch } from "../../../hooks/useAppDispatch";
import showMessage from "../../../utils/message";
import { RootState } from "../../store";
import { setLatitude, setLongitude } from "./userSlice";

export const setupGeolocation = () => {
  const dispatch = useAppDispatch();
  const geo = navigator.geolocation;
  if (!geo) {
    showMessage("Geolocation is not supported", "salmon");
    return null;
  }

  const geo_options = {
    enableHighAccuracy: true,
    timeout: 5000000,
  };

  return geo.watchPosition(
    (position) => {
      const { latitude, longitude, accuracy } = position.coords;
      const dateTime = new Date().toLocaleString();
      
      localStorage.setItem("latitude", String(latitude));
      localStorage.setItem("longitude", String(longitude));
      localStorage.setItem("accuracy", String(accuracy));
      localStorage.setItem("dateTime", dateTime);
      
      dispatch(setLatitude(latitude));
      dispatch(setLongitude(longitude));
    },
    () => {},
    geo_options
  );
};

// Location update interval setup
export const setupLocationInterval = (
  getState: () => RootState,
  socket: any
) => {
  return setInterval(() => {
    const latitude = localStorage.getItem("latitude");
    const longitude = localStorage.getItem("longitude");
    const accuracy = localStorage.getItem("accuracy");
    const {
      user: { opBranch, loginType, opFleet, company },
    } = getState();

    const branch_id = loginType === "B" ? opBranch._id : "";
    const fleet_id = loginType === "F" ? opFleet._id : "";
    const entity = loginType === "B" ? branch_id : fleet_id;

    if (socket && latitude && longitude && entity) {
      const token = sessionStorage.getItem("kabra:token");
      socket.emit("updateLocation", {
        latitude,
        longitude,
        accuracy,
        token,
        sub: loginType,
        entity,
        company: company._id,
      });
    }
  }, 60000);
};

export const makeDocketMapRegNumWise = (arr: any) => {
  const docketMap: any = {};
  // const fleetSet = new Set(fleets.map((f) => f.regNumber));
  arr.forEach((pack: any) => {
    const curr = pack.regNumber;
    // if (fleetSet.has(curr)) {
    if (!(curr in docketMap)) {
      docketMap[curr] = [pack];
    } else {
      docketMap[curr].push(pack);
    }
    // }
  });
  const newDocketMap: any = {};
  Object.keys(docketMap).forEach((d) => {
    newDocketMap[d] = {};
    let currDocs = docketMap[d];
    for (let i = 0; i < currDocs.length; i++) {
      const currDocket = currDocs[i].docket.docketNumber;
      if (!(currDocket in newDocketMap[d])) {
        newDocketMap[d][currDocket] = [{ ...currDocs[i] }];
      } else {
        newDocketMap[d][currDocket].push({ ...currDocs[i] });
      }
    }
  });

  return newDocketMap;
};
