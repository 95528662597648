import { createSlice } from "@reduxjs/toolkit";
import { qrType } from "./qr.type";

const initialState: qrType = {
  logs: [],
  dialog: false,
  caches: {},
};

const qrSlice = createSlice({
  name: "qr",
  initialState,
  reducers: {
    setScanLogs: (state, action) => {
      let logs = [...state.logs];
      if (logs.length === 10) {
        logs.shift();
        logs.push(action.payload);
      } else {
        logs.push(action.payload);
      }
      state.logs = logs;
    },
    setQrDialog: (state, action) => {
      state.dialog = action.payload;
    },
    setQrScanCaches: (state, action) => {
      if (state.caches[action?.payload]) {
        state.caches = {
          ...state.caches,
          [action?.payload]: state.caches[action?.payload] + 1,
        };
      } else {
        state.caches = {
          ...state.caches,
          [action?.payload]: 1,
        };
      }
    },
  },
});

export const { setScanLogs, setQrDialog, setQrScanCaches } = qrSlice.actions;

export default qrSlice.reducer;
