import API from "../axios";
import { ServiceMaker, buildQueryString } from "./index";
import { isArrayCheck } from "../containers/generics/CheckArray";
import { showHttpError } from "../utils/message";

export const getExpenseList = (data: any) => {
  return new Promise((resolve, reject) => {
    API.post("expense/list", data)
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err.response));
  });
};

export const expenseSubmitHandler = (data: any) =>
  ServiceMaker("expense/create", "POST", data);

export const assignBranchService = (data: {
  userId: string;
  branches: [string];
}) => ServiceMaker("user/branch/assign", "POST", data);
export const handelncentiveCount = (data: any) =>
  ServiceMaker(`incentive/userIncentive?${buildQueryString(data)}`, "GET");
export const handelCount = (data: any) =>
  ServiceMaker(`handling/count?${buildQueryString(data)}`, "GET");

export const handelCharge = (data: any) =>
  ServiceMaker("handling/charge", "POST", data);

export const expenseEditHandler = (data: any) =>
  ServiceMaker("expense/update", "POST", data);

export const fixedExpenseSubmitHandler = (data: any) => {
  return new Promise((resolve, reject) => {
    API.post("expense/fix/create", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response));
  });
};

export const incurExpenseService = (data: any) =>
  ServiceMaker("expense/add", "POST", data);

export const fetchRouteForExpense = () => ServiceMaker("route/list", "GET");

export const deleteExpense = (id: number) =>
  ServiceMaker("expense/type/delete", "POST", { id });

export const deleteIncurExpense = (data: any) => {
  return new Promise((resolve, reject) => {
    API.post("/expense/delete", data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const editIncurExpense = (data: any) =>
  ServiceMaker("expense/edit", "POST", data);
// {
//     return new Promise((resolve, reject) => {
//         API.post('expense/edit', data)
//             .then(res => resolve(res.data.response))
//             .catch(err => reject(err && err.response ? err.response.data.response : err))
//     })
// }

export const fetchExpenseRecordById = (data: any) => {
  return new Promise((resolve, reject) => {
    API.post("expense/byId", data)
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err));
  });
};

export const deleteChargeExpense = (handlingId: any) =>
  ServiceMaker(`handling/charge/${handlingId}`, "DELETE");

export const editChargeExpense = (handlingId: any, data: any) =>
  ServiceMaker(`handling/charge/${handlingId}`, "PATCH", data);

export const getFixedExpenseTypeById = (data: any) => {
  return new Promise((resolve, reject) => {
    API.post("expense/fix_expense/get", data)
      .then((res) => {
        if (res.data.status != 1) {
          throw "error while fetching fixed expenses";
        }
        let { fixedExpenses, entities } = res.data.response;
        let entityMap = new Map();
        fixedExpenses = fixedExpenses.map((f: any) => {
          let uniqueId = "";
          try {
            switch (f.entity) {
              case "B": {
                let entity = entities.find(
                  (e: { _id: string }) => e._id == f.entity_id
                );
                uniqueId = entity.branchName;
                break;
              }
              case "A": {
                let entity = entities.find(
                  (e: { _id: string }) => e._id == f.entity_id
                );
                uniqueId = entity.uniqueId;
                break;
              }
              case "U": {
                let entity = entities.find(
                  (e: { _id: string }) => e._id == f.entity_id
                );
                uniqueId = entity.name.fName + " " + entity.name.lName;
                break;
              }
              case "G": {
                uniqueId = "General";
                break;
              }
              default: {
                uniqueId = "";
                break;
              }
            }
          } catch (err : any) {
            showHttpError(err);
          }
          return { ...f, uniqueId };
        });
        if (fixedExpenses && isArrayCheck(fixedExpenses)) {
          entityMap = new Map(fixedExpenses.map((f: any) => [f.entity_id, f]));
        }
        if (isArrayCheck(entities)) {
          entities = entities.map((e: any) => ({
            ...entityMap.get(e._id),
            ...e,
          }));
        }
        resolve({ entities, fixedExpenses });
      })
      .catch((err) => reject(err));
  });
};

export const getFixedExpenseLimit = (ref: any, date: any, sub: string) => {
  return new Promise((resolve, reject) => {
    API.get(
      `expense/fix_expense/limit/?expenseType=${ref}&sub_val=${sub}&start_date=${date}`
    )
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err && err.data ? err.data.response : err));
  });
};
export const editFixedExpenseType = (data: any) => {
  return new Promise((resolve, reject) => {
    API.post("/expense/fix_expense/edit", data)
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err.response));
  });
};
export const voucherImageUploadService = (data: any) => {
  return new Promise((resolve, reject) => {
    API.post("expense/voucher/image/upload", data)
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err.response));
  });
};

export const deleteFixedExpenseType = (id: any) => {
  return new Promise((resolve, reject) => {
    API.post("expense/fix_expense/delete", { id: id })
      .then((res) => resolve(res.data.response.response))
      .catch((err) => reject(err.response));
  });
};
export const createFuelExpense = (data: any) =>
  ServiceMaker("fuel/create", "POST", data);

export const editFuelExpense = (data: any) =>
  ServiceMaker("fuel/edit", "POST", data);

export const deleteFuelExpense = (
  expenseId: string,
  uid: string,
  fuelId: string,
  company: any
) => ServiceMaker("fuel/delete/" + fuelId, "POST", { expenseId, uid, company });

export const getPastExpenses = (
  user: any,
  entiy: string,
  loginType: string,
  page: number,
  disallowedVoucherImageOnly: any,
  pendingVoucher?:any,
  pendingVerification?:any,
  pendingApproval?:any,
  startDate?:any,
  endDate?:any
) => {
  console.log(`JHHKZDFBFVDFV 0.1` , startDate , `Xfvzdfv` , endDate);
  let url = "expense/past/?";
  let query = ``
  if(typeof pendingVoucher === 'boolean') query += `&pendingVoucher=${pendingVoucher}`
  if(pendingVerification) query += `&pendingVerification=${pendingVerification}`
  if(pendingApproval) query += `&pendingApproval=${pendingApproval}`
  if(startDate) query += `&startDate=${startDate.toISOString()}`
  if(endDate) query += `&endDate=${endDate.toISOString()}`
  console.log(`JHHKZDFBFVDFV 0.1` , startDate , `Xfvzdfv` , endDate, query);
  if (loginType == "F") {
    if(query != ``){
      return ServiceMaker(
        `${url}user=${user}&fleet=${entiy}&disallowedVoucherImageOnly=${disallowedVoucherImageOnly}&page=${page}${query}`,
        "GET"
      );
    }
    return ServiceMaker(
      `${url}user=${user}&fleet=${entiy}&disallowedVoucherImageOnly=${disallowedVoucherImageOnly}&page=${page}`,
      "GET"
    );
  } else {
    if(query != ``){
      return ServiceMaker(
        `${url}user=${user}&branch=${entiy}&disallowedVoucherImageOnly=${
          disallowedVoucherImageOnly === undefined
            ? false
            : disallowedVoucherImageOnly
        }&page=${page}${query}`,
        "GET"
      );
    }
    return ServiceMaker(
      `${url}user=${user}&branch=${entiy}&disallowedVoucherImageOnly=${
        disallowedVoucherImageOnly === undefined
          ? false
          : disallowedVoucherImageOnly
      }&page=${page}`,
      "GET"
    );
  }
};

export const disallowExpenseService = (id: any) => {
  let url = "expense/disallow";
  return ServiceMaker(url, "POST", { id });
};
export const disallowVoucher = (id: string) => {
  let url = "verification/disallowVoucherImage";
  return ServiceMaker(url, "PATCH", { id });
};

export const verifyExpenseService = (id: any) => {
  let url = "expense/verify";
  return ServiceMaker(url, "POST", { id });
};

export const fetchIncentiveAmount = () =>
  ServiceMaker("expense/fetch/incentive", "GET");


export const getExpenseRecordsForExpenseType = (data : any) => 
   ServiceMaker(`expense/getExpenseRecordsForExpenseType?${buildQueryString(data)}`, "GET");

export const setAutoExpense = (data: any) =>
  ServiceMaker("handling/autoExpense", "POST", data);

export const editAutoExpense = (data: any, id: any) =>
  ServiceMaker(`handling/autoExpense/${id}`, "PATCH", data);

export const deleteAutoExpense = (id: any) =>
  ServiceMaker(`handling/autoExpense/${id}`, "DELETE");

export const AutoIncentiveExpense = (data: any) =>
  ServiceMaker("incentive/userIncentiveExpense", "POST", data);

export const editAutoIncentive = (data: any, id: string) =>
  ServiceMaker(`incentive/userIncentiveExpense/${id}`, "PATCH", data);

export const deleteAutoIncentive = (id: string) =>
  ServiceMaker(`incentive/userIncentiveExpense/${id}`, "DELETE");

export const monthWiseExpensedata = (data: any) =>
  ServiceMaker(`expense/branchwiseExpense?${buildQueryString(data)}`, "GET");

  export const handlingCountExpensedata = (data: any) =>
  ServiceMaker(`report/handlingLogs/counts${buildQueryString(data)}`, "GET");  

export const getDaywiseExpenseReport = (data: any) =>
  ServiceMaker(`expense/branchExpenseDayWise`, "POST", data);

export const ExpenseBudgetList = (data: any) =>
  ServiceMaker(`expense/listExpenseBudget?${buildQueryString(data)}`, "GET");

export const getHoldingAmount = (data: any) =>
  ServiceMaker("expense/getHoldingAmount", "POST",data);

export const ExpenseBudgetAdd = (data: any) =>
  ServiceMaker("expense/createExpenseBudget", "POST", data);

  export const ExpenseBudgetUpdate = (data: any) =>
  ServiceMaker("expense/updateExpenseBudget", "POST", data);

  export const ExpenseBudgetDelete = (data: any) =>
  ServiceMaker(`expense/deleteExpenseBudget?${buildQueryString(data)}`, "DELETE");

export const ExpenseListPost = () => ServiceMaker("expense/list", "POST");

export const getFuelExpense = (data: any) =>
  ServiceMaker(`fuel/listFuelRecord?${buildQueryString(data)}`, "GET");

  export const getExpenseLogs = (data: any) =>
  ServiceMaker(`expense/getExpenseLogs?${buildQueryString(data)}`, "GET");
  