import { createSlice } from "@reduxjs/toolkit";
import { FeedbackFilterType } from "./feedbackFilters.type";

const initialState: FeedbackFilterType = {
    startDate: null,
    endDate: null,
    originBranch: "",
    destBranch: "",
    complaintStatus: [
        {
            label: "In Progress",
            value: "I",
        },
        {
            label: "Open",
            value: "O",
        },
        {
            label: "Re-opened",
            value: "RE",
        },
    ],
    serviceType: "",
    user: "",
    complaintType: "",
    isAllComplaints: true,
    onlyPendingVerify: false,
    stepWiseUpdater: {
        startDate: null,
        endDate: null
    },
    stepWiseFilterStatus: null,
    sortingAction: ""
};

const feedbackFiltersSlice = createSlice({
    name: "feedbackFilters",
    initialState,
    reducers: {
        resetFeedbackFilters: () => {
            return { ...initialState }
        },
        applyBulkFeedbackFilters: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        applyFeedbackFilters: (state, action) => {
            let { what, val } = action.payload
            return {
                ...state,
                [what]: val,
            };
        },
    },
});

export const { resetFeedbackFilters, applyBulkFeedbackFilters, applyFeedbackFilters } = feedbackFiltersSlice.actions;

export default feedbackFiltersSlice.reducer;
