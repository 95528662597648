import { createSlice } from "@reduxjs/toolkit";
import { MissingType } from "./missing.type";
import { getMissingList } from "./api";

const initialState: MissingType = {
  missingList : []
};

const missingSlice = createSlice({
  name: "missing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
     .addCase(getMissingList.fulfilled, (state, action) => {
        state.missingList = action.payload;
      })
      
  },
});

export default missingSlice.reducer;
