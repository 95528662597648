import { createAsyncThunk } from "@reduxjs/toolkit";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import {
  departmentListService,
  getUserByContact,
} from "../../../services/user";
import { showHttpError } from "../../../utils/message";
import { getCityByPincode } from "../../../services/area";

export const getCity = createAsyncThunk(
  "userForm/getCity",
  async (pin: number, { dispatch }) => {
    try {
      const response = await getCityByPincode(pin);
      if (response != null) {
        return response._id;
      }
    } catch (err) {
      showHttpError(err);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "userForm/getUserDetails",
  async (contact: number, { dispatch }) => {
    try {
      dispatch(showLoader());
      let response = await getUserByContact(contact);
      if (response != null) {
        return response;
      }
      return response;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const fetchDepartmentList = createAsyncThunk(
  "userForm/fetchDepartmentList",
  async ({ companyId }: any) => {
    try {
      const response = await departmentListService(companyId);
      return response;
    } catch (error) {
      showHttpError("Could Not Fetch Departments!");
    }
  }
);

// export const changeFlat = async (key: string, val: any) => {
//   return changeFlatApi({ key, val });
// };
