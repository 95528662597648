import { ServiceMaker } from "./index";

const qs = (obj:any) => {
  return Object.entries(obj)
    .map(([key, val]:any) => `${key}=${encodeURIComponent(val)}`)
    .join("&");
};

export const listMissingPackage = (data:any) =>
  ServiceMaker(`package/missingParcel?${qs(data)}`, "GET");

export const missingPackageFound = (data:{
  sub: string,
  entity: string,
  packages: [
    string
  ],
  remarkText?: string
}) =>
  ServiceMaker("package/found/missingParcel", "PATCH", data);

// export const fetchMissingDocket = (data) =>
//   ServiceMaker(`package/missingParcel?${qs(data)}`, "GET");

// export default fetchMissingDocket;
