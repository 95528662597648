import { ServiceMaker, buildQueryString } from "./index";

export type CompanyResp = {
  _id: string;
  companyName: string;
  individual: boolean;
  individualFleet: boolean;
  active: boolean;
};

export interface ListCompanyServiceQuery {
  individual?: boolean;
  individualFleet?: boolean;
  searchName: string
}

export const listCompanyService = (query?: ListCompanyServiceQuery) =>
  ServiceMaker<CompanyResp[]>(`company/list?${buildQueryString(query || {})}`, "GET");

export const createCompanyService = (data: any) =>
  ServiceMaker("company/create", "POST", data);

export const createIndivisualService = (data: any) =>
  ServiceMaker("fleet/create", "POST", data);

export const createDriverService = (data: any) =>
  ServiceMaker("user/create", "POST", data);

export const updateCompanyService = (id: string, data: any) => 
  ServiceMaker(`company/update/${id}`, "PUT", data);

export const updateCompanyChargesService = (data: any) =>
  ServiceMaker("company/update/charges", "PUT", data);
export const updateInterMediateService = (id: any, data: any) =>
  ServiceMaker(`branch/secondaryInfo/${id}`, "PATCH", data);

export const deleteCompanyService = (id: string) =>
  ServiceMaker("company/delete", "DELETE", { id: id });

export const viewCompanyService = (id: string) =>
  ServiceMaker(`company/view/${id}`, "GET");

  export const getPaymentTypeOfUser = (data: any) =>
  ServiceMaker(`user/getPaymentTypeForUser?${buildQueryString(data)}`, "GET");


export const fetchCompanies = (data: any) =>
  ServiceMaker("company/fetch", "POST", data);

export const getBlockedFleetReasonByCity = (data: any) =>
  ServiceMaker(`fleet/OFDFleetStaus?${buildQueryString(data)}`, "GET");

export const getCityIndividuals = (data: any) =>
  ServiceMaker(`fleet/forOFD?${buildQueryString(data)}`, "GET");

export const getIndividuals = () =>
  ServiceMaker("company/individuals/list", "GET");

export const updateCompanyMessage = (data: any) =>
  ServiceMaker("company/update/messages", "PUT", data);

export const updateCompanyPackingSizes = (data: any) =>
  ServiceMaker("company/update/packingsizes", "PUT", data);

export const listFleetUserService = (data: any) =>
  ServiceMaker("user/list/fleetWise", "POST", data);

export const listBookingIncentivesList = () =>
  ServiceMaker("company/incentive/list", "GET");

export const setOrUpdateBookingIncentive = (data: any) =>
  ServiceMaker("company/incentive/set", "POST", data);

export const listBranchMultipleCompanyService = (data: any) =>
  ServiceMaker("branch/list/by/company", "POST", data);

export const getLiveLocation = () =>
  ServiceMaker("/location/getLiveLocation", "GET");

export const setPickupIncentive = (data: any) =>
  ServiceMaker("incentive/pickup", "POST", data);

export const getPickupIncentive = (sub: string, entity: string) =>
  ServiceMaker(`incentive/pickup?${buildQueryString({ sub, entity })}`, "GET");

export const updatePickupIncentive = (id: string, data: any) =>
  ServiceMaker(`incentive/pickup/${id}`, "PATCH", data);

export const getPickupReport = (contact: number) =>
  ServiceMaker(
    `incentive/pickupReports?${buildQueryString({ contact })}`,
    "GET"
  );

export const sendPickupPayment = (data: any) =>
  ServiceMaker("incentive/makePayment", "POST", data);

export const getPaymentReport = (contact: number) =>
  ServiceMaker(`incentive/paymentList?${buildQueryString({ contact })}`, "GET");



export const deliverPacket = (data: any) =>
  ServiceMaker("package/deliver", "POST", data);

export const searchCustomerByRegex = (q: any, sub: any, entity: any) =>
  ServiceMaker(
    `customer/search?q=${q +
    (sub && (sub == "B" || sub === "F") ? "&sub=" + sub : "") +
    (entity && entity.length === 24 ? "&entity=" + entity : "")}`,
    "GET"
  );

  export const uploadBuiltyBannerImage = (data: any) => 
    ServiceMaker(`company/builtyBanner`, "POST", data);

  export const getBuiltyBannerList = (data:any) => 
    ServiceMaker(`company/builtyBanner?${buildQueryString(data)}`, "GET");

  export const updateBuiltyBannerList = (id: any) => 
    ServiceMaker(`company/builtyBanner/${id}`, "PATCH");
