import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHttpError } from "../../../utils/message";
import { getSettingsService } from "../../../services/settings";

export const setSettingsGeneralAll = createAsyncThunk(
    "setting/allGeneralSettings",
    async () => {
      try {
        const allSettingsData = await getSettingsService();
        return allSettingsData;
      } catch (err) {
        showHttpError(err);
      }
    }
  );
  