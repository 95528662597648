import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPaymentList } from "../../../services/paymentType";
import { showHttpError } from "../../../utils/message";

export const setPaymentTypeList = createAsyncThunk(
    "setPaymentTypeList",
    async () => {
        try {
            const response = await getPaymentList();
            return response;
        } catch (err) {
            showHttpError(err);
        }
    }
);

export const setOnlinePaymentTypeList = createAsyncThunk(
    "setOnlinePaymentTypeList",
    async () => {
        try {
            const response = await getPaymentList({ online_Payment: true });
            return response;
        } catch (err) {
            showHttpError(err);
        }
    }
);