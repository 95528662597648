import { createSlice } from "@reduxjs/toolkit";
import { CustometNotificationInitialValuesType } from "./notification.type";
import { customernotificationService, loadMoreCustomerNotifications, updatePendingActionCustomerNotificationCountAction } from "./api";

const initialState: CustometNotificationInitialValuesType = {
  notifications: [],
  notificationLoadMore: true,
  isNotificationsLoading: false,
  pendingActionNotificationCount: 0,
  offset: 0,
  notifyMemos: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotificationLoading: (state, action) => {
      state.isNotificationsLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(customernotificationService.pending, (state) => {
        state.isNotificationsLoading = true;
      })
      .addCase(customernotificationService.fulfilled, (state, action) => {
        state.notifications = action.payload?.notifications;
        state.notificationLoadMore = action.payload
          ?.notificationLoadMore as boolean;
        state.offset = 0;
        state.pendingActionNotificationCount =
          action.payload?.pendingActinoNotificationCount;
        state.isNotificationsLoading = false;
      })
      .addCase(customernotificationService.rejected, (state) => {
        state.notificationLoadMore = false;
        state.isNotificationsLoading = false;
      })
      .addCase(loadMoreCustomerNotifications.fulfilled, (state, action) => {
        if (action.payload && action.payload.offset > 0) {
          return {
            ...state,
            notifications: [
              ...state.notifications,
              ...action.payload.notifications,
            ],
            notificationLoadMore: action.payload.notificationLoadMore,
            isNotificationsLoading: false,
          };
        }
        state.notifications = action.payload?.notifications;
        state.notificationLoadMore = action.payload
          ?.notificationLoadMore as boolean;
      })
      .addCase(
        updatePendingActionCustomerNotificationCountAction.fulfilled,
        (state, action) => {
          state.pendingActionNotificationCount = action.payload;
        }
      );
  },
});

export const { setNotificationLoading } = notificationSlice.actions;

export default notificationSlice.reducer;
