import { ServiceMaker, buildQueryString } from "./index";

export const getCustomerdocketDataTrack = (data: any) =>
  ServiceMaker(`customerBooking/viewdocket?${buildQueryString(data)}`, "GET");

export const getCustomerWalletTransactions = (data: any) =>
  ServiceMaker(`customerBooking/listCustomerTransaction?${buildQueryString(data)}`, "GET");

export const getCustomerBranchOrderMonthWise = (data: any) =>
  ServiceMaker(`customerBooking/branchOrderMonthWise?${buildQueryString(data)}`, "GET");

export const getOtpForBranchOrderTracking = (data: any) =>
  ServiceMaker(`customerBooking/getBranchOrderOtp?${buildQueryString(data)}`, "GET");

export const getDriverTabsTotalCount = () =>
  ServiceMaker(
    `operation/countsCustomerBookingRequest`,
    "GET"
  );

export const getAreaForCustomer = (data: any) =>
  ServiceMaker(`area/view/${data}`, "GET");

export const listCustomerBooking = (data?: any) =>
  ServiceMaker(
    `customerBooking/listCustomerBooking`,
    "POST" , data
  );

export const processPartLoadRateApprovalByCustomer = (id : any ,data: any) =>
  ServiceMaker(`customerBooking/processCustomerBookingGrossReq/${id}`, "PATCH", data);

export const verifyPartBByDriver = (data: any) =>
  ServiceMaker(`customerBooking/verifyEwayBill`, "PATCH", data);

export const processPartLoadBookingRequest = (data: any) =>
  ServiceMaker(`operation/createCustomerBookingGrossReq`, "POST", data);

export const processCustomerBookingRequest = (data: any) =>
  ServiceMaker(`customerBooking/processCustomerBookingRequest`, "POST", data);

export const createCustomerBookingCancelRequest = (data: any) =>
  ServiceMaker(`customerBooking/cancelOrderReq`, "PATCH", data);

export const loadCustomerPackage = (data: any) =>
  ServiceMaker(`customerBooking/loadCustomerPackage`, "POST", data);

export const driverDetailsForPartLoad = (data: any) =>
  ServiceMaker(`fleet/listAvalibleFleets?${buildQueryString(data)}`, "GET");

export const transferFleetForPartLoad = (data: any) =>
  ServiceMaker(`customerBooking/updateFleet`, "PATCH" , data);

export const customerPackageDelivery = (data: any) =>
  ServiceMaker(`delivery/customerPackageDelivery`, "POST", data);

export const trackCustomerBooking = (data: any) =>
  ServiceMaker(
    `customerBooking/trackCustomerBooking?${buildQueryString(data)}`,
    "GET"
  );

export const trackCustomerBookingWithDocket = (data: any) =>
  ServiceMaker(
    `customerBooking/viewCustomerBookingDocket?${buildQueryString(data)}`,
    "GET"
  );

export const customerWalletInfo = () =>
  ServiceMaker(
    `transactions/cashOutfromCustomerWallet`,
    "GET"
  );

export const sendOtpForGeneratecode = (data:any) =>
  ServiceMaker(`customerProfile/sendOtp`,"PATCH",data)

export const verifyOtpForGeneratecode = (data:any) =>
  ServiceMaker(`customerProfile/verifyOtp`,"PATCH",data)

export const deleteMyAccount = (data:any) =>
  ServiceMaker(`customerProfile/deleteCustomer`,"DELETE",data)

export const setCustomerDoorDelivery = (data: any) =>
  ServiceMaker(`customerBooking/doorDelivery`, "PATCH", data);

export const addComplaintForCustomer = (data: any) =>
  ServiceMaker(`customerBooking/addCustomerComplaint`, "POST", data);

export const updateGpsLink = (data: any) =>
  ServiceMaker(`booking/gpsTrackingLink`, "POST", data);

export const getCustomerWiseWalletBalance = (data:any) => 
    ServiceMaker(`customerCredit/getCustomerBalance`,"POST",data)

export const customerBookingStartOrder = (data:any) =>
ServiceMaker(`fleet/customerBooking/startOrder`, "PATCH", data);

export const customerBookingCustomerVerify = (data:any) =>
ServiceMaker(`customerLogin/booking/customerBookingCustomerVerify`, "PATCH", data);

export const generatePolyLine = (data:any) =>
ServiceMaker(`customerBooking/generatePolyLine`, "POST", data);

export const createIntraCityBooking = (data:any) => ServiceMaker(`customerBooking/createCustomerBookingRequestReq`, "POST", data);

export const createPartLoadFullLoadBooking = (data:any) => ServiceMaker(`customerBooking/createCustomerBookingRequestReq`, "POST", data);

export const vehicleIntracityList = (data:any) =>
  ServiceMaker(`customerBooking/getIntraCityPrice`, "POST", data);

export const getCustomerBookingTotalCharge = (data: any) =>
  ServiceMaker(`customerBooking/getCustomerBookingTotalCharge?${buildQueryString(data)}`, "GET"); 

export const reqForCancelOrder = (data: any) =>
  ServiceMaker(`customerBooking/cancelOrder`, "PATCH",data); 

export const removeBidApi = (data: any) =>
  ServiceMaker(`operation/deleteCustomerBookingGrossReq`, "DELETE",data); 

export const transferFleetApi = (data: any) =>
  ServiceMaker(`customerBooking/transferCustomerPackage`, "POST",data); 

export const addConnectedCustomerside = (data: any) =>
  ServiceMaker(`customerProfile/connectcontact/verifyotp`, "POST", data); 

export const sendOtpConnectedCustomerside = (data: any) =>
  ServiceMaker(`customerProfile/connectcontact/sendotp`, "POST", data); 

export const deleteConnectedCustomerside = (data: any) =>
  ServiceMaker(`customerProfile/connectedcontact`, "DELETE", data); 

export const deletedoorDelAddressOfCus = (data : any) => 
  ServiceMaker(`address/delete`,"DELETE" , data)

export const editdoorDelAddressOfCus = (id : string , data : any) => 
  ServiceMaker(`address/update/${id}`,"PATCH",data)

export const getCustomerDetails = () => 
  ServiceMaker(`customerProfile/getDetails`,"GET")

export const updateCustomerProfile = (data:any) => 
  ServiceMaker(`customerProfile/updateCustomerProfile`,"POST",data)

export const getCustomerAvgRating = () => 
  ServiceMaker(`driverToCustomerReview/getCustomerAverageRating`,"GET")

export const getCustomerBookingTransactionCount = () => 
  ServiceMaker(`customerBooking/CustomerTransaction/count`,"GET")

export const updateCustomerDocument = (data?: any) => ServiceMaker(`customerProfile/document/update`, "PATCH", data)

export const updateGSTInfo = (data?: any) => ServiceMaker(`customerProfile/gst/add`, "PATCH", data)