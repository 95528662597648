import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  expense_Boolean,
  expenseValidationSchema,
  fuelExpenseValidation,
  validateExpense,
} from "./helper";
import { Expense, ExpenseState } from "./expense.type";
import { isArrayCheck } from "../../../containers/generics/CheckArray";
import { get, set } from "lodash";

const initExpense = {
  type: "add",
  sub: "",
  sub_val: "",
  supplier: "",
  suppliers: null,
  route: "",
  ref: "",
  amt: "",
  end_date: new Date(),
  start_date: new Date(),
  is_route: false,
  monthly_duration: false,
  tripDate: "",
  month: undefined,
  year: undefined,
  attachRoute: false,
  duration: true,
  assetType: "",
  assetBalance: undefined,
  remarks: "",
  isFixedExpense: false,
  paymentType: "",
  transactionImage: "",
  transactionId: "",
  vendor: "",
  variance: "",
  paymentMode: "",
};

const initState: ExpenseState = {
  expenseHandlingData: "",
  expenseTypes: [],
  subExpenses: [],
  assetTypes: [],
  assetBalances: [],
  routes: null,
  selectExpenseTypes: [],
  fuel: {
    op: "",
    cl: "",
    ppl: "",
    places: [],
    new: "",
    rnum: "",
    driver: "",
    dName: "",
    remarks: "",
    from: "",
    fuelQty: "",
    to: "",
    selectedFuel: "",
    filterContact: "",
    filterVehicle: "",
    avgIdeal: "",
  },
  fuelErrors: {
    op: true,
    cl: true,
    ppl: true,
    rnum: true,
    driver: true,
    dName: true,
    remarks: true,
    fuelQty: true,
    avgIdeal: true,
  },
  assign: {
    branches: null,
    departments: null,
    fleets: null,
  },
  disableOpKms: false,
  printExpenseVoucher: {},
  voucherPrint: false,
  expense: initExpense,
  expenseErrors: { ...expense_Boolean(true) },
  expenseTouched: { ...expense_Boolean(false) },
  remainingLimit: 0,
  loaderAcrossAmount: false,
};

const newInitialState = initState;

const expenseSlice = createSlice({
  name: "expense",
  initialState: newInitialState,
  reducers: {
    setExpenseType: (state, action) => {
      let expenses = action.payload;
      let selectExpenseTypes = [];
      let expenseTypes: any = {};
      for (let i = 0; i < expenses.length; i++) {
        const e = expenses[i];
        selectExpenseTypes.push({ label: e.name, value: e.id });
        expenseTypes[e.id] = e;
      }
      // return { ...state, expenseTypes: action.payload, expenseTypesMap: expenseTypes, selectExpenseTypes }
      state.expenseTypes = expenseTypes;
      state.selectExpenseTypes = selectExpenseTypes;
    },
    dumbSelect: {
      reducer: (
        state,
        action: PayloadAction<{ val: any; expenseData: any }>
      ) => {
        let expense: any = Object.assign({}, initExpense);
        let { val, expenseData } = action.payload;
        expense = {
          ...expense,
          ...expenseData,
          ref: val,
        };
        expense.subExpenses = null;
        expense.subExpense = "";
        let expenseErrors = { ...state.expenseErrors };
        let expenseTouched = { ...state.expenseTouched };
        expenseTouched.expenseType = true;
        expenseErrors.expenseType = validateExpense(
          (expenseValidationSchema as any).expenseType,
          action.payload
        ) as boolean;
        if (action.payload.val == "") expense.expenseType = "";
        state.expense = expense;
        state.expenseTouched = expenseTouched;
        state.expenseErrors = expenseErrors;
        state.remainingLimit = 0;
      },
      prepare: (val, expenseData) => ({
        payload: { val, expenseData },
      }),
    },
    fixedExpenseType: (state, action) => {
      let expense = Object.assign({}, state.expense);
      expense.isFixedExpense = action.payload;
      state.expense = expense;
    },
    setSubExpenses: (state, action) => {
      state.subExpenses = action.payload;
    },
    setAssetBal: (state, action) => {
      let selectAssetBal,
        assetBalance: any = {};
      if (isArrayCheck(action.payload)) {
        selectAssetBal = action.payload.map((a: { [k: string]: any }) => ({
          label: a?.uniqueId ? a?.uniqueId : a?.qty + " " + a?.type?.assetName,
          value: a._id,
          fleet_id: a?.fleetInfo?._id,
        }));
        action.payload.forEach((e: { [k: string]: any }) => {
          let key = e?.uniqueId
            ? e?.uniqueId
            : e?.qty + " " + e?.type?.assetName;
          assetBalance[key] = e;
        });
      }
      state.assetBalances = assetBalance;
      state.selectAssetBal = selectAssetBal;
    },
    expAssetSet: (state, action) => {
      // let expense = Object.assign({}, state.expense)
      let assetTypes = action.payload;
      state.assetTypes = assetTypes;
    },
    showLoaderInAmout: (state) => {
      state.loaderAcrossAmount = true;
    },
    hideLoaderInAmount: (state) => {
      state.loaderAcrossAmount = false;
    },
    availableBalance: (state, action) => {
      let remainingLimit =
        action.payload && action.payload.fixed
          ? action.payload.fixed.amt - action.payload.past
          : 0;
      if (
        action.payload &&
        action.payload.fixed &&
        action.payload.past !== null &&
        action.payload.fixed.expectedCost
      ) {
        remainingLimit = action.payload.fixed.expectedCost
          ? action.payload.fixed.expectedCost - action.payload.past
          : 0;
      }
      state.remainingLimit = remainingLimit;
    },
    fuelInExpenseOnChange: {
      reducer: (state, action: PayloadAction<{ what: any; val: any }>) => {
        let { what, val } = action.payload;
        let fuel: any = Object.assign({}, state.fuel);
        fuel[what] = val;
        state.fuel = fuel;
        state.fuelErrors = set(
          state.fuelErrors,
          [what],
          validateExpense(get(fuelExpenseValidation, [what]), val)
        );
      },
      prepare: (what: any, val: any) => ({
        payload: {
          what,
          val,
        },
      }),
    },
    expSelAssetType: (state, action) => {
      let expense = Object.assign({}, state.expense);
      expense.assetType = action.payload;

      let expenseErrors = state.expenseErrors;
      expenseErrors.assetType = validateExpense(
        get(expenseValidationSchema, ["assetType"]),
        action.payload
      ) as boolean;
      let expenseTouched = state.expenseTouched;
      expenseTouched.assetType = true;
      state.expense = expense;
      state.expenseErrors = expenseErrors;
    },
    selectAsseetBal: (state, action) => {
      let expense = Object.assign({}, state.expense);
      expense = {
        ...expense,
        assetBalance: action.payload,
        sub_val: "",
      };
      let expenseErrors = state.expenseErrors;
      expenseErrors.assetType = validateExpense(
        get(expenseValidationSchema, ["assetBalance"]),
        action.payload
      ) as boolean;
      let expenseTouched = state.expenseTouched;
      expenseTouched.assetBalance = true;
      state.expense = expense;
      state.expenseErrors = expenseErrors;
    },
    disableOPKMS: (state) => {
      state.disableOpKms = true;
    },
    enableOPKMS: (state) => {
      state.disableOpKms = false;
    },
    selectSubExpenseType: (state, action) => {
      let expense = Object.assign({}, state.expense);
      // let id = "id";
      if (state.expense.sub == "U" || state.expense.sub == "B") {
        // id = "_id";
      }
      expense = {
        ...expense,
        assetBalance: undefined,
        sub_val: action.payload,
      };
      let expenseErrors = { ...state.expenseErrors };
      expenseErrors.subExpense = validateExpense(
        get(expenseValidationSchema, ["subExpense"]),
        action.payload
      ) as boolean;
      let expenseTouched = { ...state.expenseTouched };
      expenseTouched.subExpense = true;
      // expenseErrors.subExpense = validateExpense(expenseValidationSchema.subExpense, action.payload)
      if (action.payload == "") expense.subExpense = "";
      state.expense = expense;
      state.expenseErrors = expenseErrors;
      state.expenseTouched = expenseTouched;
    },
    changeExpenseAmount: (state, action) => {
      let expense = Object.assign({}, state.expense);
      expense.amt = action.payload;
      let expenseErrors = state.expenseErrors;
      expenseErrors.amt = validateExpense(
        get(expenseValidationSchema, ["amt"]),
        action.payload
      ) as boolean;
      let expenseTouched = state.expenseTouched;
      expenseTouched.amt = true;
      state.expense = expense;
      state.expenseErrors = expenseErrors;
      state.expenseTouched = expenseTouched;
    },
    toggleAttachRoute: (state) => {
      let expense = Object.assign({}, state.expense);
      expense.attachRoute = !expense.attachRoute;
      state.expense = expense;
    },
    setExpRoutes: (state, action) => {
      let expense = Object.assign({}, state.expense);
      expense.routes = action.payload;
      state.expense = expense;
    },
    selectExpenseRoute: (state, action) => {
      let expense = Object.assign({}, state.expense);
      let route = "";
      state.routes.map((rt: any) => {
        if (rt._id == action.payload.value) {
          route = action.payload;
        }
      });
      expense.route = route;
      let expenseErrors = state.expenseErrors;
      expenseErrors.route = validateExpense(
        (expenseValidationSchema as any).route,
        action.payload
      ) as boolean;
      let expenseTouched = state.expenseTouched;
      expenseTouched.route = true;
      state.expense = expense;
      state.expenseErrors = expenseErrors;
    },
    expStartDateData: (state, action) => {
      let expense = Object.assign({}, state.expense);
      expense.start_date = action.payload;
      state.expense = expense;
    },
    expEndDate: (state, action) => {
      let expense = Object.assign({}, state.expense);
      expense.end_date = action.payload;
      state.expense = expense;
    },
    setVoucherImage: (state, action) => {
      let expense = Object.assign({}, state.expense);
      expense.voucherImage = action.payload;
      state.expense = expense;
    },
    setBillImage: (state, action) => {
      let expense = Object.assign({}, state.expense);
      expense.billImage = action.payload;
      state.expense = expense;
    },
    resetExpenseIncur: (state) => {
      let expenseErrors = { ...expense_Boolean(true) };
      state.expense = initState.expense;
      state.fuel = initState.fuel;
      state.fuelErrors = initState.fuelErrors;
      state.expenseErrors = expenseErrors;
      state.remainingLimit = 0;
      state.disableOpKms = false;
      state.voucherPrint = false;
      state.expenseHandlingData = "";
    },
    closeVoucherModal: (state) => {
      state.voucherPrint = false;
    },
    deleteExpenseRecordData: (state) => {
      let expense: Expense = {
        type: "add",
        sub: null,
        sub_val: "",
        supplier: "",
        suppliers: null,
        route: "",
        ref: "",
        amt: "",
        remarks: "",
        end_date: new Date(),
        start_date: new Date(),
        is_route: false,
        tripDate: "",
        attachRoute: false,
        duration: true,
        assetType: "",
        assetBalances: "",
        assetBalance: undefined,
        isFixedExpense: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        vendor: "",
        paymentMode: "",
      };
      let expenseErrors = { ...expense_Boolean(true) };
      state.expense = expense;
      state.expenseErrors = expenseErrors;
    },
    incureExpSuccess: (state, action) => {
      const { voucherNumber } = action.payload;
      (state.voucherPrint = true),
        (state.voucherNumber = voucherNumber),
        (state.expenseHandlingData = action.payload?.handlingLogs
          ? {
              ...action.payload?.handlingLogs,
              amt:
                action.payload?.handlingLogs &&
                action.payload?.handlingLogs?.amount,
            }
          : "");
      // expense: { ...initExpense, expenseTypes: state.expense.expenseTypes },
      // fuel: initState.fuel
    },
    incureExpFailed: (state) => {
      return state;
    },
    editExpenseRecordData: (state) => {
      let expense = {
        type: "add",
        sub: null,
        sub_val: "",
        supplier: "",
        suppliers: null,
        route: "",
        ref: "",
        amt: "",
        remarks: "",
        end_date: new Date(),
        start_date: new Date(),
        is_route: false,
        tripDate: "",
        attachRoute: false,
        duration: true,
        assetType: "",
        assetBalances: "",
        assetBalance: undefined,
        // remarks: "",
        isFixedExpense: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        vendor: "",
        paymentMode: "",
      };
      state.expense = expense;
    },
    deleteExpenseRecordFailed: (state) => {
      return state;
    },
    setExpenseIncurData: (state, action) => {
      let expense = action.payload;
      expense = {
        ...expense,
        type: "edit",
        expenseType: expense.ref,
      };
      state.expense = expense;
    },
    setFuelValues: (state, action) => {
      state.fuel = action.payload;
    },
    printExpenseVoucher: (state, action) => {
      state.printExpenseVoucher = action.payload;
    },
    deleteFuelExpenseData: (state) => {
      return state;
    },
    couldNotFetchCntact: (state) => {
      return state;
    },
    editExpenseRecordFailed: (state) => {
      return state;
    },
    nonCashChange: (state, action) => {
      let { what, val } = action.payload;
      set(state.expense, [what], val);
    },
    setPastEnpensesData: (state, action) => {
      return {
        ...state,
        pastExpenses: action.payload,
      };
    },
    setCompanyRoutes: (state, action) => {
      return {
        ...state,
        routes: action.payload,
      };
    },
    getRoutesData: (state, action) => {
      return {
        ...state,
        routes: action.payload,
      };
    },
  },
});

export const {
  setExpenseType,
  dumbSelect,
  fixedExpenseType,
  setSubExpenses,
  setAssetBal,
  expAssetSet,
  showLoaderInAmout,
  hideLoaderInAmount,
  availableBalance,
  fuelInExpenseOnChange,
  expSelAssetType,
  selectAsseetBal,
  disableOPKMS,
  enableOPKMS,
  selectSubExpenseType,
  changeExpenseAmount,
  toggleAttachRoute,
  setExpRoutes,
  selectExpenseRoute,
  expStartDateData,
  expEndDate,
  setVoucherImage,
  setBillImage,
  resetExpenseIncur,
  closeVoucherModal,
  deleteExpenseRecordData,
  incureExpFailed,
  incureExpSuccess,
  editExpenseRecordData,
  deleteExpenseRecordFailed,
  setExpenseIncurData,
  setFuelValues,
  printExpenseVoucher,
  deleteFuelExpenseData,
  couldNotFetchCntact,
  editExpenseRecordFailed,
  nonCashChange,
  setPastEnpensesData,
  getRoutesData
} = expenseSlice.actions;

export default expenseSlice.reducer;

export const selectRight = (state: RootState) => state.fleet;
// export const selectIsLoading = (state: RootState) => state.user.isLoading;
// export const selectError = (state: RootState) => state.user.error;
