import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./../src/containers/CustomerSection/CustomerBookingTracking/customerBooking.css";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import "./newSrc/styles/colors.css";
import * as Sentry from "@sentry/react";
import "./newSrc/styles/utils.css";
import Loader from "./Components/Loader/Loader";
const AllRoutes = lazy(() => import("./router"));

Sentry.init({
  dsn: "https://e30a11deb632e1218ec501d35701e8e4@o4509071542714368.ingest.us.sentry.io/4509071545860096",
  // integrations: [new Sentry.BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0, // Adjust sampling rate for performance monitoring
});
const tagManagerArgs = {
  gtmId: "GTM-WXPKHMBM",
};
TagManager.initialize(tagManagerArgs);

function App() {
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setIsLoaderVisible(false);
    }, 2000);
    // requestNotification();

    return () => clearTimeout(loaderTimeout);
  }, []);

  return (
    <>
      {isLoaderVisible
        ? <Loader />
        : <Suspense fallback={<Loader />}>
          <Router>
            <AllRoutes />
          </Router>
        </Suspense>
      }
    </>
  );
}

export default App;
