import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAllFleetsService,
  fetchAllFranchiseFleetsService,
} from "../../../services/fleet";
import { changeSimpleData, setFleet, setFleets, setFranchiseFleets } from "./fleetSlice";
import { showHttpError } from "../../../utils/message";
import { RootState } from "../../store";

export const fetchFleets = createAsyncThunk(
  "fleet/fleetList",
  async (_, { dispatch }) => {
    try {
      let response = await fetchAllFleetsService();
      dispatch(setFleets(response));
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const fetchFranchiseFleets = createAsyncThunk(
  "fleet/fetchFranchiseFleets",
  async (_, { dispatch }) => {
    try {
      dispatch(setFranchiseFleets([]));
      let response = await fetchAllFranchiseFleetsService();
      dispatch(setFranchiseFleets(response));
    } catch (error) {
      showHttpError(error);
    }
  }
);

export const changeSimpleAction = createAsyncThunk(
  "fleet/changeSimple",
  async (data: any, { dispatch, getState }) => {
    const { what, val } = data;
    try {
      const state = getState() as RootState;
      await dispatch(changeSimpleData(data));
      let fleetsMap = state?.fleet?.fleetsMap;
      if (fleetsMap && what[0] == "regNumber" && val in fleetsMap) {
        dispatch(setFleet(fleetsMap[val]))
      }
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const changeSimple = (what: any, val: any) => {
  return (dispatch: any) => {
    const payload = { what, val };
    dispatch(changeSimpleAction(payload));
  };
};
