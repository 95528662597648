import { createSlice } from "@reduxjs/toolkit";
import { userListSliceType } from "./userList.type";
import { userList } from "./api";

const initialState: userListSliceType = {
  userList: [],
};

const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userList.fulfilled, (state, action) => {
      state.userList = action.payload;
    });
  },
});

// export const { userListActions } = listSlice.actions

export default listSlice.reducer;
