import * as yup from "yup";
import { fleetRegNumberRegex } from "../../../constant";
export const booleanSchema = (defaultValue: boolean) => ({
    regNumber: defaultValue,
    modelNumber: defaultValue,
    capacity: defaultValue,
    volume: {
      l: defaultValue,
      b: defaultValue,
      h: defaultValue,
    },
    fleetType: defaultValue,
    bodyType: defaultValue,
    fuelType: defaultValue,
    idealFuel: defaultValue,
    distanceCovered: defaultValue,
    ownerShip: defaultValue,
  });

  export const validate = (schema: any, obj: any) => {
    try {
      if (!schema) return false;
      schema.validateSync(obj);
      return false;
    } catch (err: any) {
      return err.message;
    }
  };

  export const fleetSchemaValidate = yup.object().shape<any>({
    regNumber: yup
      .string()
      .required("RegNumber is required")
      .matches(fleetRegNumberRegex, "invalid format"),
    modelNumber: yup.string().required("Model number is required"),
    capacity: yup.string().required("Capactiy is required"),
    volume: {
      l: yup.string().required("Length is required"),
      b: yup.string().required("Breadth is required"),
      h: yup.string().required("Height is required"),
    },
    fleetType: yup.object().shape({
      label: yup.string().required("FleetType is required"),
      value: yup.string().required("FleetType is required"),
    }),
    bodyType: yup.object().shape({
      label: yup.string().required("Body Type is required"),
      value: yup.string().required("Body Type is required"),
    }),
    fuelType: yup.object().shape({
      label: yup.string().required("Fuel Type is required"),
      value: yup.string().required("Fuel Type is required"),
    }),
    ownerShip: yup.object().shape({
      label: yup.string().required("Ownership is required"),
      value: yup.string().required("Ownership is required"),
    }),
    assetSubType: yup.string().required("assetSubType is required"),
    // bodyType: yup.string().required("BodyType is required"),
    // fuelType: yup.string().required("FuelType is required"),
    // ownerShip: yup.string().required("OwnerShip is required"),
    idealFuel: yup.string().required("IdealFuel is required"),
    distanceCovered: yup.string().required("DistanceCovered is required"),
    allowedOFDCities: yup
      .array()
      .of(yup.string().required())
      .min(1, "At least 1 allowedOFDCities is required"),
    businessType: yup.string().required("BusinessType is required"),
    overhead: yup.string().required("overhead is required"),
    insuranceDate: yup.date().required("insuranceDate is required"),
    emiDate: yup.date().required("emiDate is required"),
    fitnessDate: yup.date().required("fitnessDate is required"),
    permitDate: yup.date().required("permitDate is required")
  })