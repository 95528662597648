import { createSlice } from "@reduxjs/toolkit";
import { SmsAndCallRecords } from "./smsAndCallRecords.type";
import { setDoorDelCallAcceptedWithOptionPressedRecords, setDoorDelCallAcceptedWithoutOptionPressedRecords, setDoorDelCallPendingRecords, setDoorDelCallRejectedRecords, setNormalCallAcceptedRecords, setNormalCallRejectedRecords, setSmsAcceptedRecords, setSmsRejectedRecords, setTotalCallSmsCount } from "./api";

const initialState: SmsAndCallRecords = {
    smsAccepted: [],
    smsRejected: [],
    normalAcceptedCalls: {
        // timeStamp: null,
        data: []
    },
    normalPendingCalls: {
        // timeStamp: null,
        data: []
    },
    normalRejectedCalls: {
        // timeStamp: null,
        data: []
    },
    doorDelAcceptedWithOptionPressed: {
        // timeStamp: null,
        data: []
    },
    doorDelAcceptedWithoutOptionPressed: {
        // timeStamp: null,
        data: []
    },
    doorDelPendingCalls: {
        // timeStamp: null,
        data: []
    },
    doorDelRejectedCalls: {
        // timeStamp: null,
        data: []
    },
    totalCallSmsCount: {
        deliveryAcceptCount: 0,
        deliveryCount: 0,
        deliveryPendingCount: 0,
        deliveryRejectCount: 0,
        doorDeliveryAcceptCount: 0,
        doorDeliveryAcceptNOpCount: 0,
        doorDeliveryAcceptOpCount: 0,
        doorDeliveryCount: 0,
        doorDeliveryPendingtCount: 0,
        doorDeliveryRejectCount: 0,
        smsCount: 0,
        smsRejectCount: 0,
        smsSendCount: 0,
        voiceCount: 0,
        // countTimeStamp: null
    }
}

const smsAndCallRecordsSlice = createSlice({
    name: "update/smsAndCallRecords",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setSmsAcceptedRecords.fulfilled, (state, action) => {
                state.smsAccepted = action.payload
            })
            .addCase(setSmsRejectedRecords.fulfilled, (state, action) => {
                state.smsRejected = action.payload
            })
            .addCase(setNormalCallAcceptedRecords.fulfilled, (state, action) => {
                if (action.payload) {
                    state.normalAcceptedCalls = action.payload
                }
            })
            .addCase(setNormalCallRejectedRecords.fulfilled, (state, action) => {
                if (action.payload) {
                    state.normalRejectedCalls = action.payload
                }
            })
            .addCase(setDoorDelCallAcceptedWithOptionPressedRecords.fulfilled, (state, action) => {
                if (action.payload) {
                    state.doorDelAcceptedWithOptionPressed = action.payload
                }
            })
            .addCase(setDoorDelCallAcceptedWithoutOptionPressedRecords.fulfilled, (state, action) => {
                if (action.payload) {
                    state.doorDelAcceptedWithoutOptionPressed = action.payload
                }
            })
            .addCase(setDoorDelCallPendingRecords.fulfilled, (state, action) => {
                if (action.payload) {
                    state.doorDelPendingCalls = action.payload
                }
            })
            .addCase(setDoorDelCallRejectedRecords.fulfilled, (state, action) => {
                if (action.payload) {
                    state.doorDelRejectedCalls = action.payload
                }
            })
            .addCase(setTotalCallSmsCount.fulfilled, (state, action) => {
                if (action.payload) {
                    state.totalCallSmsCount = action.payload
                }
            })
    },
});

export default smsAndCallRecordsSlice.reducer;