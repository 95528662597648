import { createAsyncThunk } from "@reduxjs/toolkit";
import { trackCustomerBooking } from "../../../services/customersection";

export const getCustomerOrderQuickData = createAsyncThunk(
    "getCustomerOrderQuickData",
    async (data: any, { dispatch }) => {
        try {
            dispatch({ type: "START_LOADER" });
            const response = await trackCustomerBooking({ customerDocketId: data });
            dispatch({ type: "END_LOADER" });
            return { isOpen: true, data: response }; // ✅ Returning necessary state updates
        } catch (error) {
            dispatch({ type: "END_LOADER" });
            console.error("Error fetching quick order data:", error);
        }
    }
);
