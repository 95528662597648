import { createSlice } from "@reduxjs/toolkit";
import { setOnlinePaymentTypeList, setPaymentTypeList } from "./api";

const initialState = {
    paymentTypeList: [],
    onlinePaymentTypeList: [],
};

const paymentTypeListSlice = createSlice({
    name: "paymentTypeList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(setPaymentTypeList.fulfilled, (state, action) => {
                state.paymentTypeList = action.payload
            })
            .addCase(setOnlinePaymentTypeList.fulfilled, (state, action) => {
                if (action.payload) {
                    state.onlinePaymentTypeList = action.payload
                }
            })
    },
});

export default paymentTypeListSlice.reducer;