import { toast } from "react-toastify";
let { isArray } = Array;

type ErrReason = {
  [name: string]: string[];
  [name: number]: string[];
};

type LMSPAYError = {
  status: number;
  message: ErrReason;
};

let extractHandler = (err: LMSPAYError) => {
  if (
    err &&
    err.message &&
    isArray(err.message) &&
    typeof err.message[0] === "string"
  ) {
    return err.message[0];
  }
  if (err && err.message && typeof err.message === "string") {
    return err.message;
  }
  if (
    err &&
    err.message &&
    typeof err.message === "object" &&
    Object.keys(err.message).length > 0
  ) {
    let field: string = Object.keys(err.message)[0];
    return [new String(field).toUpperCase(), err.message[field][0]].join(" - ");
  }
  return "Something Went Wrong!";
};

const resolveAPI = (fn: Function, args: Array<any>) =>
  new Promise<any>((resolve, reject) => {
    fn.apply(null, args)
      .then(resolve)
      .catch((err: any) =>
        reject(err.message ? extractHandler(err) : "Something went wrong here!")
      );
  });

const showError = (err: any) => {
  toast.error(typeof err === "string" ? err : "Something went wrong!");
};

export { extractHandler, resolveAPI, showError };
