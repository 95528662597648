import { ServiceMaker, buildQueryString } from "./index";

export const getCancelBookingData = (data: any) =>
 ServiceMaker(`booking/cancel/requests?${buildQueryString(data)}`, "GET");

export const getCancelPartLoadAndFullLoadBookingData = (data: any) =>
 ServiceMaker(`customerBooking/listCancelOrderReqByUser?${buildQueryString(data)}`, "GET");

export const processCancelFullloadAndPatloadBooking = (data: any) =>
 ServiceMaker(`customerBooking/processCancelOrder`, "PATCH", data);

export const rejectCancelBooking = (data: any) =>
 ServiceMaker("booking/cancel/reject", "POST", data);

export const approveCancelBooking = (data: any) =>
 ServiceMaker("booking/cancel/approve", "POST", data);

export const getCancelBookingCount = (data: any) =>
 ServiceMaker(`booking/count/cancel/requests?${buildQueryString(data)}`, "GET");

export const isCancelRequiredProcess = (data: any) =>
 ServiceMaker(`booking/cancelRequiredProcess`, "POST", data);

export const cancelBookingRequest = (data: any) =>
 ServiceMaker(`customerBooking/cancelOrderByCustomer`, "PATCH", data);

export const retryBookingRequest = (data: any) =>
 ServiceMaker(`customerBooking/retryCustomerBookingReq`, "PATCH", data);

export const allowCancelBookingRequest = (data: any) =>
 ServiceMaker(`booking/cancel/allow/request`, "PATCH", data);