import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { isArrayCheck } from "../../../containers/generics/CheckArray";
import { FleetInitialValuesType } from "./fleet.type";
import { booleanSchema, fleetSchemaValidate, validate } from "./helper";
import { get, merge, set } from "lodash";

const initialState: FleetInitialValuesType = {
  fleets: [],
  selectFleets: [],
  errors: [],
  fleetsMap: [],
};

const newInitialState = initialState

const fleetSlice = createSlice({
  name: "fleet",
  initialState: newInitialState,
  reducers: {
    setFleets: (state, action) => {
      let fleetsMap: any = {},
        selectFleets: any = [];
      if (isArrayCheck(action.payload)) {
        action.payload.forEach((e: { regNumber: any }) => {
          fleetsMap[e.regNumber] = e;
          if (e.regNumber) {
            selectFleets.push({ label: e.regNumber, value: e.regNumber });
          }
        });
      }
        state.fleets = action.payload
        state.fleetsMap = fleetsMap
        state.selectFleets = selectFleets
        state.errors = {...booleanSchema(true) }
    },
    setFleetError : (state,action) => {
      set(state,["errors",...action.payload],validate(get(fleetSchemaValidate,[action.payload]),""))
    },
    changeSelectedCities : (state,action) => {
      let allowedOFDCities1 = [];
      allowedOFDCities1.push(action.payload);
      state.allowedOFDCities = allowedOFDCities1[0]
    },
    setFranchiseFleets : (state,action) => {
      let fleetsMap: any = {},
        selectFranchiseFleets: any = [];
      if (isArrayCheck(action.payload)) {
        action.payload.forEach((e: { [k: string]: any }) => {
          fleetsMap[e.regNumber] = e;
          if (e.regNumber) {
            selectFranchiseFleets.push({
              label: e.regNumber + " " + e.companyName,
              value: e.regNumber,
              company: e.companyId,
            });
          }
        });
      }
      state.franchiseFleets = action.payload
      state.franchiseFleetsMap = fleetsMap
      state.selectFranchiseFleets = selectFranchiseFleets
    },
    changeSimpleData : (state,action) => {
      const { what, val } = action.payload;
      set(state, [what],val)
      set(state, ["errors", ...what],validate(get(fleetSchemaValidate,["what"]),val))
    },
    setFleet : (state, action) => {
      const { _id } = action.payload;
      merge(state,{
        ...action.payload,
        _id: _id,
      })
      set(state,["errors"],{ ...booleanSchema(true) })
    },
    resetFleet : (state) => {
      Object.assign(state,initialState)
    }
  },
});

export const { setFleets,setFleetError,changeSelectedCities,setFranchiseFleets,changeSimpleData,setFleet,resetFleet } = fleetSlice.actions;

export default fleetSlice.reducer;

export const selectRight = (state: RootState) => state.fleet;
// export const selectIsLoading = (state: RootState) => state.user.isLoading;
// export const selectError = (state: RootState) => state.user.error;
