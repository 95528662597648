import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHttpError } from "../../../utils/message";
import { fetchDocketDetails, fetchStrackDetailsForMsgLink } from "../../../services/operations";
import { getCustomerdocketDataTrack, trackCustomerBooking } from "../../../services/customersection";

// Centralized API mapping
const apiMap: Record<string, Function> = {
    fetchStrackDetailsForMsgLink,
    getCustomerdocketDataTrack,
    fetchDocketDetails,
    trackCustomerBooking,
};

export const SetCustomerTracking = createAsyncThunk(
    "setCustomerTracking",
    async (details: { apiName: string; payloadInfo: { id?: any; data: any; source?: string } }, { rejectWithValue }) => {
        try {
            const apiFunc = apiMap[details.apiName];

            if (!apiFunc) {
                throw new Error(`API function not found for: ${details.apiName}`);
            }

            console.log(`mzsdfvnsdfvsdfvnsdfv`, details)

            // Call API function with or without ID
            const response = details.payloadInfo.id
                ? await apiFunc(details.payloadInfo.id, { ...details.payloadInfo.data }) // APIs requiring ID
                : await apiFunc({ ...details.payloadInfo.data }); // APIs without ID

            if (response && Object.keys(response).length > 0) {
                let orderData = response; // Default response

                // Only extract `bookingTracker[0]` for `trackCustomerBooking`
                if (details.apiName === "trackCustomerBooking") {
                    orderData = response.bookingTracker[0];
                }

                return {
                    orderData,
                    source: details.payloadInfo.source || "", // ✅ Store source in Redux
                };
            }

            throw new Error("Empty response from API");
        } catch (error) {
            showHttpError(error);
            return rejectWithValue(error);
        }
    }
);
