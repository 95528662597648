import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { MemoInitialValuesType } from "./memo.type";
import {
  loadMoreNotifications,
  notificationService,
  updatePendingActionNotificationCountAction,
} from "./api";

const initialState: MemoInitialValuesType = {
  memo: {},
  notifications: [],
  notificationLoadMore: true,
  isNotificationsLoading: false,
  pendingActionNotificationCount: 0,
  offset: 0,
  notifyMemos: [],
  updateSidebar : false
};

const memoSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    setNotificationLoading: (state, action) => {
      state.isNotificationsLoading = action.payload;
    },
    sidebarUpdateAck : (state,action) => {
      state.updateSidebar = action.payload;
    }
    // this needs to change

    //  case MEMO_RECEIVED: {
    //       const { memo: memoState } = state;
    //       const { uid } = state;
    //       let memoDoc = action.data;
    //       if (memoDoc.receiver != uid) {
    //         return state;
    //       }
    //       const pendingMemos = Array.isArray(memoState.notifyMemos)
    //         ? memoState.notifyMemos.slice()
    //         : [];
    //       toast.info("You have received a new memo!");
    //       return {
    //         ...state,
    //         memo: {
    //           ...memoState,
    //           notifyMemos: pendingMemos.concat([action.data]),
    //         },
    //         updateSidebar: true,
    //       };
    //     }
  },
  extraReducers: (builder) => {
    builder
      .addCase(notificationService.pending, (state) => {
        state.isNotificationsLoading = true;
      })
      .addCase(notificationService.fulfilled, (state, action) => {
        state.notifications = action.payload?.notifications;
        state.notificationLoadMore = action.payload
          ?.notificationLoadMore as boolean;
        state.offset = 0;
        state.pendingActionNotificationCount =
          action.payload?.pendingActinoNotificationCount;
        state.notifyMemos = action.payload?.notifyMemos;
        state.isNotificationsLoading = false;
      })
      .addCase(notificationService.rejected, (state) => {
        state.notificationLoadMore = false;
        state.isNotificationsLoading = false;
      })
      .addCase(loadMoreNotifications.fulfilled, (state, action) => {
        if (action.payload && action.payload.offset > 0) {
          return {
            ...state,
            notifications: [
              ...state.notifications,
              ...action.payload.notifications,
            ],
            notificationLoadMore: action.payload.notificationLoadMore,
            isNotificationsLoading: false,
          };
        }
        state.notifications = action.payload?.notifications;
        state.notificationLoadMore = action.payload
          ?.notificationLoadMore as boolean;
      })
      .addCase(
        updatePendingActionNotificationCountAction.fulfilled,
        (state, action) => {
          state.pendingActionNotificationCount = action.payload;
        }
      );
  },
});

export const { setNotificationLoading,sidebarUpdateAck } = memoSlice.actions;

export default memoSlice.reducer;

export const selectRight = (state: RootState) => state.branch;
// export const selectIsLoading = (state: RootState) => state.user.isLoading;
// export const selectError = (state: RootState) => state.user.error;
