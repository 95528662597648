import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDepartmentList, getCity, getUserDetails } from "./api";
import { booleanSchema, validate, validationSchema } from "./helper";
import { isArrayCheck } from "../../../containers/generics/CheckArray";
import { UserFormType } from "./userForm.type";
import { get, set } from "lodash";

const initialState: UserFormType = {
  values: {
    _id: "",
    type: "add",
    contact: "",
    maxBalance: "",
    name: {
      fName: "",
      // mName: '',
      lName: "",
    },
    role: "",
    dob: new Date(),
    doj: "",
    address: {
      l1: "",
      l2: "",
      city: "",
      pincode: "",
    },
    active: false,
    photoReq: false,
    pendingPaidLeaveCount: 0,
    docVerified: false,
    password: "",
    email: "",
    company: "",
    assignedBranch: [],
    assignedFleet: [],
    assignedDepartment: [],
    allbranches: [],
    userShiftDuty: {
      startTime: "",
      endTime: "",
    },
    businessType: "",
    overhead: "",
    userTypeId: "",
    expenseDepartment: "",
    reportingManager: "",
    referenceUsers: [],
    userRef1Doc1: [],
    userRef1Doc2: [],
    userRef2Doc1: [],
    userRef2Doc2: [],
    bankDetails: {
      bankName: "",
      beneficiary: "",
      accountNumber: "",
      ifscCode: "",
    },
    designation: "",
    department: "",
    reportingBranch: "",
  },
  assignedBranches: [],
  departMentList: [],
  errors: { ...booleanSchema(true) },
  touched: { ...booleanSchema(false) },
};

const userFormSlice = createSlice({
  name: "userForm",
  initialState,
  reducers: {
    changeFlat: {
      reducer: (state, action: PayloadAction<{ key: string[]; val: any }>) => {
        const { key, val } = action.payload;
        set(state, ["values", ...key], val);
        set(state, ["touched", ...key], true);
        set(
          state,
          ["errors", ...key],
          validate(get(validationSchema, key), val)
        );
      },
      prepare: (key: string | string[], val: any) => ({
        payload: { key: Array.isArray(key) ? key : [key], val },
      }),
    },
    resetUserForm: {
      reducer: (state, action: PayloadAction<{ contact?: string }>) => {
        let contact = action.payload?.contact;
        state.values = {
          ...initialState.values,
          ...(contact ? { contact } : {}),
        };
        state.errors = { ...booleanSchema(true) };
      },
      prepare: (contact?: string) => ({
        payload: { contact },
      }),
    },
    setUserError: (state, action) => {
      const { key } = action.payload;
      set(state.errors, key, validate(get(validationSchema, key), ""));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.fulfilled, (state, action) => {
        const { branches, fleets, departments } = action.payload;
        console.log(action.payload, "data contact");
        const values = {
          type: "edit",
          ...action.payload,
          userRef1Doc1: [],
          userRef1Doc2: [],
          userRef2Doc1: [],
          userRef2Doc2: [],
          companyId: action.payload.company,
          pendingPaidLeaveCount: action.payload.pendingPaidLeaveCount,
          designation: action.payload.designation,
          department: action.payload.department,
          reportingBranch: action.payload.reportingBranch,
          userShiftDuty: action.payload.userShiftDuty
            ? {
                startTime: action.payload.userShiftDuty.startTime,
                endTime: action.payload.userShiftDuty.endTime,
              }
            : { startTime: "", endTime: "" },
          bankDetails: action.payload.bankDetails
            ? {
                bankName: action.payload.bankDetails.bankName,
                beneficiary: action.payload.bankDetails.beneficiary,
                accountNumber: action.payload.bankDetails.accountNumber,
                ifscCode: action.payload.bankDetails.ifscCode,
              }
            : {
                bankName: "",
                beneficiary: "",
                accountNumber: "",
                ifscCode: "",
              },
          assignedBranch: isArrayCheck(branches)
            ? branches.map((b: { branchName: string; _id: string }) => ({
                label: b.branchName,
                value: b._id,
              }))
            : [],
          assignedFleet: isArrayCheck(fleets)
            ? fleets.map((f: { regNumber: string; _id: string }) => ({
                label: f.regNumber,
                value: f._id,
              }))
            : [],
          assignedDepartment: isArrayCheck(departments)
            ? departments.map((f: { departmentName: string; _id: string }) => ({
                label: f.departmentName,
                value: f._id,
              }))
            : [],
        };
        set(state, ["values"], { ...values });
        set(state, ["errors"], { ...booleanSchema(true) });
      })
      .addCase(getCity.fulfilled, (state, action) => {
        set(state, ["values", "address", "city"], action.payload);
      })
      .addCase(fetchDepartmentList.fulfilled, (state, action) => {
        const temp = isArrayCheck(action.payload)
          ? action.payload.map(
              (a: { departmentName: string; _id: string }) => ({
                label: a.departmentName,
                value: a._id,
              })
            )
          : [];
        state.departMentList = temp;
      });
  },
});

export const { changeFlat, resetUserForm, setUserError } =
  userFormSlice.actions;

export default userFormSlice.reducer;
