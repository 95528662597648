import { createAsyncThunk } from "@reduxjs/toolkit";
import showMessage, { showHttpError, success } from "../../../utils/message";
import {
  createBranchService,
  deleteBranchService,
  filterBookBranches,
  listBranch,
  viewBranch,
} from "../../../services/branch";
import { setAllPlatformBranchesData, setBranchesData, setFilteredBranch } from "./branchSlice";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import jsonToFormdata from "../../../utils/jsonToFormdata";
import { getAllBranches } from "../../../services/branchRank";
import { RootState } from "../../store";

export const setBranches = createAsyncThunk(
  "branch/branchList",
  async (_, { dispatch }) => {
    try {
      const response = await listBranch();
      dispatch(setBranchesData(response));
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const setAllPlatformBranches = createAsyncThunk(
  "branch/allPlatformBranchList",
  async (_, { dispatch }) => {
    try {
      const response = await getAllBranches();
      dispatch(setAllPlatformBranchesData(response))
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const listFilteredBranches = createAsyncThunk(
  "branch/filteredBranchList",
  async (company: string, { dispatch }) => {
    try {
      const response = await filterBookBranches({ company });
      const payload = { data: response, query: company };
      dispatch(setFilteredBranch(payload));
    } catch (err: any) {
      showHttpError(err);
      console.log(err);
    }
  }
);

export const fetchBranch = createAsyncThunk(
  "branch/fetchBranch",
  async (bid: string) => {
    try {
      const response = await viewBranch(bid);
      if (
        response.rented &&
        response.rented.rentDate &&
        response.rented.rentDate.length > 10
      ) {
        response.rented.rentDate = response.rented.rentDate.substring(0, 10);
      }
      return response;
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const branchCreateReq = createAsyncThunk(
  "branch/branchCreateReq",
  async (payload: string, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const branches = state.user?.branches;
      dispatch(showLoader());
      const response = await createBranchService(jsonToFormdata(payload));
      const data : any = {
        response: response,
        branchesArr: [...branches],
      };
      return data;
    } catch (err) {
      showHttpError(err);
      return err;
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "branch/deleteBranch",
  async (payload: { id: string; data: any }, { dispatch }) => {
    const { id, data } = payload;
    try {
      dispatch(showLoader());
      const response = await deleteBranchService(id, data);
      showMessage("Branch Deleted successfully", success);
      return response;
    } catch (err) {
      showHttpError(err);
    } finally {
      dispatch(hideLoader());
    }
  }
);
