import { ServiceMaker, buildQueryString } from "./index";

export interface ListTaskServiceQuery {
    search: string;
    page: number;
    pageSize: number;
    length?: number;
    boardSection?: number;
  }

export type ListTaskServiceResp = {
    _id: string;
    title: string;
    board?:any
    boardSection?:any
  };
  
export const getViewTaskList = (data:any) =>
ServiceMaker(`task/list`, "POST" , data);

export const getSubTaskListFromMainTaskId = (id:any, data: any) =>
ServiceMaker(`task/${id}?${buildQueryString(data  || {})}`, "GET");

export const getMainTaskFilteredList = (data:any) =>
ServiceMaker(`task/list`, "POST" , data);

export const getSubTaskViewTaskList = (data : any) =>
ServiceMaker(`subTask/list`, "POST" , data);

export const updateSubtaskAttribute = (id : any ,data : any) =>
ServiceMaker(`subTask/update/attributes/${id}`, "PATCH" , data);

export const getSubTaskFilteredList = (data:any) =>
ServiceMaker(`subTask/list`, "POST" , data);
export const createBoard = (data:any) =>
ServiceMaker(`board/create`, "POST" , data);
export const boardList = (data: any) =>
ServiceMaker(`board/list/?${buildQueryString(data  || {})}`, "GET");

export const createTask = (data:any) =>
  ServiceMaker(`task/create`, "POST",data);
export const boardSectionCreate = (data: any) =>
  ServiceMaker("boardSection/create", "POST",data);

export const boardSectionEdit = (id:any,data: any) =>
  ServiceMaker(`boardSection/update/${id}`, "PUT",data);
export const boardSectionsList = (data: any) =>
  ServiceMaker(`boardSection/list/detailed/?${buildQueryString(data  || {})}`, "GET");
export const boardSectionUpdateIndex = (id:any,data: any) =>
  ServiceMaker(`boardSection/index/${id}`, "PATCH",data);



export const updateTask = (data:any) =>
ServiceMaker(`task/update`, "PUT",data);

export const updateSubTaskApi = (data:any) =>
ServiceMaker(`subTask/update`, "PUT",data);

export const processSubTaskApi = (data:any) =>
ServiceMaker(`subTask/process`, "PATCH",data);

export const getSubTaskById = (id:any) =>
ServiceMaker(`subTask/${id}`, "GET")

export const getAllMainTaskList = (data?:ListTaskServiceQuery) =>
ServiceMaker(`task/mainTaskList?${buildQueryString(data  || {})}`, "GET")