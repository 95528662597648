import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHttpError } from "../../../utils/message";
import { RootState } from "../../store";
import { getStockCount } from "../../../services/verifyStock";
import { getTableCashCount } from "../../../services/TableCashList";
import { getShortageCount } from "../../../services/settledAmount";
import {
  fetchUnverifiedDocketCount,
  getUnverifiedExpenseCount,
} from "../../../services/verifyPOD";
import {
  getGraceTowerCount,
  getOperationRequestCount,
  getOperationsCount,
} from "../../../services/operationsCount";
import { getCompaintCount, getComplaintsCounts } from "../../../services/complaint";
import { countSettlementExpenseAmount } from "../../../services/vendor";
import { getCameraActivityStatus } from "../../../services/dashboard2";
import { getRightsCityCompanyWise } from "../../../services/cityWiseRights";
import { allIssueCounts } from "../../../services/userIssues";
import { getCancelBookingCount } from "../../../services/cancelBooking";

export const sidebareBadgesCount = createAsyncThunk(
  "sidebar/sidebarBadges",
  async ({ userId }: { userId: string }, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const receivedRights = state.rights;
      const receivedAppRights = state.appRights;
      let data: any = {};
      console.log(
        receivedRights,
        "receivedRights",
        receivedAppRights,
        "receivedAppRights"
      );
      if (receivedRights.verification.verifiedPackages === true) {
        const stockResponse = await getStockCount();
        data = {
          ...data,
          verifyStockCount: stockResponse,
        };
      }

      if (receivedRights.verification.controlTower === true) {
        const res = await graceToweCount();
        data = {
          ...data,
          graceTowerCountData: res,
        };
      }

      if (receivedAppRights.verification.pettyCash === true) {
        const cashResponse = await getTableCashCount();
        data = {
          ...data,
          tableCashCount: cashResponse.tableCashCount,
        };
      }

      if (receivedRights.verification.verifySettledAmount === true) {
        const shortageResponse = await getShortageCount();
        data = {
          ...data,
          shortageCount: shortageResponse,
        };
      }

      if (Boolean(receivedRights.verification.expense) === true) {
        const res = await updateVerifyExpenseBadges(userId);
        data = {
          ...data,
          verifyExpenseBagdeCount: res,
        };
      }

      if (receivedRights.verification.pod.verifyPOD === true) {
        const res = await fetchUnverifiedDocketCount();
        data = {
          ...data,
          fetchUnverifiedDocketCountData: res,
        };
      }
      return data;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const graceToweCount = async () => {
  try {
    // const { loading } = getState().rights.operations;
    // if (g {

    let operationPayload: any = {};
    operationPayload = {
      // entity:
      //   getState().user.loginType === "B"
      //     ? getState().user.opBranch._id
      //     : getState().user.opFleet._id,
      // sub: getState().user.loginType,

      company: true,
      user: true,
      creditCoustomer: true,
      branch: true,
      fleet: true,
    };

    const graceTowerData = await getGraceTowerCount(operationPayload);
    return graceTowerData;
    // }
  } catch (err: any) {
    showHttpError(err);
  }
};

export const updateVerifyExpenseBadges = async (userId: string) => {
  try {
    const payload = {
      user: userId,
    };
    const {
      // total,
      pendingVerify,
      pendingVoucherImage,
    } = await getUnverifiedExpenseCount(payload);
    return {
      pendingVerify,
      pendingVoucherImage,
    };
  } catch (err: any) {
    showHttpError(err);
  }
};

export const updateFeedbackCount = createAsyncThunk(
  "sidebar/feedbackCount",
  async (payload : any, { dispatch }) => {
    try {
      const stockResponse =
        await getCompaintCount(payload);
      return stockResponse;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const getFleetOrderRightsData = createAsyncThunk(
  "sidebar/fleetOrderRightsData",
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      let payloadData = { company: state.user.opBranch._id }
      const stockResponse = await getRightsCityCompanyWise(payloadData);
      console.log(`mabvmndfbnvjzdnfv`, stockResponse)
      return stockResponse?.data;
    } catch (err: any) {
      showHttpError(err);
    } finally {
      dispatch({ type: "END_LOADER" });
    }
  }
);

export const updateCameraStatusDetails = createAsyncThunk(
  "sidebar/updateCameraStatusDetails",
  async (data: any, { }) => {
    try {
      let payload: any = {
        branch: data,
        date: new Date(),
      }
      let response = await getCameraActivityStatus(payload);
      return response?.processStatus;
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const updateComplaintsChatBadges = createAsyncThunk(
  "sidebar/updateComplaintsChatBadges",
  async (data: any, { }) => {
    try {
      let payload: any = {
        userId: data
      }
      let response = await getComplaintsCounts(payload);
      return response[0];
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const updateOperationsDeliveryCount = createAsyncThunk(
  "operations/updateDeliveryCount",
  async (_, { getState }) => {
    try {
      const state = getState() as any;
      const delivery = state?.rights?.operations?.delivery;

      if (state.user.loginType === "B" && delivery) {
        const operationPayload = {
          branch: state.user.opBranch._id,
          delivery: true,
          outforDelivery: true,
        };

        const response = await getOperationsCount(operationPayload);
        return response;
      }
      return null;
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const updateVerifyCancelBookingBadges = createAsyncThunk(
  "sidebar/updateVerifyCancelBookingBadges",
  async (data: any, { }) => {
    try {
      const payload = {
        sub: data?.sub,
        entity: data?.entity,
      };
      let response = await getCancelBookingCount(payload);
      return response;
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const OperationsRequestCount = createAsyncThunk(
  "sidebar/OperationsRequestCount",
  async (operationData: any = "NoData", { getState }) => {
    try {
      const state = getState() as RootState;
      // const { loading } = getState().rights.operations;
      // if (g {
      const operationsRights = state.rights.operationRequest;

      let operationPayload: any = {};
      operationPayload = {
        entity:
          state.user.loginType === "B"
            ? state.user.opBranch._id
            : state.user.opFleet._id,
        sub: state.user.loginType,
        BTH: operationsRights.backToHub.view
          ? operationData == "NoData"
            ? true
            : operationData?.BTH
              ? true
              : false
          : false,
        ND: operationsRights.changeDestination.view
          ? operationData == "NoData"
            ? true
            : operationData?.ND
              ? true
              : false
          : false,
        demurrage: operationsRights.damurrage.view
          ? operationData == "NoData"
            ? true
            : operationData?.demurrage
              ? true
              : false
          : false,
        remarks: operationsRights.remarksRequest.view
          ? operationData == "NoData"
            ? true
            : operationData?.remarks
              ? true
              : false
          : false,
        discount: operationsRights.discount.view
          ? operationData == "NoData"
            ? true
            : operationData?.discount
              ? true
              : false
          : false,
        doorDeliveryDiscount: operationsRights.discount.view
          ? operationData == "NoData"
            ? true
            : operationData?.doorDeliveryDiscount
              ? true
              : false
          : false,
        customerConnect:
          operationData == "NoData"
            ? true
            : operationData?.customerConnect
              ? true
              : false,
        claim: operationsRights.claimSettlement.view
          ? operationData == "NoData"
            ? true
            : operationData?.claim
              ? true
              : false
          : false,
        parcelTransfer: operationsRights.parcelTransferRequest.view
          ? operationData == "NoData"
            ? true
            : operationData?.parcelTransfer
              ? true
              : false
          : false,
        cancelDoorDeliveryBooking:
          operationData == "NoData"
            ? true
            : operationData?.cancelDoorDeliveryBooking
              ? true
              : false,
        cancelBooking: operationsRights.cancelBookings.view
          ? operationData == "NoData"
            ? true
            : operationData?.cancelBooking
              ? true
              : false
          : false,
        fleetOrderRecheckIn: operationsRights.reCheckInReq.view
          ? operationData == "NoData"
            ? true
            : operationData?.fleetOrderRecheckIn
              ? true
              : false
          : false,
        branchDelivery: operationsRights.branchDelivery.view
          ? operationData == "NoData"
            ? true
            : operationData?.branchDelivery
              ? true
              : false
          : false,
        packageUpdate: operationsRights.packageUpdateRequestList.view
          ? operationData == "NoData"
            ? true
            : operationData?.packageUpdate
              ? true
              : false
          : false,
      };

      const response = await getOperationRequestCount(operationPayload);
      return response;
    } catch (err: any) { }
  }
);

export const updateOperationsVerifystocksCount = createAsyncThunk(
  "sidebar/updateOperationsVerifystocksCount",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const verifyPackages = (state.rights as any)?.operations?.verifyPackages;
      if (state.user.loginType === "B" && verifyPackages) {
        let operationPayload: any = {};
        operationPayload = {
          branch: state.user.opBranch._id,
          verifyStock: true,
        };

        const res = await getOperationsCount(operationPayload);
        return res;
      }
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const updateAllIssueCounts = createAsyncThunk(
  "sidebar/updateAllIssueCounts",
  async () => {
    try {
      let ans = await allIssueCounts();
      return ans;
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const updateVerifyShortageBadges = createAsyncThunk(
  "sidebar/updateVerifyShortageBadges",
  async () => {
    try {
      let ans = await getShortageCount();
      return ans;
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const updateOperationsPendingPODCount = createAsyncThunk(
  "sidebar/updateOperationsPendingPODCount",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const delivery = (state.rights as any).operations?.delivery;
      if (state.user.loginType === "B" && delivery) {
        let operationPayload: any = {};
        operationPayload = {
          branch: state.user.opBranch._id,
          pendingPOD: true,
        };
        const res = await getOperationsCount(operationPayload);
        return res;
      }
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const updateOperationsUnloadingCount = createAsyncThunk(
  "sidebar/updateOperationsUnloadingCount",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const { unloading } = (state.rights as any)?.operations;
      if (state.user.loginType === "B" && unloading) {
        let operationPayload: any = {};
        operationPayload = {
          branch:
            state.user.loginType === "B"
              ? state.user.opBranch._id
              : state.user.opFleet._id,
          unloading: true,
        };
        const res = await getOperationsCount(operationPayload);
        return res;
      }
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const creditorSettlememtCount = createAsyncThunk(
  "sidebar/creditorSettlememtCount",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const operationPayload = {
        entity:
          state.user.loginType === "B"
            ? state.user.opBranch._id
            : state.user.opFleet._id,
        sub: state.user.loginType,
      };

      const response = await countSettlementExpenseAmount(operationPayload);
      return response;
    } catch (err: any) {
      showHttpError(err);
    }
  }
);

export const updateOperationsLoadingCount = createAsyncThunk(
  "sidebar/updateOperationsLoadingCount",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const { loading } = (state.rights as any)?.operations;
      if (state.user.loginType === "B" && loading) {
        let operationPayload: any = {};
        operationPayload = {
          branch:
            state.user.loginType === "B"
              ? state.user.opBranch._id
              : state.user.opFleet._id,
          loading: true,
        };
        const response = await getOperationsCount(operationPayload);
        return response;
      }
    } catch (err: any) { }
  }
);

export const updateOperationsAllCount = createAsyncThunk(
  "sidebar/updateOperationsAllCount",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      if (state.user.loginType === "B") {
        const { loading, unloading, delivery, verifyPackages } = (
          state.rights as any
        )?.operations;

        let operationPayload: any = {};
        operationPayload = {
          branch: state.user.opBranch._id,
        };
        if (loading) {
          operationPayload.loading = true;
        }
        if (unloading) {
          operationPayload.unloading = true;
        }
        if (delivery) {
          operationPayload.delivery = true;
          operationPayload.outforDelivery = true;
          operationPayload.pendingPOD = true;
        }
        if (verifyPackages) {
          operationPayload.verifyStock = true;
        }

        const res = await getOperationsCount(operationPayload);
        return res;
      }
    } catch (err: any) {
      showHttpError(err);
    }
  }
);
