import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  friendAddressSuggestionAction,
  loginCustomer,
  setCustomerDetails,
} from "./api";
import { LoginState } from "./customer.type";

const initialState: LoginState = {
  isLoading: false,
  error: null,
  addressSuggetionData: [],
  friendAddressSuggetionData: [],
  _id: "",
  name: "",
  names: [],
  contact: 0,
  address: {
    pincode: 0,
    city: undefined,
  },
  referenceUser: undefined,
  connectedContacts: undefined,
  branch: undefined,
  customerType: "",
  panCardImage: "",
  adhaarCardImage: "",
  adhaarIdentity: "",
  panIdentity: "",
  panCardImageVerified: false,
  adhaarCardImageVerified: false,
  gst: "",
  gstVerified: false,
  code: "",
  gstNos: [],
  contactVerified: false,
  active: false,
  secondaryCustomers: [],
  creditData: [],
  token: "",
  accessToken: "",
  refreshToken: "",
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    customerLogoutReducer: (state) => {
      localStorage.removeItem("loginBy");
      localStorage.removeItem("kabra:customertoken");
      // sessionStorage.setItem("kabra:user", customer._id);
      localStorage.removeItem("kabra:customer");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("refreshTokenExpiresAt");
      localStorage.removeItem("accessTokenExpiresAt");
      Object.assign(state, {});
    },
    customerDocument: (state, action: PayloadAction<{ panCardImage: string; adhaarCardImage: string; panIdentity: string; adhaarIdentity: string }>) => ({...state, ...action.payload })
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginCustomer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginCustomer.fulfilled, (state, action) => {
        // if (typeof action.payload !== "boolean") {
        const data = {
          ...action.payload.customer,
          token: action.payload.token,
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          isLoading: false,
        };
        Object.assign(state, data);
        // }
      })
      .addCase(loginCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Login failed";
      })
      .addCase(friendAddressSuggestionAction.fulfilled, (state, action) => {
        if (action.payload?.length) {
          state.friendAddressSuggetionData = action.payload;
        }
      })
      .addCase(setCustomerDetails.fulfilled, (state, action) => {
        Object.assign(state, {
          ...state,
          ...action.payload?.customer,
        });
      });
  },
});

export const { customerLogoutReducer, customerDocument } = customerSlice.actions;

export default customerSlice.reducer;

export const selectUser = (state: RootState) => state.user.user;
export const selectIsLoading = (state: RootState) => state.user.isLoading;
export const selectError = (state: RootState) => state.user.error;
