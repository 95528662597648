import { ServiceMaker, buildQueryString } from './index'

export const listAllNewRoutes = (data?:any) => ServiceMaker(`newroute/list?${buildQueryString(data)}`, 'GET');

export const listNewRoutesByCompany = (data?:any) => ServiceMaker(`newroute/list/company?${buildQueryString(data)}`, 'GET');

export const listAllRouteReport = (date? : any) => ServiceMaker(`newroute/listAllRouteReport?${buildQueryString(date)}`, 'GET');

export const listAffectingInActiveRoute = (id? : any) => ServiceMaker(`newroute/routeAndServiceListByRouteId/${id}`, 'GET');

export const routeReportById = (id : any) => ServiceMaker(`newroute/routeReport/${id}`, 'GET');

export const createNewRoutes = (data : any) => ServiceMaker('newroute/create', 'POST' , data);

export const updateNewRoute = (id:string, data:any) => ServiceMaker(`newroute/update/${id}`, 'PUT', data);

export const createdRoutesList = (data? :any) => ServiceMaker(`newroute/routeToBeCreatedList?${buildQueryString(data)}`, 'GET');

export const getRouteCitiesByCompany = (data? :any) => ServiceMaker(`newroute/getRouteCitiesByCompany?${buildQueryString(data)}`, 'GET');

