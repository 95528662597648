import { createSlice, current } from "@reduxjs/toolkit";
import { LoginState } from "./user.type";
import {
  addAttendanceServiceAction,
  getMyOdaPackages,
  getRoutesAction,
  getVerifyList,
  individualOpBranch,
  loginAdditionalApis,
  loginUser,
  logoutUser,
  setRoutesAction,
} from "./api";
import { RootState } from "../../store";
import { isArrayCheck } from "../../../containers/generics/CheckArray";

export type DataType = {
  isLoading: string;
  token: string;
  accessToken: string;
  refreshToken: string;
  userName: string;
  allowAttendanceFromAnywhere: boolean;
} & LoginState;

const initialState: LoginState = {
  isLoading: false,
  error: null,
  user: null,
  accessToken: null,
  allowAttendanceFromAnywhere: false,
  latitude: null,
  longitude: null,
  refreshToken: null,
  userName: null,
  soundStatus: "",
  pendingBalance: 0,
  trackingNumber: "",
  expenseHandlingData: "",
  maxBalance: 0,
  CCAvenueTxns: 0,
  allowFlexibleHour: false,
  startLoader: false,
  verifyList: [],
  verifyRemarks: "",
  userShiftStart: "",
  userShiftEnd: "",
  thisMonthAttendanceCount: 0,
  attendance: false,
  attendancePopup: false,
  loginType: "B",
  userType: {
    _id: "",
    typeName: "",
    allowAttendanceFromAnywhere: false,
    allowFlexibleHour: false,
    attendanceRequired: false,
    allowShortBreak: false,
  },
  uid: "",
  token: "",
  contact: "",
  remarks: "",
  opBranch: {},
  opFleet: {
    assetSubType: {
      assetType: {},
    },
    // allowedOFDCities : false
  },
  company: {
    id: "",
    name: "",
    _id: "",
    transhipment: {
      rate: {
        perPackage: [],
        kgWise: [],
      },
    },
    address: {
      l1: "",
      l2: "",
      city: "",
      pincode: "",
    },
    bilty: {
      interState: 0,
      intraState: 0,
      interNational: 0,
    },
    insurance: {
      owner: [],
      carrier: [],
    },
    individual: false,
    individualFleet: false,
    demurrageThreshold: 0,
    companyName: "",
    panNumber: "",
    bankAccount: 0,
    ifscCode: "",
    handling: [],
    demurrage: [],
    sizes: [],
    designation: [],
    col: [],
    cod: [],
    packaging: [],
    contactPerson: {
      name: {
        fName: "",
        lName: "",
      },
      contact: "",
    },
    twoFactorAuthentication: false,
    displayName: "",
    shortCode: "",
    cameraStatus: false,
    phoneStatus: false,
    userAttendance: false,
    userVerification: false,
    deliveryWithQr: false,
    otpVerification: false,
    photoVerification: false,
    allDocketVerification: false,
    withoutEWayBillDocketVerification: false,
    branchTiming: false,
    themePreference: {
      clrPrimaryHue: 204,
      clrSecondaryHue: 204,
    },
    autoEwayBillPartBVerification: false,
    cashDemurrageDiscount: false,
    cashDiscount: false,
    eWaybillValidityCheck: false,
    backToHub: false,
    settledBranch: {},
  },
  branches: [],
  rights: null,
  routes: null,
  allcities: [],
  assign: {
    branches: null,
    departments: null,
    fleets: null,
  },
  balanceDialog: false,
  docketMapRegNumWise: {},
  allBranchBalance: "",
  totalBalance: "",
  colMonth: 0,
  customPack: null,
  docketMap: null,
  docketReRenderMap: {},
  assignroute: "",
  docketNumber: "",
  colReport: {
    bookings: [],
    expenses: [],
    deliveries: [],
    sumOfExpense: 0,
    sumOfGross: 0,
  },
  remainingLimit: 0,
  loading: false,
  loaderAcrossAmount: false,
  loadingWithOutBlur: false,
  showAttachmentOptions: false,
  pendingPaidLeaveCount: 0,
  isBreakInBreakOutDialogOpen: false,
  role: "S",
  todayCheckIn: null,
  fullName: "",
  fleets: [],
  userTypeForOrganogram: {
    _id: "",
    typeName: "",
    allowAttendanceFromAnywhere: false,
    allowFlexibleHour: false,
    attendanceRequired: false,
    allowShortBreak: false,
  },
  documents: {
    user: {
      aadharCard: false,
      licence: false,
      panCard: false,
    },
    fleet: {},
  },
  socketLockAcquired: true,
  allBalances: [],
  citiesMap: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLatitude: (state, action) => {
      state.latitude = action.payload;
    },
    setLongitude: (state, action) => {
      state.longitude = action.payload;
    },
    logout: () => {
      // Clear all state and localStorage
      sessionStorage.clear();
      return initialState;
    },
    setUserAllBalances: (state, action) => {
      state.allBalances = action.payload;
    },
    setCitiesData: (state, action) => {
      const cities = action.payload;
      const citiesMap = new Map();
      if (isArrayCheck(cities)) {
        cities.forEach((c: any) => {
          if (!citiesMap.has(c._id)) {
            citiesMap.set(c._id, c);
          }
        });
      }
      state.allcities = cities;
      state.citiesMap = citiesMap;
    },
    setOperatingBranch: (state, action) => {
      state.opBranch = action.payload;
      state.loginType = "B";
    },
    setOperatingFleet: (state, action) => {
      state.opFleet = action.payload?.opFleet;
      state.loginType = "F";
    },
    setLastStatusAttendence: (state, action) => {
      state.attendance = action.payload;
    },
    setUpdateSoundStatus: (state) => {
      state.soundStatus = "PLAYING";
    },
    setStopSoundStatus: (state) => {
      state.soundStatus = "STOPPED";
    },
    showAllBalanceModal: (state) => {
      state.balanceDialog = true;
    },
    hideAllBalanceModal: (state) => {
      state.balanceDialog = false;
    },
    clearReNumwiseDocket: (state) => {
      state.docketMapRegNumWise = {};
    },
    setTrackingnumber: (state, action) => {
      state.docketNumber = action.payload;
    },
    setAttendance: (state, action) => {
      state.attendance = action.payload;
    },
    setBreakInBreakOutDialogOpen: (state, action) => {
      state.isBreakInBreakOutDialogOpen = action.payload;
    },
    clearSelectedEntity: (state) => {
      state.opBranch = {};
    },
    updateBalance: (state, action) => {
      if (!isNaN(action.payload)) {
        return;
      }

      let { options } = action?.payload;
      const balance1 = action.payload?.balance;
      const { balance, handlingLogs } = options;

      let { loginType, opBranch, opFleet, allBalances } = current(state);

      if (balance && balance.sub && balance.entity) {
        if (allBalances?.length > 0) {
          state.allBalances = allBalances.map((b) =>
            b._id == balance.entity
              ? {
                  ...b,
                  balance:
                    balance.balance || balance1 + (balance.add ? b.balance : 0),
                }
              : b
          );
        }
        state.expenseHandlingData = handlingLogs ? handlingLogs : "";
      }
      if (loginType == "B") {
        let allBalancesUpdated = allBalances.map((balanc: any) => {
          if (
            balanc &&
            balanc._id &&
            balanc._id.length == 24 &&
            balanc._id == opBranch._id
          ) {
            return {
              ...balanc,
              balance: balance1,
            };
          }
          return { ...balanc };
        });
        state.allBalances = allBalancesUpdated;
      }
      if (loginType == "F") {
        let allBalancesUpdated = allBalances.map((balanc: any) => {
          if (
            balanc &&
            balanc._id &&
            balanc._id.length == 24 &&
            balanc._id == opFleet._id
          ) {
            return {
              ...balanc,
              balance: balance1,
            };
          }
          return { ...balanc };
        });
        state.allBalances = allBalancesUpdated;
      }
    },
    missingRemarksChange: (state, action) => {
      state.remarks = action.payload;
    },
    verifyRemarks: (state, action) => {
      state.verifyRemarks = action.payload;
    },
    setUserFleets: (state, action) => {
      state.fleets = action.payload;
    },
    setUserBranches: (state, action) => {
      state.branches = action.payload;
    },
    //expense module
    setColReport: (state, action) => {
      state.colReport = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        if (typeof action.payload !== "boolean") {
          const data = {
            ...action.payload?.user,
            ...action.payload,
            isLoading: false,
            token: action.payload?.token,
            accessToken: action.payload?.accessToken,
            refreshToken: action.payload?.refreshToken,
            userName: action.payload?.userName,
            allowAttendanceFromAnywhere:
              action.payload?.allowAttendanceFromAnywhere,
            user: undefined,
          };
          Object.assign(state, data);
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Login failed";
      })
      .addCase(loginAdditionalApis.fulfilled, (state, action) => {
        const data = {
          loadingReportList: action.payload?.loadingReportList,
          franchiseRequestsNotification:
            action.payload?.franchiseRequestsNotification,
          updateIncentiveAmount: action.payload?.updateIncentiveAmount,
          CCAvenueTxnsCountAmount: action.payload?.CCAvenueTxnsCountAmount,
        };
        state.pendingLoadingReports = data.loadingReportList;
        state.franchiseRequests = data.franchiseRequestsNotification;
        state.incentive = data.updateIncentiveAmount;
        state.CCAvenueTxns = data.CCAvenueTxnsCountAmount as any;
      })
      .addCase(addAttendanceServiceAction.fulfilled, (state, action) => {
        state.thisMonthAttendanceCount = action.payload;
      })
      .addCase(getMyOdaPackages.fulfilled, (state, action) => {
        state.docketMapRegNumWise = action.payload;
      })
      .addCase(getVerifyList.rejected, (state, action) => {
        state.verifyList = action.payload as any[];
      })
      .addCase(setRoutesAction.fulfilled, (state, action) => {
        state.routes = action.payload;
      })
      .addCase(individualOpBranch.fulfilled, (state, action) => {
        state.opBranch = action.payload?.opBranch;
        state.loginType = "B";
      })
      .addCase(getRoutesAction.fulfilled, (state, action) => {
        state.routes = action.payload;
      })
      .addCase(logoutUser.fulfilled, () => {
        sessionStorage.clear();
        return initialState;
      });
  },
});

export const {
  setLatitude,
  setLongitude,
  logout,
  setUserAllBalances,
  setCitiesData,
  setOperatingBranch,
  setOperatingFleet,
  setLastStatusAttendence,
  setUpdateSoundStatus,
  setStopSoundStatus,
  showAllBalanceModal,
  hideAllBalanceModal,
  clearReNumwiseDocket,
  setTrackingnumber,
  setBreakInBreakOutDialogOpen,
  clearSelectedEntity,
  updateBalance,
  setAttendance,
  missingRemarksChange,
  verifyRemarks,
  setUserFleets,
  setUserBranches,
  setColReport
} = userSlice.actions;

export default userSlice.reducer;

export const selectUser = (state: RootState) => state.user.user;
export const selectIsLoading = (state: RootState) => state.user.isLoading;
export const selectError = (state: RootState) => state.user.error;
