import { API_URL } from "../config";
import io from "socket.io-client";

const SOCKET_URL = API_URL[process.env.NODE_ENV as any];

class SocketService {
  private static instance: SocketService;
  private socket: any = null;

  private constructor() {}

  // Singleton Instance
  public static getInstance(): SocketService {
    if (!SocketService.instance) {
      SocketService.instance = new SocketService();
    }
    return SocketService.instance;
  }

  // Initialize socket connection
  public initializeConnection(token: string | null) {
    if (!this.socket) {
      this.socket = io(SOCKET_URL, {
        auth: { token: token ? token : null }, // Pass token for authentication
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        transports: ["websocket"],
        autoConnect: true, // Auto-connect once initialized
      });

      this.setupListeners();
    }
  }

  // Setup event listeners
  private setupListeners() {
    console.log(this.socket,"socket11111111")
    if (!this.socket) return;

    this.socket.on("connect", () => {
      console.log("✅ Connected to WebSocket server");
    });

    this.socket.on("disconnect", (reason: string) => {
      console.log(`❌ Disconnected from WebSocket server: ${reason}`);
    });

    this.socket.on("connect_error", (error: Error) => {
      console.error("❗ Connection error:", error.message);
    });
  }

  // Subscribe to events
  public on(event: string, callback: (data: any) => void) {
    if (!this.socket) return;
    this.socket.on(event, callback);
  }

  // Emit events
  public emit(event: string, data: any) {
    if (!this.socket) return;
    this.socket.emit(event, data);
  }
  // off perticular event
  public off(event: string, callback?: (data: any) => void) {
    if (!this.socket) return;
    this.socket.off(event, callback);
  }

  // Disconnect socket
  public disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      console.log("Socket disconnected.");
    }
  }
}

export default SocketService.getInstance();
