
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHttpError } from "../../../utils/message";
import { listAllUser } from "../../../services/user";

export const setAllPlatformUsersList = createAsyncThunk(
    "set/allPlatformUsersAndDriverList",
    async () => {
        try {
            const result = await listAllUser();
            const onlyDriversArr: any = [];
            result && result.map((r: any) => {
                if (r?.role === "D") {
                    onlyDriversArr?.push({
                        label: r.name ? [r.name.fName, r.name.lName].join(" ") : "N/A",
                        value: r._id,
                    })
                }
            });
            let data = Array.isArray(result)
                ? [
                    ...result.map((r: any) => ({
                        label: r.name ? [r.name.fName, r.name.lName].join(" ") : "N/A",
                        value: r._id,
                    })),
                ]
                : [];
            return { allPlatformUserListData: data, allPlatformDriverListData: onlyDriversArr };
        } catch (err) {
            showHttpError(err);
        }
    }
);
