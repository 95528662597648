import { fromJS } from "immutable";
import * as yup from "yup";

export const booleanSchema = (defaultValue: boolean) => ({
  regNumber: defaultValue,
  modelNumber: defaultValue,
  capacity: defaultValue,
  volume: {
    l: defaultValue,
    b: defaultValue,
    h: defaultValue,
  },
  fleetType: defaultValue,
  bodyType: defaultValue,
  fuelType: defaultValue,
  idealFuel: defaultValue,
  distanceCovered: defaultValue,
  ownerShip: defaultValue,
});

export const days = [
  "mohh",
  "mchh",
  "tohh",
  "tchh",
  "wohh",
  "wchh",
  "thohh",
  "thchh",
  "fohh",
  "fchh",
  "saohh",
  "sachh",
  "suohh",
  "suchh",
];

export const time = yup.date();

export const branchSchemaFieldWise: any = fromJS({
  branchName: yup
    .string()
    .required("Branch name is required")
    .min(3, "Too short")
    .max(40, "Too long"),
  shortName: yup
    .string()
    .required("Short name is required")
    .min(3, "Too short")
    .max(5, "Too long"),
  working: {
    rohh: time,
    rchh: time,
    mohh: time,
    mchh: time,
    tohh: time,
    tchh: time,
    wohh: time,
    wchh: time,
    thohh: time,
    thchh: time,
    fohh: time,
    fchh: time,
    saohh: time,
    sachh: time,
    suohh: time,
    suchh: time,
  },
  pContact: yup
    .string()
    .required("Primary contact is required")
    .matches(/^[\d]{10}$/, "Invalid Primary contact"),
  sContact: yup.string().matches(/^[\d]{10}$/, "Invalid secondary contact"),
  email: yup.string().required("Email is required").email("Invalid email"),
  paid: yup.boolean().required("booking.paid is required"),
  topay: yup.boolean().required("booking.topay is required"),
  credit: yup.boolean().required("booking.credit is required"),
  foc: yup.boolean().required("booking.foc is required"),
  // noncash: yup.boolean().required("booking.noncash is required"),
  dCredit: yup.boolean().required("booking.credit is required"),
  // dNoncash: yup.boolean().required("delivery.noncash is required"),
  isBooking: yup.boolean().required("Booking field is required"),
  isDelivery: yup.boolean().required("Delivery field is required"),
  // isTr: yup.boolean().required('this field is required'),
  // trBy: '',
  // trArr: [],
  ownership: yup
    .string()
    .required("Ownership info required")
    .matches(/^(o|r)$/, "Invalid ownership info"),
  isHub: yup.boolean().required("Please Select hub field"),
  isWarehouse: yup.boolean().required("Please Select isWarehouse field"),
  isIntermediateBranch: yup
    .boolean()
    .required("Please Select isIntermediateBranch field"),
  cams: yup.string().url("invalid Cam url"),

  l1: yup
    .string()
    .required("Address line1 is required")
    .min(3, "Too short")
    .max(65, "Too long"),
  l2: yup
    .string()
    .required("Address line2 is required")
    .min(3, "Too short")
    .max(65, "Too long"),
  pin: yup
    .string()
    .required("Pincode is required")
    .matches(/^[1-9][\d]{5}$/, "Invalid pincode"),
  // maxBalance: yup.string().required("Maximum balance is required"),
  // manager: yup.string().required("Manager is required"),
  city: yup
    .object()
    .shape({
      name: yup.string().required("City name is required"),
      _id: yup.string().required("City _id is required"),
      // Add additional validation rules for other properties of the city object if needed
    })
    .required("City object is required"),
  location: yup.object().shape({
    latitude: yup.number().min(0).max(90),
    longitude: yup.number().min(-180).max(180),
  }),
});

export const procWorking = (working: any) => {
  let { regular, custom } = working;
  let result: any = {};
  let days = [
    { front: "m", name: "mon" },
    { front: "t", name: "tue" },
    { front: "w", name: "wed" },
    { front: "th", name: "thu" },
    { front: "f", name: "fri" },
    { front: "sa", name: "sat" },
    { front: "su", name: "sun" },
  ];
  let dayMap: any = {
    mon: "m",
    tue: "t",
    wed: "w",
    thu: "th",
    fri: "f",
    sat: "sa",
    sun: "su",
  };
  let rohh =
    working.custom.length > 0
      ? new Date(regular.regStart)
      : new Date("2020-10-14T04:30:00.443Z"),
    rchh =
      working.custom.length > 0
        ? new Date(regular.regEnd)
        : new Date("2020-10-14T12:30:00.443Z");

  result["rohh"] = rohh;
  result["rchh"] = rchh;

  days.forEach((day) => {
    result[day.front + "ohh"] = rohh;
    result[day.front + "chh"] = rchh;
  });

  custom.forEach((day: any) => {
    let ohh =
      working.custom.length > 0
        ? new Date(day.cusStart)
        : new Date("2020-10-14T04:30:00.443Z"),
      chh =
        working.custom.length > 0
          ? new Date(day.cusEnd)
          : new Date("2020-10-14T12:30:00.443Z");

    result[dayMap[day["cusDay"]] + "ohh"] = ohh;
    result[dayMap[day["cusDay"]] + "chh"] = chh;
  });

  return result;
};