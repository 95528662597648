import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PayloadType, VerifyStocksSliceType } from "./verifyStocks.type";

const initialState: VerifyStocksSliceType = {};

const verifyStocksSlice = createSlice({
  name: "verifyStocks",
  initialState,
  reducers: {
    getVerifyStocksList: (state, action: PayloadAction<PayloadType>) => {
      const docketNumber = action.payload.docketNumber;
      const packages = action.payload.packages;

      if (!state[docketNumber]) {
        state[docketNumber] = { packagesImage: { images: [] } };
      }
      const existingImages = state[docketNumber]?.packagesImage?.images || [];
      const newImages = packages?.pckData?.pck?.filter((res) => res.img) || [];
      const uniqueImages = [...existingImages, ...newImages].filter(
        (image, index, self) =>
          index === self.findIndex((img) => img._id === image._id)
      );
      state[docketNumber].packagesImage.images = uniqueImages;
    },
    removeVerifyStocksList: (state, action: PayloadAction<PayloadType>) => {
      const docketNumber = action.payload.docketNumber;
      const packages = action.payload.packages;
      if (!state[docketNumber]) {
        state[docketNumber] = { packagesImage: { images: [] } };
      }
      state[docketNumber].packagesImage.images = (
        state[docketNumber]?.packagesImage?.images || []
      ).filter((res: any) => res._id !== packages._id);
    },
    removeAllVerifyStocksList: (state, action) => {
      const docketNumber = action.payload.docketNumber;
      state[docketNumber] = { packagesImage: { images: [] } };
    },
  },
});

export const {
  getVerifyStocksList,
  removeVerifyStocksList,
  removeAllVerifyStocksList,
} = verifyStocksSlice.actions;

export default verifyStocksSlice.reducer;
