import { BooleanSchema } from "./userForm.type";
import * as Yup from "yup";

export const booleanSchema = (defaultValue: boolean) : BooleanSchema => ({
    name: {
      fName: defaultValue,
      mName: defaultValue,
      lName: defaultValue,
    },
    dob: defaultValue,
    doj: defaultValue,
    address: {
      l1: defaultValue,
      l2: defaultValue,
      city: defaultValue,
      pincode: defaultValue,
    },
    role: defaultValue,
    contact: defaultValue,
    maxBalance: defaultValue,
    password: defaultValue,
    email: defaultValue,
    pendingPaidLeaveCount: defaultValue,
    expenseDepartment: defaultValue,
    businessType: defaultValue,
    overhead: defaultValue,
    bankDetails: {
      bankName: defaultValue,
      beneficiary: defaultValue,
      accountNumber: defaultValue,
      ifscCode: defaultValue
    },
    designation: defaultValue,
    docVerified:defaultValue,
    department: defaultValue,
    reportingBranch: defaultValue,
  });

  export const validationSchema = {
    contact: Yup.string()
      .required("Contact number is required")
      .min(10, "Contact must be 10 digits")
      .max(10, "Contact must be 10 digits"),
    maxBalance: Yup.string()
      .required("Maximum balance is required")
      .min(0, "maximum amount can not be negative"),
    name: {
      fName: Yup.string()
        .required("First name is required")
        .min(3, "too short")
        .max(20, "Too long"),
      mName: Yup.string().max(10),
      lName: Yup.string()
        .required("Last name is required")
        .min(3, "too short")
        .max(20, "Too long"),
    },
    address: {
      l1: Yup.string()
        .required("Line 1 address is required")
        .min(3, "too short")
        .max(65, "Too long"),
      l2: Yup.string()
        .required("Line 2 address is required")
        .min(3, "too short")
        .max(65, "Too long"),
      city: Yup.string().required("City is required"),
      pincode: Yup.string()
        .required("Pincode is required")
        .max(10),
    },
    password: Yup.string()
      .required()
      .min(3, "Password should be minimum 6 characters"),
    email: Yup.string()
      .email()
      .required(),
    expenseDepartment: Yup.string(),
    businessType: Yup.string().required("Business type is required"),
    overhead: Yup.string().required("Overhead is required"),
  }
  
  export const validate = (schema: any, obj: any) => {
    try {
      if (!schema) return false;
      schema.validateSync(obj);
      return false;
    } catch (err:any) {
      return err.message;
    }
  };