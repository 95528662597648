import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {};

const paymentGateWaySlice = createSlice({
  name: "paymentGateWay",
  initialState,
  reducers: {
    updateCCAvenueTxnsStutus: (state, action) => {
      Object.assign(state, action.payload);
    },
    resetCCAvenueTxnsStutus: (state) => {
      Object.assign(state, {});
    },
  },
});

export const { updateCCAvenueTxnsStutus, resetCCAvenueTxnsStutus } =
paymentGateWaySlice.actions;

export default paymentGateWaySlice.reducer;
