import { ServiceMaker, buildQueryString } from "./index";

export const createComplaintGuest = (data: any) =>
  ServiceMaker("feedback/guest/complaint", "POST", data);

export const createComplaintUser = (data: any) =>
  ServiceMaker("feedback/user/complaint", "POST", data);

export const addCustomerComplaintForBranchOrder = (data: any) =>
  ServiceMaker(`feedback/customerCreateFeedback`, "POST", data);

export const getGuestComplaint = (data: any) =>
  ServiceMaker(`feedback/guest?${buildQueryString(data)}`, "GET");

export const getComplaintsListing = (data: any) =>
  ServiceMaker(`feedback/listBookingFeedbacks`, "POST", data);

export const getComplaintsCounts = (data: any) =>
  ServiceMaker(`feedback/countUnreadMessages?${buildQueryString(data)}`, "GET");

export const getUserComplaintList = (data: any) =>
  ServiceMaker(`feedback/user`, "POST", data);

export const reOpenCustomerCompalint = (data: any, feedbackNumber: any) =>
  ServiceMaker(`feedback/resendMessage/user/${feedbackNumber}`, "POST", data);

export const getComplaintListdocketDataCount = (data: any) =>
  ServiceMaker(`feedback/docketDataCount`, "POST", data);

export const missingRequestApproval = (id: any, data: any) =>
  ServiceMaker(`operation/missingRequest/process/${id}`, "POST", data);

export const getStepWiseComplaintCount = (data: any) =>
  ServiceMaker(
    `feedback/setpWiseComplaintCount`,
    "POST",
    data
  );

export const getCustomerComplaints = (data: any) =>
  ServiceMaker(
    `feedback/listFeedbacks/forUser`,
    "POST", data
    // data
  );

export const setUnreadMesgAsPerUser = (data: any) => 
  ServiceMaker(`feedback/updatedReadBy`, `PATCH`, data);


export const sendGuestMessage = (feedbackNumber: any, data: any) =>
  ServiceMaker(`feedback/replay/guest/${feedbackNumber}`, "POST", data);

export const sendUserMessage = (feedbackNumber: any, data: any) =>
  ServiceMaker(`feedback/replay/user/${feedbackNumber}`, "POST", data);

// export const customerReviewRating = (data: any) =>
//   ServiceMaker("feedback/guest/reviewRating", "POST", data);

export const customerReviewRating = (data: any) =>
  ServiceMaker("customerReview/addBookingReviewRating", "POST", data);

export const getCompaintCount = (data: any) =>
  ServiceMaker(`feedback/count?${buildQueryString(data)}`, "GET");

export const registerComplaintUserInternally = (data: any) =>
  ServiceMaker("feedback/user/registerComplaint", "POST", data);

export const addEnquiry = (data: any) =>
  ServiceMaker("customer/addEnquiry", "POST", data);

export const getRegisterComplaintChatList = (data: any) =>
  ServiceMaker(`customer/fetchComplaintInquiry?${buildQueryString(data)}`, "GET");

export const getFeedbackComplaintCount = (data: any) =>
  ServiceMaker("feedback/complaintTypeCount", "POST", data);

export const GetCustomerComplaintListOnly = (data: any) =>
  ServiceMaker(`feedback/list?${buildQueryString(data)}`, "GET");

export const getCustomerComplaintCounts = () =>
  ServiceMaker(`feedback/customer/countBookingWise`, "GET");

// export const getFeedbackOfCustomerComplaint = (data : any) =>
//   ServiceMaker(`feedback/listCustomerBookingFeedback?${buildQueryString(data)}`, "GET");

export const getFeedbackOfCustomerComplaint = (data: any) =>
  ServiceMaker(`feedback/listFeedbacks/forCustomer?${buildQueryString(data)}`, "GET");

// export const getCustomerInidvidualChat = (data:any) =>
//   ServiceMaker(`feedback/listCustomerBookingFeedback?${buildQueryString(data)}`, "GET");

export const getInidvidualChat = (data: any) =>
  ServiceMaker(`feedback/listFeedbacks/forCustomer?${buildQueryString(data)}`, "GET");

export const getCustomerInidvidualChat = (data: any) =>
  ServiceMaker(`feedback/listFeedbacks/forCustomer?${buildQueryString(data)}`, "GET");

export const getCityAdminReviewRatingListing = (data: any) =>
  ServiceMaker(`customerReview/listReviewRatingByCustomer`, "POST", data);

export const getCityAdminReviewRatingListingForDriver = (data: any) =>
  ServiceMaker(`driverToCustomerReview/listDriverToCustomerReviewRating`, "POST", data);

export const getCustomerBookingComplaintList = (data: any) =>
  ServiceMaker(`feedback/customer/customerBooking/list?${buildQueryString(data)}`, "GET");

export const getExpressParcelBookingComplaintList = (data: any) =>
  ServiceMaker(`feedback/customer/expressParcelBooking/list?${buildQueryString(data)}`, "GET");

export const docketWiseData = (data: any) =>
  ServiceMaker(`package/getBy/docketNumber?${buildQueryString(data)}`, "GET");

export const branchWisefeedBackReport = (data: any) =>
  ServiceMaker(`report/branchWisefeedBackReport`, "POST", data);

export const branchWiseRatingReport = (data: any) =>
  ServiceMaker(
    `report/branchWiseRatingReport?${buildQueryString(data)}`,
    "GET"
  );

export const branchToBranchComplaintReport = (data: any) =>
  ServiceMaker(
    `report/branchToBranchComplaintReport?${buildQueryString(data)}`,
    "GET"
  );

export const stepWiseComplaintReport = (data: any) =>
  ServiceMaker(
    `report/complaintStepwiseReport?${buildQueryString(data)}`,
    "GET"
  );

export const RemoveResolveIssueAfterReachAtDestBranch = (data: any) =>
  ServiceMaker(`resolveIssue/removeResolveIssue`, "PATCH", data);
