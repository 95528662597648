import { createAsyncThunk } from "@reduxjs/toolkit";
import showMessage, { failed } from "../../../utils/message";
import { RootState } from "../../store";
import socket from "../../../socket/socket";

export const setDriverLocationData = createAsyncThunk(
  "liveLocation/setDriverLocationData",
  async (data: any, { getState }) => {
    try {
      let { liveLocation, user } = getState() as RootState;

      let socketData = {
        ...liveLocation.driverLocation,
        ...data,
        driver: user.uid,
        isStarted: true,
      };
      socket.emit("setLiveDocketTracking", socketData);
      return socketData;
    } catch (error: any) {
      showMessage(error.message, failed);
    }
  }
);
