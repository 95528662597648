import * as Yup from "yup";

// Define types
export type BooleanMap = {
  [key: string]: boolean;
};

// Boolean flag generator for expense validation
export const expense_Boolean = (val: boolean) => ({
  expenseType: val,
  subExpense: val,
  sub: val,
  amt: val,
  route: val,
  assetType: val,
  assetBalance: val,
});

// Expense Validation Schema
export const expenseValidationSchema = Yup.object().shape({
  expenseType: Yup.object({
    value: Yup.string().required("This is a required field"),
  }),
  sub: Yup.string().required("This is a required field"),
  subExpense: Yup.object({
    label: Yup.string().required("This is a required field"),
    value: Yup.string().required("This is a required field"),
  }),
  amt: Yup.string().required("This is a required field"),
  route: Yup.object({
    value: Yup.string().required("This is a required field"),
  }),
  assetType: Yup.object({
    value: Yup.string().required("This is a required field"),
  }),
  assetBalance: Yup.object({
    value: Yup.string().required("This is a required field"),
  }),
});

// Custom Validation Method in Yup
Yup.addMethod(
  Yup.mixed,
  "check",
  function (key: string, message?: string, func?: (value: number) => boolean) {
    return this.test(
      "check",
      message || "Closing kms should be more",
      function (value: any) {
        const context = this.options.context as { [key: string]: number };
        return func ? func(value) : value > context[key];
      }
    );
  }
);

// Final Expense Validation Schema
export const finalExpenseValidation = Yup.object().shape({
  ref: Yup.string().required("Please Select An Expense Type"),
  sub: Yup.string().required("Please Select An Expense Type"),
  sub_val: Yup.string().when("sub", {
    is: (val: string) => val !== "G",
    then: (schema) => schema.required("Please Select Sub Type"),
  }),
  is_route: Yup.boolean(),
  amt: Yup.string().when("sub", {
    is: (val: string) => val !== "F",
    then: (schema) => schema.required("Please Enter Amount"),
  }),
  route: Yup.string().when("is_route", {
    is: true,
    then: (schema) => schema.required("Please Select Route"),
  }),
  op: Yup.string().when("sub", {
    is: (val: string) => val === "F",
    then: (schema) => schema.notRequired(),
  }),
  cl: Yup.string().when("sub", {
    is: (val: string) => val === "F",
    then: (schema) =>
      schema
        .required("Please Enter Closing Kilometers")
        .test(
          "op",
          "Closing Kilometers Should Be Greater than Opening",
          function (value) {
            const op = this.parent.op;
            return parseInt(value, 10) > (parseInt(op, 10) || 0);
          }
        ),
  }),
  ppl: Yup.string().when("sub", {
    is: (val: string) => val === "F",
    then: (schema) => schema.required("Please Enter Price Per Litre"),
  }),
  driver: Yup.string().when("sub", {
    is: (val: string) => val === "F",
    then: (schema) =>
      schema
        .required("Please Enter Driver's Number")
        .length(10, "Contact Should Be 10 digits"),
  }),
  dName: Yup.string().when("sub", {
    is: (val: string) => val === "F",
    then: (schema) => schema.required("Please Enter Valid Driver's Number"),
  }),
  fuelQty: Yup.string().when("sub", {
    is: (val: string) => val === "F",
    then: (schema) => schema.required("Please Enter Fuel Quantity"),
  }),
})

// Boolean flag generator for fuel validation
export const fuel_Boolean = (val: boolean): BooleanMap => ({
  op: val,
  cl: val,
  ppl: val,
  rnum: val,
  driver: val,
  dName: val,
  remarks: val,
  fuelQty: val,
});

// Fuel Expense Validation Schema
export const fuelExpenseValidation = Yup.object().shape({
  op: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(99999999, "Too long"),
  cl: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(99999999, "Too long"),
  ppl: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(9999999, "Too long"),
  rnum: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(50, "Reg number can't be too long"),
  driver: Yup.string()
    .required("This is a required field")
    .length(10, "Contact must be 10 digits"),
  dName: Yup.string()
    .required("This is a required field")
    .length(10, "Contact must be 10 digits"),
  remarks: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters"),
  fuelQty: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(10000, "Too long"),
  paymentType: Yup.mixed().when("paymentMode", {
    is: (val: string) => val === "nonCash",
    then: (schema) => schema.required("Payment Type is required!"),
  }),
  transactionId: Yup.mixed().when("paymentMode", {
    is: (val: string) => val === "nonCash",
    then: (schema) => schema.required("Transaction ID is required!"),
  }),
  transactionImage: Yup.mixed().when("paymentMode", {
    is: (val: string) => val === "nonCash",
    then: (schema) => schema.required("Transaction Image is required!"),
  }),
  vendor: Yup.string().required("This is a required field"),
})

// Validation Function
export const validateExpense = (schema: any, obj: any) => {
  try {
    schema.validateSync(obj, { abortEarly: false });
    return false;
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      return err.errors.join(", ");
    }
    return "Unknown validation error";
  }
};
