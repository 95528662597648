import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  IDepartmentRights,
  processRights,
} from "../../../reducers/utils/ProcessRights";

export interface RightsState extends IDepartmentRights<boolean> {
  role?: "S" | "A" | "O";
}

const initialState: RightsState = processRights([]);

const rightSlice = createSlice({
  name: "rights",
  initialState,
  reducers: {
    setRights: (state, action) => {
      const rights = processRights(action.payload.rights);
      Object.assign(state, {...rights, role: action.payload.role});
    },
  },
});

export const { setRights } = rightSlice.actions;

export default rightSlice.reducer;

export const selectRight = (state: RootState) => state.rights;
// export const selectIsLoading = (state: RootState) => state.user.isLoading;
// export const selectError = (state: RootState) => state.user.error;
