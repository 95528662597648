import { createSlice } from "@reduxjs/toolkit";
import { setAllPlatformUsersList } from "../allPlatformUserList/api";

const initialState : any[] = []; // Explicitly define type

const allPlatformDriverListSlice = createSlice({
    name: "set/allPlatformDriverList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setAllPlatformUsersList.fulfilled, (state, action) => {
            console.log(`Zndc`, state, action);
            return action?.payload?.allPlatformDriverListData ?? []; // Ensure it always returns an array
        });
    },
});

export default allPlatformDriverListSlice.reducer;
