import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SetCustomerTracking } from "./api";
import { CustomerTrackingPayload } from "./customerTracking.type";

const initialState = {}

const customerTrackingSlice = createSlice({
    name: "customerChat",
    initialState,
    reducers: {
        ResetCustomerTracking: (state) => {
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(SetCustomerTracking.fulfilled, (state, action: PayloadAction<CustomerTrackingPayload>) => {
            if (!action.payload) {
                console.error("Empty payload received in SetCustomerTracking");
                return;
            }

            console.log("Redux Update:", action.payload);

            const { docket, bthReqData, source } = action.payload;

            const docketImages = docket?.docketImages?.length
                ? docket.docketImages.map((image: string) => ({ image }))
                : [];

            const bthImages = bthReqData?.length ? bthReqData : [];

            const finalData = {
                ...action.payload,
                gallary: [...docketImages, ...bthImages],
                source, // ✅ Store `source` in Redux state
            };

            // ✅ Efficiently update Redux state
            Object.assign(state, finalData);
        });
    },

});

export const { ResetCustomerTracking } = customerTrackingSlice.actions;

export default customerTrackingSlice.reducer;