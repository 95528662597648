import { createAsyncThunk } from "@reduxjs/toolkit";
import { userAllBalancesService } from "../../services/user";
import { setCitiesData, setUserAllBalances } from "../slices/user/userSlice";
import { showHttpError } from "../../utils/message";
import axios from "axios";
import { baseURL } from "../../axios";
import { isArrayCheck } from "../../containers/generics/CheckArray";
import { RootState } from "../store";

export const fetchUserAllBalances = createAsyncThunk(
  "auth/balance",
  async (id: string, { dispatch, getState }) => {
    userAllBalancesService(id)
      .then((res) => {
        const state = getState() as RootState;
        let response = {
          ...res,
          fleets: state.fleet.fleets,
          branches: state.branch.branchMap,
        };
        console.log(res,"res11111111111")
        const { balances } = response;
        let  company  = state.company as any;
        let allBranchBalance;
        // totalBalance = 0;
        if (isArrayCheck(balances)) {
          allBranchBalance = balances
            .filter((b: any) =>
              b.company && company && company._id && company._id.length == 24
                ? b.company == company._id
                : true
            )
            .map((b: any) => {
              // totalBalance += b.balance;
              if (b.sub == "B") {
                return {
                  balance: b.balance,
                  entity:
                  b?.branch && b?.branch?.branchName ? b?.branch?.branchName : "NA",
                  _id: b.entity,
                  sub: b.sub,
                };
              } else if (isArrayCheck(b?.fleet) && b.sub == "F") {
                let fleet = b?.fleet?.find(
                  (f: { _id: string }) => f._id == b.entity
                );
                return {
                  balance: b.balance,
                  entity: fleet && fleet.regNumber ? fleet.regNumber : "NA",
                  _id: b.entity,
                  sub: b.sub,
                };
              } else {
                return {
                  balance: b.balance,
                  entity: "NA",
                  _id: b.entity,
                };
              }
            }); //.filter(b => b && b.entity != null && b.entity != undefined)
        }
        console.log(allBranchBalance,"allBranchBalance1111")
        dispatch(setUserAllBalances(allBranchBalance));
      })
      .catch((error) => {
        console.log(error);
      });
  }
);

export const setCities = createAsyncThunk(
  "city/cityList",
  async (_, { dispatch }) => {
    const url = baseURL;
    try {
      const res = await axios({
        method: "get",
        url: url + "/city/list",
      });
      dispatch(setCitiesData(res.data.response));
    } catch (error) {
      showHttpError(error);
    }
  }
);
