import { createAsyncThunk } from "@reduxjs/toolkit";
import { getInidvidualChat } from "../../../services/complaint";
import { showHttpError } from "../../../utils/message";
import { GetChatHistoryPayload } from "./customerChat.type";

export const getChatHistory = createAsyncThunk(
    "getChatHistory",
    async (
        payload: GetChatHistoryPayload
    ) => {
        try {
            let response = await getInidvidualChat(payload);
            if (response && response?.feedBackList?.length > 0) {
                let tpayload = {
                    feedbackNumber: response?.feedBackList?.[0].feedbackNumber,
                    docketNumber: response?.feedBackList?.[0].customerDocket.shipmentNumber,
                    userId: null,
                    customerId: response?.feedBackList?.[0].customer._id,
                    chat: response?.feedBackList?.[0].messages
                }
                return tpayload;
            }
        } catch (error) {
            showHttpError(error);
        }
    }
);