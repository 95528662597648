import { configureStore } from "@reduxjs/toolkit";
import {
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
  persistStore,
  persistReducer,
} from "redux-persist";
import rootReducer from "./rootReducer";
import storage from "redux-persist/lib/storage";


const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
  whitelist: ["customerLoginReducer","notification"], // Ensure this is the correct spelling
};

// const production = process.env.NODE_ENV == "production";
// const socketIOMiddleware: any = createSocketIoMiddleware(socket, "server/");

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,
      immutableCheck: false, // Disable ImmutableStateInvariantMiddleware
    }),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof rootReducer>;

export const persistor = persistStore(store);
