import { createAsyncThunk } from "@reduxjs/toolkit";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import showMessage, { failed } from "../../../utils/message";
import { listMissingPackage } from "../../../services/missingList";

export const getMissingList = createAsyncThunk(
  "missing/getMissingList",
  async (data : any, { dispatch }) => {
    try {
      dispatch(showLoader());
      let response = await listMissingPackage(data);
      return response;
    } catch (error: any) {
      showMessage(error || "Couldn't list Missing Package", failed);
    } finally {
      dispatch(hideLoader());
    }
  }
);
