import { createAsyncThunk } from "@reduxjs/toolkit";
import { billCancelService, billGenerateService, billSettleService, createCustomerCreditService, createOrUpdateCustomer, fetchCustomerByContactService, fetchCustomerCreditService, fetchDueCreditService, GetCustomerCredit, getInvoiceService, saveCreditService } from "../../../services/customer";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import showMessage, { failed, showHttpError, success } from "../../../utils/message";
import { fetchFleets } from "../fleet/api";
import { clearCustomer, newCreditSubmit } from "./customerErpSlice";
import jsonToFormdata from "../../../utils/jsonToFormdata";
import { updateBalance } from "../user/userSlice";

export const getInvoiceDetails = createAsyncThunk(
    "getInvoiceDetails",
    async (data: string, { rejectWithValue, dispatch }) => {
        dispatch(showLoader());
        try {
            let response = await getInvoiceService({ receiptNo: data });
            dispatch(hideLoader());
            return response; // Returning the response as the fulfilled payload
        } catch (error) {
            dispatch(hideLoader());
            return rejectWithValue(error); // Handling errors properly
        }
    }
);

export const getInvoiceDetailsForCustomer = createAsyncThunk(
    "getInvoiceDetailsForCustomer",
    async (data: string, { rejectWithValue, dispatch }) => {
        dispatch(showLoader());
        try {
            let response = await GetCustomerCredit({ receiptNo: data });
            dispatch(hideLoader());
            return response; // This will be passed to `fulfilled`
        } catch (error: any) {
            dispatch(hideLoader());
            return rejectWithValue(error); // Handles errors in Redux
        }
    }
);

export const fetchDueCredit = createAsyncThunk(
    "fetchDueCredit",
    async (data: any, { rejectWithValue, dispatch }) => {
        const [id, sub, entity] = data;
        dispatch(showLoader());
        try {
            const response = await fetchDueCreditService({ customer: id, sub, entity });
            dispatch(hideLoader());
            return response;
        } catch (error) {
            dispatch(hideLoader());
            return rejectWithValue(error);
        }
    }
);

export const getCustomerByContact = createAsyncThunk(
    "getCustomerByContact",
    async (contact: any, { dispatch, getState, rejectWithValue }) => {
        console.log(`Payload received: 0.01`, contact);
        dispatch(showLoader());
        try {
            if (String(contact).length !== 10) {
                throw new Error("Not valid contact");
            }

            let state: any = getState();

            const { loginType, opBranch, opFleet } = state.user;
            let responseCredit;

            const response = await fetchCustomerByContactService({ contact });

            try {
                responseCredit = await fetchCustomerCreditService({ contact });
            } catch (err: any) {
                showHttpError(err);
            }

            response["successmsg"] = "Verified";

            if (!state.fleet.fleets) {
                await dispatch(fetchFleets());
            }

            const payloadData = {
                customer: response,
                credit: responseCredit,
                allbranches: (state.branch as any)?.allbranches,
                allfleets: state.fleet?.fleets,
            };

            let data: any = [response._id,
                loginType,
            loginType === "B" ? opBranch._id : opFleet._id]
            dispatch(
                fetchDueCredit(data)
            );

            dispatch(hideLoader());
            return payloadData;
        } catch (err: any) {
            dispatch(hideLoader());
            if (err?.message === "Not valid contact") {
                dispatch(clearCustomer());
            }
            return rejectWithValue(err.message || "An error occurred");
        }
    }
);

export const saveCustomer = createAsyncThunk(
    "saveCustomer",
    async (_, { getState, dispatch, rejectWithValue }) => {
        dispatch(showLoader());
        try {
            const state: any = getState();
            const customerData = { ...state.customer.customer };
            const { connected } = customerData;

            customerData["connected"] =
                connected && connected.length > 0
                    ? connected.map(({ value }: any) => value).filter((v: any) => v && v.length === 24)
                    : [];

            if (!customerData.referenceUser || customerData.referenceUser.length !== 24) {
                delete customerData.referenceUser;
            }

            // Remove unwanted fields before sending
            ["companies", "otherAddr", "oaddr", "__v", "_id"].forEach((key) => delete customerData[key]);

            const response = await createOrUpdateCustomer(jsonToFormdata(customerData));

            if (response.new === true) {
                showMessage("Customer Created Successfully!", success);
            } else {
                showMessage("Customer Edited!", success);
            }
            dispatch(showLoader());
            return response;
        } catch (err: any) {
            dispatch(showLoader());
            showMessage(err.message, failed);
            return rejectWithValue(err.message);
        }
    }
);


export const generateBill = createAsyncThunk(
    "generateBill",
    async (data: any, { getState, dispatch, rejectWithValue }) => {
        try {
            dispatch(showLoader());
            const state: any = getState();
            const { loginType, opBranch, opFleet } = state.user;
            const { customer } = state.customer;

            const customerId = customer._id;

            const dockets = data.dockets.map((ele: any) => ({
                docket: ele.docketDetail?.docketNumber,
                doorDeliveryDocket: ele.docketDetail?.doorDeliveryDocketNumber,
                doorDeliveryCharges: ele.doorDelDocketcreditTxns?.amount,
                packages: Array.isArray(ele.packages)
                    ? ele.packages.map((p: any) => ({
                        _id: p._id,
                        amount: parseInt(p.actAmount || p.amount, 10),
                    }))
                    : [],
                fixed: ele.fixed,
            }));

            const payload = {
                customer: customerId,
                data: dockets,
                loginType,
                branch: opBranch._id,
            };

            await billGenerateService(payload);

            showMessage("Bill Generated!", success, 2000);
            let details = { id: customerId, sub: loginType, entity: loginType === "B" ? opBranch._id : opFleet._id };
            dispatch(fetchDueCredit(details));

            // let details: any = [customerId, loginType, loginType === "B" ? opBranch._id : opFleet._id]
            // dispatch(fetchDueCredit(details));

            dispatch(hideLoader());
        } catch (error: any) {
            dispatch(hideLoader());
            showMessage(error.message, failed);
            return rejectWithValue(error.message);
        }
    }
);

export const settleBill = createAsyncThunk(
    "settleBill",
    async (data: any, { getState, dispatch, rejectWithValue }) => {
        try {
            dispatch(showLoader());
            const state: any = getState();
            const { customer } = state.customer;
            const customerId = customer._id;
            const { loginType, opBranch, opFleet } = state.user;

            const { receiptDoc, balance } = await billSettleService(
                jsonToFormdata({
                    ...data,
                    loginType,
                    [loginType === "B" ? "branch" : "fleet"]:
                        loginType === "B" ? opBranch._id : opFleet._id,
                })
            );

            showMessage("Bill Settled!", success, 2000);

            dispatch(updateBalance({ balance: balance?.balance, options: { balance } }));

            let details = { id: customerId, sub: loginType, entity: loginType === "B" ? opBranch._id : opFleet._id };
            dispatch(fetchDueCredit(details));

            dispatch(hideLoader());

            return receiptDoc; // Returning receiptDoc as the fulfilled payload
        } catch (error: any) {
            dispatch(hideLoader());
            showHttpError(error);
            return rejectWithValue(error.message);
        }
    }
);

export const cancelBill = createAsyncThunk(
    "cancelBill",
    async (billId: any, { getState, dispatch, rejectWithValue }) => {
        try {
            dispatch(showLoader());

            const state: any = getState();
            const { customer } = state.customer;
            const { loginType, opBranch, opFleet } = state.user;
            const customerId = customer._id;

            await billCancelService(billId);

            showMessage("Bill Cancelled!", success, 2000);
            let details = { id: customerId, sub: loginType, entity: loginType === "B" ? opBranch._id : opFleet._id };

            await dispatch(fetchDueCredit(details));

            dispatch(hideLoader());

            return { billId }; // Returning the billId in case needed in reducers
        } catch (error: any) {
            dispatch(hideLoader());
            showHttpError(error);
            return rejectWithValue(error.message);
        }
    }
);

export const saveCredit = createAsyncThunk(
    "saveCredit",
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            const state: any = getState();
            const customer = state.customer;
            const credit = customer.credit;

            const { fromBranches, toBranches, fleetPermissions, includeDeliveryCharge } = credit;

            const payload = {
                ...credit,
                customer: customer.customer._id,
                credit: credit.id,
                fromBranches: Array.isArray(fromBranches) ? fromBranches.map((b) => b.value) : [],
                toBranches: Array.isArray(toBranches) ? toBranches.map((b) => b.value) : [],
                fleetPermissions: Array.isArray(fleetPermissions) ? fleetPermissions.map((f) => f.value) : [],
                includeDeliveryCharge,
            };

            if (credit.id) {
                await saveCreditService(payload);
                //   dispatch({ type: CREDIT_UPDATED_SUCCESS, payload: result });
            } else {
                await createCustomerCreditService(payload);
                dispatch(newCreditSubmit());
            }

            showMessage("Successful", success);
            dispatch(getCustomerByContact(customer.customer.contact));

            return payload;
        } catch (error: any) {
            showHttpError(error);
            return rejectWithValue(error.message);
        }
    }
);
