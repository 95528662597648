import { ServiceMaker, buildQueryString } from "./index";

export const getAttendanceReportCount = (data: any) =>
  ServiceMaker(
    `dashboard/attendanceReportCount?${buildQueryString(data)}`,
    "GET"
  );

export const userAttendanceReport = (data: any) =>
  ServiceMaker(`dashboard/attendanceReport?${buildQueryString(data)}`, "GET");

export const allUsersAttendanceReport = (data: any) =>
  ServiceMaker(
    `dashboard/attendanceReportAll`,
    "POST",
    data
  );

export const BranchAttendanceReport = (data: any) =>
  ServiceMaker(
    `dashboard/branchAttendanceReport?${buildQueryString(data)}`,
    "GET"
  );

export const BranchAttendanceReportAll = (data: any) =>
  ServiceMaker(
    `dashboard/branchAttendanceReportAll?${buildQueryString(data)}`,
    "GET"
  );

  export const BranchAttendanceReportAllOld = (data: any) =>
    ServiceMaker(
      `dashboard/branchAttendanceReportAllOld?${buildQueryString(data)}`,
      "GET"
    );

export const getShortageAmount = () =>
  ServiceMaker("verification/shortageAmount", "GET");

  export const getShortageDeliveryChargeDetails = (data: any) =>
  ServiceMaker(`report/shortageDeliveryChargeDetails?${buildQueryString(data)}`, "GET");

export const getShortageDetails = (data: any) =>
  ServiceMaker(`report/shortageDetails?${buildQueryString(data)}`, "GET");

  export const getShortageDeliveryChargeDetailBranchWise = (data: any) =>
  ServiceMaker(`report/shortageDeliveryChargeDetailBranchWise?${buildQueryString(data)}`, "GET");

  export const getShortageDetailsBranchWise = (data: any) =>
  ServiceMaker(`report/shortageDetailBranchWise?${buildQueryString(data)}`, "GET");
  
  export const getShortageDemurrageBranchWise = (data: any) =>
  ServiceMaker(`report/shortageDemurrageBranchWise?${buildQueryString(data)}`, "GET");
export const getShortageDemurage = (data: any) =>
  ServiceMaker(
    `report/shortageDemurrage?${buildQueryString(data)}`,
    "GET"
  );

export const getPendingDeliveryReport = () =>
  ServiceMaker(`report/pendingDelivery`, "GET");

export const getPendingDeliveryCountReport = (data: any) =>
  ServiceMaker(`report/pendingDeliveryCount?${buildQueryString(data)}`, "GET");

export const getPendingDestDocketsReport = (data: any) =>
  ServiceMaker(`report/pendingDestDockets?${buildQueryString(data)}`, "GET");

export const getPendingDeliveryDockets = (data: any) =>
  ServiceMaker(
    `report/pendingDeliveryDockets?${buildQueryString(data)}`,
    "GET"
  );

export const getReportsDockets = (data: any) =>
  ServiceMaker(`report/dockets?${buildQueryString(data)}`, "GET");

export const getReportBranchUnverified = (data?: any) =>
  ServiceMaker(`report/unverifiedDockets?${buildQueryString(data)}`, "GET");

export const getDemurrageDownloadData = (data: any) =>
  ServiceMaker(
    `verification/shortageDemurrageDockets?${buildQueryString(data)}`,
    "GET"
  );

export const getDeliveryChargeDownloadData = (data: any) =>
  ServiceMaker(
    `verification/shortageDChargeDockets?${buildQueryString(data)}`,
    "GET"
  );

export const getShortageDownloadData = (data: any) =>
  ServiceMaker(`verification/shortageDockets?${buildQueryString(data)}`, "GET");

export const getPendingBTHCount = () =>
  ServiceMaker("dashboard/pendingBTHCount", "GET");

export const getPendingBTHCountBranch = (data: any) =>
  ServiceMaker(
    `dashboard/pendingBTHCountBranch?${buildQueryString(data)}`,
    "GET"
  );

export const getCameraActivityStatus = (data: any) =>
  ServiceMaker(`branch/getBranchActivity?${buildQueryString(data)}`, "GET");

export const listBranchActivities = (data: any) =>
  ServiceMaker(`branch/listBranchActivities?${buildQueryString(data)}`, "GET");

export const processBranchActivity = (data: any) =>
  ServiceMaker(`branch/processBranchActivity`, "POST", data);

export const getBranchOperationKpi = (data: any) =>
  ServiceMaker(`report/branch/operation/kpi?${buildQueryString(data)}`, "GET");
