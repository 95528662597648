import { ServiceMaker, buildQueryString } from ".";

/**
 * Attendance Module
 * @param {data}
 * @return city object
 */

export const addAttendanceService = (data: any) =>
  ServiceMaker("attendance/addAttendance", "POST", data);

export const getAttendanceService = (data: any) =>
  ServiceMaker("attendance/getAttendance", "POST", data);

export const getAttendanceServiceV2 = (data: any) =>
  ServiceMaker("attendance/getAttendanceV2", "POST", data);

export const getAttendanceReportByUser = (data: {
  startDate?: Date;
  endDate?: Date;
  userId?: string;
}) => ServiceMaker("attendance/report", "POST", data);

export const getLastStatusAttendence = (data: { userId?: string }) =>
  ServiceMaker("attendance/getLastStatusAttendance", "POST", data);

export const checkAttendenceMarked = (data:any) =>
  ServiceMaker(`dashboard/combindReport?${buildQueryString(data)}`,"GET");
export const branchBenchmarks = (data: any) =>
  ServiceMaker(`dashboard/branchBenchmarks?${buildQueryString(data)}`, "GET");
export const getBalanceInfo = () =>
  ServiceMaker("dashboard/getBalanceInfo", "GET");

export const getAdminUnverifiedInfo = () =>
  ServiceMaker("verification/secondaryVerifyPkgCount", "GET");

export const auditInfo = () => ServiceMaker("dashboard/auditInfo", "GET");
export const creditCustomerInfo = () =>
  ServiceMaker("dashboard/creditCustomerBalanceDetails", "GET");
export const CreditCustomerListData = () => {
  return ServiceMaker(`dashboard/expenseInfo`, "GET");
};
export const submitAttendencePhoto = (id: string, data: any) =>
  ServiceMaker(`attendance/photo/${id}`, "PATCH", data);

export const ServiceListData = () => {
  return ServiceMaker(`dashboard/serviceValidity`, "GET");
};
export const ServiceSendData = (data: any) =>
  ServiceMaker(`dashboard/serviceValidity`, "PATCH", data);

export const ServiceDelete = (data: any) =>
  ServiceMaker(`dashboard/serviceValidity`, "DELETE", data);

  export const submitAttendanceReq = (data:any)=>
  ServiceMaker("attendance/attendanceReq","POST",data);
  
  export const getAttendanceReqData = (data:any)=>
  ServiceMaker(`attendance/attendanceReq?${buildQueryString(data)}`,"GET");
  
  export const attendanceAction = (data:any)=>
  ServiceMaker("attendance/processReq","PATCH",data);
  
  export const getDailymemoPendingCount  = (data:any) =>
  ServiceMaker(`dashboard/preLogoutCheck?${buildQueryString(data)}`,"GET")
  
  export const removeAttendanceData = (data:any)=>
  ServiceMaker("attendance/removeAttendance","DELETE",data);

  export const addBreakLog = (data:any)=>
  ServiceMaker("attendance/addBreakLog","PATCH",data);
  export const removeBreakLog = (data:any)=>
  ServiceMaker(`attendance/removeBreakLog/${data}`,"DELETE");
  
  export const ApplyForPaidLeave = (data:any)=>
  ServiceMaker("attendance/applyPaidLeave","POST",data);

  export const AllowOvertime = (data:any)=>
  ServiceMaker("attendance/allowOvertime","PATCH",data);

  export const allowDayOffDuty = (data:any) =>
  ServiceMaker("attendance/allowDayOffDuty", "PATCH", data)

  export const lastcheckOutFirstCheckInData = (data:any) =>
  ServiceMaker(`attendance/branchCheckInCheckOut?${buildQueryString(data)}`,"GET")

  export const allow15MinsGrace = (data:any) =>
  ServiceMaker(`attendance/allowAttendanceGrace`,"PATCH", data)

  export const processSalary=(data:any)=>
  ServiceMaker(`attendance/processSalary`,"PATCH",data)

  export const approveSalary=(id:any)=>
  ServiceMaker(`attendance/approveSalary/${id}`,"PATCH")
  
  export const getCompanyUserMemoInfo=()=>
  ServiceMaker(`dashboard/getCompanyUserMemoInfo`,"GET")

  export const allowHolidaySalary = (data:any) =>
    ServiceMaker("attendance/allowHolidaySalary", "PATCH", data)

  export const markAsAbsent = (data:any) => 
    ServiceMaker("attendance/markAsAbsent", "PATCH", data)
  
  export const getAttendanceRequests = (data:any) =>
    ServiceMaker(`attendance/getAttendanceRequests/monthWise?${buildQueryString(data)}`,"GET")
  
  export const getLeaveDataMonthwise = (data:any) =>
    ServiceMaker(`leave/list/monthWise?${buildQueryString(data)}`,"GET")