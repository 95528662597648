import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHttpError } from "../../../utils/message";
import { TaskListFromMainTaskPayload } from "./taskModule.type";
import { getSubTaskListFromMainTaskId } from "../../../services/task";

export const setTaskListFromMainTask = createAsyncThunk(
    "setTaskListFromMainTask",
    async (
        payload: TaskListFromMainTaskPayload,
    ) => {
        try {
            console.log(`kjSDNv`, payload);
            let response: any = await getSubTaskListFromMainTaskId(payload, { page: 1, pageSize: 10 });
            return response;
        } catch (err) {
            showHttpError(err);
        }
    }
);