import { createSlice } from "@reduxjs/toolkit";
import { customerChatStateType } from "./customerChat.type";
import { getChatHistory } from "./api";

const initialState: customerChatStateType = {
    feedbackNumber: null,
    docketNumber: null,
    userId: null,
    customerId: null,
    chat: [],
    media: []
}

const customerChatSlice = createSlice({
    name: "customerChat",
    initialState,
    reducers: {
        updateChatData: (state, action) => {
            Object.assign(state, {
                chat: [...state.chat, action.payload]
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getChatHistory.fulfilled, (state, action) => {
            // state = {
            //     ...state,
            //     ...action.payload
            // }
            Object.assign(state, {
                ...action.payload
            })
        });
    },
});

export const { updateChatData } = customerChatSlice.actions;

export default customerChatSlice.reducer;