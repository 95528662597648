import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHttpError } from "../../../utils/message";
import { complaintChatListPayload } from "./complaintChatList.type";
import { getComplaintsListing } from "../../../services/complaint";

export const complaintChatListingFunc = createAsyncThunk(
    "complaintChatList",
    async (
        payload: complaintChatListPayload,
    ) => {
        try {
            console.log(`kjSDNv`, payload);
            let response = await getComplaintsListing(payload);
            if(response?.customerReviewList){
                return response?.customerReviewList;
            }else if(response?.bookingFeedBackList){
                return response?.bookingFeedBackList;
            }
        } catch (err) {
            showHttpError(err);
        }
    }
);