import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  processAppRights
} from "../../../reducers/utils/ProcessRights";

const initialState = processAppRights([]);


const appRightSlice = createSlice({
  name: "rights",
  initialState,
  reducers: {
    setAppRights: (state, action) => {
      const rights = processAppRights(action.payload);
      Object.assign(state, rights);
    },
  },
});

export const { setAppRights } = appRightSlice.actions;

export default appRightSlice.reducer;

export const selectRight = (state: RootState) => state.rights;
// export const selectIsLoading = (state: RootState) => state.user.isLoading;
// export const selectError = (state: RootState) => state.user.error;
